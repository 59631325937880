import React from 'react'
import { connect } from 'react-redux'
import { getLanguage } from '../../../store/ui/reducers'

//Danes
import imgda from '../../../assets/img/banner-suma/DA/wide.jpg'
import imgdaSquared from '../../../assets/img/banner-suma/DA/squared.jpg'
//Aleman
import imgde from '../../../assets/img/banner-suma/DE/wide.jpg'
import imgdeSquared from '../../../assets/img/banner-suma/DE/squared.jpg'
//Ingles
import imgen from '../../../assets/img/banner-suma/EN/wide.jpg'
import imgenSquared from '../../../assets/img/banner-suma/EN/squared.jpg'
//Español
import imges from '../../../assets/img/banner-suma/ES/wide.jpg'
import imgesSquared from '../../../assets/img/banner-suma/ES/squared.jpg'
//Suomi
import imgfi from '../../../assets/img/banner-suma/FI/wide.jpg'
import imgfiSquared from '../../../assets/img/banner-suma/FI/squared.jpg'
//Frances
import imgfr from '../../../assets/img/banner-suma/FR/wide.jpg'
import imgfrSquared from '../../../assets/img/banner-suma/FR/squared.jpg'
//Hungria (Se usa el ingles)
import imghu from '../../../assets/img/banner-suma/EN/wide.jpg'
import imghuSquared from '../../../assets/img/banner-suma/EN/squared.jpg'
//Italiano
import imgit from '../../../assets/img/banner-suma/IT/wide.jpg'
import imgitSquared from '../../../assets/img/banner-suma/IT/squared.jpg'
//Japones
import imgja from '../../../assets/img/banner-suma/JP/wide.jpg'
import imgjaSquared from '../../../assets/img/banner-suma/JP/squared.jpg'
//Neerlandes
import imgnl from '../../../assets/img/banner-suma/NL/wide.jpg'
import imgnlSquared from '../../../assets/img/banner-suma/NL/squared.jpg'
//Noruego
import imgno from '../../../assets/img/banner-suma/NO/wide.jpg'
import imgnoSquared from '../../../assets/img/banner-suma/NO/squared.jpg'
//Polaco
import imgpl from '../../../assets/img/banner-suma/PO/wide.jpg'
import imgplSquared from '../../../assets/img/banner-suma/PO/squared.jpg'
//Portugues
import imgpt from '../../../assets/img/banner-suma/PT/wide.jpg'
import imgptSquared from '../../../assets/img/banner-suma/PT/squared.jpg'
//Suecia
import imgsv from '../../../assets/img/banner-suma/SE/wide.jpg'
import imgsvSquared from '../../../assets/img/banner-suma/SE/squared.jpg'
//Turquia
import imgtr from '../../../assets/img/banner-suma/TR/wide.jpg'
import imgtrSquared from '../../../assets/img/banner-suma/TR/squared.jpg'
//Hebreo
import imghe from '../../../assets/img/banner-suma/EN/wide.jpg'
import imgheSquared from '../../../assets/img/banner-suma/EN/squared.jpg'
import { isAustralianCenter } from '../../../store/centers/reducers'

const mapStateToProps = state => {
    return {
        language: getLanguage(state),
        isAustralianCenter: isAustralianCenter(state)
    }
}

class BannerSuma extends React.Component {
    render() {
        if (this.props.isAustralianCenter) return null
        const { language } = this.props
        const responsiveWidth = window.innerWidth <= 768

        const images = {
            da: responsiveWidth ? imgdaSquared : imgda,
            de: responsiveWidth ? imgdeSquared : imgde,
            en: responsiveWidth ? imgenSquared : imgen,
            es: responsiveWidth ? imgesSquared : imges,
            fi: responsiveWidth ? imgfiSquared : imgfi,
            fr: responsiveWidth ? imgfrSquared : imgfr,
            hu: responsiveWidth ? imghuSquared : imghu,
            it: responsiveWidth ? imgitSquared : imgit,
            ja: responsiveWidth ? imgjaSquared : imgja,
            nl: responsiveWidth ? imgnlSquared : imgnl,
            no: responsiveWidth ? imgnoSquared : imgno,
            pl: responsiveWidth ? imgplSquared : imgpl,
            pt: responsiveWidth ? imgptSquared : imgpt,
            sv: responsiveWidth ? imgsvSquared : imgsv,
            tr: responsiveWidth ? imgtrSquared : imgtr,
            he: responsiveWidth ? imgheSquared : imghe,
            'en-US': responsiveWidth ? imgenSquared : imgen,
            'fr-CA': responsiveWidth ? imgfrSquared : imgfr,
            'en-CA': responsiveWidth ? imgenSquared : imgen
        }

        const links = {
            da: 'https://www.cosentino.com/da-dk/silestone/suma/',
            de: 'https://www.cosentino.com/de/silestone/suma/',
            en: 'https://www.cosentino.com/en-gb/silestone/suma/',
            es: 'https://www.cosentino.com/es/silestone/suma/',
            fi: 'https://www.cosentino.com/fi-fi/silestone/suma/',
            fr: 'https://www.cosentino.com/fr-fr/silestone/suma/',
            hu: 'https://www.cosentino.com/silestone/suma/',
            it: 'https://www.cosentino.com/it-it/silestone/suma/',
            ja: 'https://www.cosentino.com/ja-jp/silestone/suma/',
            nl: 'https://www.cosentino.com/nl-nl/silestone/suma/',
            no: 'https://www.cosentino.com/no-no/silestone/suma/',
            pl: 'https://www.cosentino.com/pl-pl/silestone/suma/',
            pt: 'https://www.cosentino.com/pt-pt/silestone/suma/',
            sv: 'https://www.cosentino.com/sv-se/silestone/suma/',
            tr: 'https://www.cosentino.com/tr-tr/silestone/suma/',
            he: 'https://www.cosentino.com/silestone/suma/',
            'en-US': 'https://www.cosentino.com/silestone/suma/',
            'fr-CA': 'https://www.cosentino.com/fr-ca/silestone/suma/',
            'en-CA': 'https://www.cosentino.com/en-ca/silestone/suma/'
        }

        return (
            <div className="mt-4">
                <a href={links[language]} target="_blank" rel="noopener noreferrer">
                    <img style={{ maxWidth: '100%' }} src={images[language]} alt="Banner Suma" />
                </a>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(BannerSuma)
