import {
    getPromotionLateralBanner,
    getPromotionLink,
    getBannerTitle,
    getBannerDescription,
    getPromotionData
} from './../../../store/promotions/reducers';
import {
    hasPromotionLandingActivated,
    getPromoBanners,
    hasPromoActivated,
    canBuy,
    canOnlyViewOutlet,
    getIsIkeaCenter,
    getIsMkToolsCenter,
    getIsMktoolsShippingCenterSelected,
    getIsMktoolsUsaCenter,
    getNeedsSegmentation,
    getPricesGroup,
    getSalesOrg,
    getSelectedCenter,
    getShippingMethod,
    isEuropeOrIberia,
    productIsInPromo,
    isLatinaVitoria
} from './../../../store/centers/reducers';
import { connect } from 'react-redux'
import { addCartLine, addCartLineWithouthSeg } from '../../../store/cart/actions'
import {
    getImpersonatedBy,
    getIsAdmin,
    getPriceListAtSelectedCenter,
    getTypeClient,
    getTestAB,
    getUserPriceList,
    getUserType,
    getNaturalStoneViewingPermissions
} from '../../../store/login/reducers'
import { withRouter } from 'react-router-dom'
import { defineMessages, injectIntl } from 'react-intl'
import { getProfileConfiguration } from '../../../store/profile-configuration/reducers'
import { getIsAdding, getQtyProductsCart } from '../../../store/cart/reducers'
import {canViewThresholdMax, getHideAllBannerCatalog, getLanguage} from '../../../store/ui/reducers'
import { canViewPricesPhase3, discountsByProductId, isExclusiveHomeDepot, getShowLotsInPortfolio } from '../../../store/products/reducers'
import { isProductIdInPromotion } from '../../../store/promotions/reducers'
import { setLastSelectedProduct} from "../../../store/selected-objects/actions";
import moment from 'moment/moment'
import RectangleResult from "./RectangleResult";

defineMessages({
    NEW_PRICE: {
        id: 'new_price',
        description: 'new_price',
        defaultMessage: 'New Price'
    }
})
const mapStateToProps = (state, ownProps) => {
    const source = ownProps.result !== undefined ? ownProps.result._source : ownProps.product
    const indexOfList = ownProps.index !== undefined ? ownProps.index : null
    const list = ownProps.list !== undefined ? ownProps.list : null
    return {
        shippingMethod: getShippingMethod(state),
        outlet: ownProps.outlet || ownProps.match.params.outlet || (ownProps.product && ownProps.product.isStockOutlet),
        isAdmin: getIsAdmin(state),
        canBuy: canBuy(state),
        canOnlyViewOutlet: canOnlyViewOutlet(state),
        userPreferences: getProfileConfiguration(state),
        userType: getUserType(state),
        typeClient: getTypeClient(state),
        testAB: getTestAB(state),
        productIsInPromo: productIsInPromo(state, source),
        isMkToolsCenter: getIsMkToolsCenter(state),
        isMkToolsUsaCenter: getIsMktoolsUsaCenter(state),
        impersonatedBy: getImpersonatedBy(state),
        qtyProductCart: getQtyProductsCart(state),
        centerId: getSelectedCenter(state),
        needsSegmentation: getNeedsSegmentation(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isMktoolsShippingCenterSelected: getIsMktoolsShippingCenterSelected(state),
        priceGroup: getPricesGroup(state),
        userPriceList: getUserPriceList(state),
        priceList: getPriceListAtSelectedCenter(state),
        canViewThresholdMax: canViewThresholdMax(state),
        canViewPricesPhase3: canViewPricesPhase3(state),
        indexOfList: indexOfList,
        isAdding: getIsAdding(state),
        productDiscounts: discountsByProductId(state, source.productId, ownProps.match.params.outlet),
        supplierCenterSalesOrg: getSalesOrg(state),
        isExclusiveHomeDepot: isExclusiveHomeDepot(state, source.colorId),
        isProductIdInPromo: isProductIdInPromotion(state, source.productId),
        isHideAllBannerCatalog: getHideAllBannerCatalog(state),
        showBannerPromo: new moment() < moment('2022-11-18 18:00') && isEuropeOrIberia(state),
        hasPromoActivated: hasPromoActivated(state),
        hasPromotionLandingActivated: hasPromotionLandingActivated(state),
        promotionLateralBanner: getPromotionLateralBanner(state),
        promotionLink: getPromotionLink(state),
        promoBanners: getPromoBanners(state),
        promotionBannerTitle: getBannerTitle(state),
        promotionBannerDescription: getBannerDescription(state),
        isLatinaVitoria: isLatinaVitoria(state),
        showLotsInPortfolio: (product, isOutlet, isCosentinoBrazil) => getShowLotsInPortfolio(state, product, isOutlet, isCosentinoBrazil),
        naturalStoneViewingPermissions: getNaturalStoneViewingPermissions(state),
        promotionData: getPromotionData(state),
        locale: getLanguage(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCartLine: (productId, qty, extra, callbackCenter, dektonGripRequested, supplierCenterId) => {
            dispatch(addCartLine(productId, qty, extra, callbackCenter, dektonGripRequested, supplierCenterId))
        },
        addCartLineWithouthSeg: (productId, qty, extra) => {
            dispatch(addCartLineWithouthSeg(productId, qty, extra))
        },
        clickDetail: (product, list, index) => {
            dispatch(setLastSelectedProduct(product))
            dispatch({ type: 'PRODUCT_CLICK', product, list, index })
        },
        setLastSelectedProduct: (product) => {
            dispatch(setLastSelectedProduct(product))
        }
    }
}

const RectangleResultContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(RectangleResult)
    )
)

export default RectangleResultContainer
