import React from 'react'
import PropTypes from 'prop-types'
import { confirmable } from 'react-confirm'
import Button from './Button'

const ExtendedConfirmDialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
    return (
        <div className={options.isV2 ? 'modal-v2 v2' : 'modal-v2 v2'}>
            <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
            <div id="confirm-container" className="confirm" style={{ display: show ? 'block' : 'none' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 container p-0">
                            <header className="container-fluid">
                                <button className="confirm-dialog-close" onClick={() => cancel('arguments will be passed to the callback')} datacy="confirm_dialog__cancel">
                                    <i class="fas fa-times"></i>
                                </button>
                                <div className="row">
                                    <div className="col-12">
                                        <div>
                                            <h2>{confirmation}</h2>
                                            {
                                                options.advise && (
                                                    <div className="confirm-advise">
                                                        {options.advise}
                                                    </div>
                                                )
                                            }
                                            <div className="confirm-btn-group">
                                                <div>
                                                    <Button onClick={() => cancel('arguments will be passed to the callback')} datacy="confirm_dialog__cancel">
                                                        {options.textCancel ? options.textCancel : 'Cancel'}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button className={options.isV2 ? 'bt-inverted' : ''} onClick={() => proceed('same as cancel')} datacy="confirm_dialog__ok">
                                                        {options.textOk ? options.textOk : 'Ok'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ExtendedConfirmDialog.propTypes = {
    show: PropTypes.bool, // from confirmable. indicates if theyarn start dialog is shown or not.
    proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ExtendedConfirmDialog)
