import React from 'react'
import { injectIntl } from 'react-intl'
import {connect} from "react-redux";
import Translation from "../global/Translation";
import LotComponent from "./LotComponent";



const LotsTable = (props) => {
        const lots = props.lots ? props.lots.slice(props.selectedIndex * props.pageSize, props.selectedIndex * props.pageSize + props.pageSize) : []


        /**
         * Determines if product is pn and has country code 
         * @returns {boolean}
         */
        const isNaturalStoneWithOrigin = () => {
            const product = props.original
            if (product && product.brand && product.countryCode) {
                const naturalStone = ['scalea', 'sensa', 'prexury']
                if (naturalStone.includes(product.brand.toLowerCase())) return true
            }
            return false
        }

        return (
            <div className="row no-gutters w-100" style={{overflow:'auto', maxHeight:'400px'}}>

                <div className="row no-gutters" style={{
                    //width:'1076px',
                    minWidth:'100%',
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '12px'
                }}>
                    <table style={{width:'100%'}}>

                        <tr className="table-header">

                            <th style={{width:'7.5%'}}/>

                            <th>
                                <Translation id="type" defaultMessage="Type"/>
                            </th>

                            <th>
                                <Translation id="quarryBlock" defaultMessage="Quarry Block" />
                            </th>

                            {
                                (!props.canViewAllLots || props.isAdmin) && (
                                    <th style={{textAlign:'center'}}>
                                        <Translation id="quality" defaultMessage="Quality"/>
                                    </th>
                                )
                            }

                            {
                                props.outlet && (
                                    <th style={{textAlign:'center'}}>
                                        <Translation id="quality" defaultMessage="Quality"/> Outlet
                                    </th>
                                )
                            }

                            <th style={{textAlign:'end'}}>
                                <Translation id="size" defaultMessage="Size" />
                            </th>

                            <th style={{textAlign:'end'}}>
                                <Translation id="surface" defaultMessage="Surface" />
                            </th>

                            {isNaturalStoneWithOrigin() && (
                                <th style={{textAlign:'end'}}>
                                    <Translation id="origin" defaultMessage="Origin" />
                                </th>
                            )}

                            {(props.isAdmin || props.impersonatedBy || props.isMexico) && (
                                <th style={{textAlign:'center'}}>
                                    <Translation id="shade" defaultMessage="Shade" />
                                </th>
                            )}
                            {(props.isAdmin || props.impersonatedBy) && (
                                <th>
                                    <Translation id="location" defaultMessage="Location" />
                                </th>
                            )}

                            <th style={{width:'2.5%'}}/>

                            {!props.isAdmin &&
                                <th style={props.minimumOrder ? {textAlign:'end'} : {}}>
                                    <Translation id="lots-to-buy" defaultMessage="Lots to buy"/>
                                </th>
                            }

                        </tr>
                        <tbody>
                            { lots && lots.map(l => {
                                const key = l.bundleId ? l.bundleId : l.lotId
                                return (
                                    <LotComponent
                                        {...props}
                                        lot={l}
                                        defaultImage={props.original.img}
                                        product={props.original}
                                        key={key}

                                        // centerId={props.center}
                                        // isAdmin={props.isAdmin}
                                        // lotImages={props.lotsImages}
                                        // outlet={props.outlet}
                                        // impersonatedBy={props.impersonatedBy}
                                        // canViewAllLots={props.canViewAllLots}
                                        // selectedProduct={props.selectedProduct}
                                        // fromMall={props.fromMall}
                                    />
                                )
                            })}
                        </tbody>


                    </table>
                </div>
            </div>
        )

}

export default injectIntl(
    connect(
        null,
        null
    )(LotsTable)
)
