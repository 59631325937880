import React from 'react'
import {ReactComponent as QuickShipIcon} from "../../../../assets/img/cut-to-size/quickShipIcon.svg";
import { injectIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'

const DisplayQuickShipIndicator = ({ tooltipId, iconStyle, intl  }) => {
    return (
        <span
            data-tip={intl.formatMessage({
               id: 'quickship_product',
                defaultMessage: 'QuickShip Product'
            })}
            data-for={tooltipId}
        >
            <QuickShipIcon style={iconStyle || { position: "absolute", top: "10%" }} />
            <ReactTooltip id={tooltipId} effect="solid" place="bottom" className="tooltip__information" />
        </span>
    )
}

export default injectIntl(DisplayQuickShipIndicator)