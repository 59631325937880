import React from 'react'
import ProductImage from '../../../catalog/ProductImage'
import ProductDisplayName from '../../../global/ProductDisplayName'
import { addForwardSlash } from '../Utils'
import Translation from '../../../global/Translation'
import { capitalizeFirstLetter } from '../../../../helpers'
import { getConfigLineName } from '../Utils'
import NaturalStoneIndicator from '../../../cart-line/indicators/NaturalStoneIndicator'
import DisplayBrand from '../../../catalog/rectangleResult/displays/DisplayBrand'
import DisplayDiscontinued from '../../../catalog/rectangleResult/displays/DisplayDiscontinued'
import DisplayOutletIndicator from '../../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayQuickShipIndicator from '../../../catalog/rectangleResult/displays/DisplayQuickShipIndicator'
import CustomProductIndicator from '../../../cart-line/indicators/CustomProductIndicator'

class NewShippingCardItem extends React.Component {
    blockQuantity = orderLine => {
        return (
            <span className="qty">
                <Translation id="quantity" defaultMessage="Quantity" />
                :&nbsp;
                {orderLine.delivery_qty == 0 ? (
                    <Translation id="product_canceled_by_center" defaultMessage="Product cancelled by center" />
                ) : (
                    <React.Fragment>{parseInt(orderLine.delivery_qty)}</React.Fragment>
                )}
            </span>
        )
    }
    render() {
        const { orderLine, adaptedFormat, intl, locale, replaceDotWithComma } = this.props

        const thicknessFormatIntl = orderLine => {
            if (!orderLine.product.thickness) return null
            return replaceDotWithComma(orderLine.product.thickness, locale) + ' cm'
        }

        const finishFormatIntl = (orderLine) => {
            if (!orderLine.product.finish) return null
            return this.props.intl.formatMessage({ id: orderLine.product.finish })
        }

        const productFormatIntl = orderLine => {
            if (!orderLine.product.format) return null
            return adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })
        }

        const subtypeFormatIntl = orderLine => {
            if (!orderLine.product.subtypeName) return null
            return this.props.intl.formatMessage({ id: orderLine.product.subtypeName })
        }

        const zaddonFormatIntl = orderLine => {
            if (orderLine.product.zaddon !== 'MAL') return null
            return this.props.intl.formatMessage({ id: 'MAL' })
        }

        const descriptionBlock = () => {
            return (
                <div className="order__card__item">
                    <div className="order__card__item--shipping-content">
                        <div>
                            <div className="card__item__productinfo">
                                <div className="card__item__productinfo--container">
                                    <div className="card__item__productinfo--img">
                                        <ProductImage product={orderLine.product} inSmallBox={true} />
                                    </div>
                                    <div className="d-flex align-items-start flex-column" style={{ gap: '4px' }}>
                                        {orderLine.extra && orderLine.extra.isCosentinoGlobal &&
                                            (orderLine.extra.outlet ? (
                                                <NaturalStoneIndicator idMessage={'outlet_in_hq'} defaultMessage="Outlet Spain" inSmallBox={true} />
                                            ) : (
                                                <NaturalStoneIndicator idMessage={'natural_stone_hq'} defaultMessage="Natural stone Spain" inSmallBox={true} />
                                            ))}

                                        {orderLine.extra && orderLine.extra.isCosentinoBrazil && (
                                            <NaturalStoneIndicator idMessage={'natural_stone_brazil'} defaultMessage="Natural stone Brazil" inSmallBox={true} />
                                        )}
                                    </div>
                                </div>

                                <div className="card__item__productinfo--text">
                                    <div className="brand-container">
                                        <span className="brand">
                                            <DisplayBrand brand={orderLine.product.brand} />
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap" style={{ gap: '3px' }}>
                                        <div>
                                            <span className="product">
                                                <ProductDisplayName product={orderLine.product} />
                                            </span>
                                        </div>
                                        <div>
                                            <span className="technical">
                                                {addForwardSlash(
                                                    subtypeFormatIntl(orderLine),
                                                    productFormatIntl(orderLine),
                                                    finishFormatIntl(orderLine),
                                                    thicknessFormatIntl(orderLine),
                                                    zaddonFormatIntl(orderLine)
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    {orderLine.product.productId && (
                                        <div className="product-id">
                                            <span>
                                                <Translation id="material_id" defaultMessage="Material ID" />
                                            </span>
                                            :&nbsp;
                                            <span>
                                                {orderLine.product.productId}
                                            </span>
                                        </div>
                                    )}

                                    <div className="indicators-block">
                                        {orderLine.product.discontinued && (
                                            <span className="mb-1">
                                                <DisplayDiscontinued discontinued={orderLine.product.discontinued} inSmallBox={true} />
                                            </span>
                                        )}
                                        {orderLine.outlet_quality && (
                                            <span className="mb-1">
                                                <DisplayOutletIndicator
                                                    outletQuality={orderLine.outlet_quality}
                                                    inSmallBox={true}
                                                />
                                            </span>
                                        )}
                                    </div>

                                    {/* Mobile screen */}
                                    <div className="mobile-screen">{this.blockQuantity(orderLine)}</div>
                                </div>
                            </div>
                        </div>
                        {/* Desktop screen */}
                        <div className="desktop-screen">{this.blockQuantity(orderLine)}</div>
                    </div>
                </div>
            )
        }

        const descriptionBlockConfigBath = () => {
            const cts = orderLine.cut_to_size
            return (
                <div className="order__card__item">
                    <div className="order__card__item--shipping-content">
                        <div>
                            <div className="card__item__productinfo">
                                <div className="card__item__productinfo--container">
                                    <div className="card__item__productinfo--img">
                                        {!!cts.configurable.thirdParty && <DisplayQuickShipIndicator tooltipId={cts.configurable.refcode} />}
                                        <img src={cts.thumb} alt={getConfigLineName(cts.configurable)} className="is-img-configurable" />
                                    </div>

                                    <div className="d-flex align-items-start flex-column" style={{ gap: '4px' }}>
                                        <CustomProductIndicator />
                                    </div>
                                </div>

                                <div className="card__item__productinfo--text">
                                    <div className="brand-container">
                                        <span className="brand">
                                            <DisplayBrand brand={cts.configurable.material.brand} />
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap" style={{ gap: '3px' }}>
                                        <div>
                                            <span className="product">
                                                <span className="product-title">{getConfigLineName(cts.configurable)}</span>
                                            </span>
                                        </div>
                                        <div>
                                            <span className="technical">
                                                <Translation id="customized" defaultMessage="Custom" />
                                            </span>
                                        </div>
                                    </div>

                                    {orderLine.material_id && (
                                        <div className="product-id">
                                            <span>
                                                <Translation id="material_id" defaultMessage="Material ID" />
                                            </span>
                                            :&nbsp;
                                            <span>
                                                {orderLine.material_id}
                                            </span>
                                        </div>
                                    )}

                                    {/* Mobile screen */}
                                    <div className="mobile-screen">{this.blockQuantity(orderLine)}</div>
                                </div>
                            </div>
                        </div>
                        {/* Desktop screen */}
                        <div className="desktop-screen">{this.blockQuantity(orderLine)}</div>
                    </div>
                </div>
            )
        }

        const descriptionBlockException = () => {
            return (
                <div className="order__card__item">
                    <div className="order__card__item--shipping-content">
                        <div className="card__item__productinfo">
                            <div className="card__item__productinfo--img">
                                <ProductImage product={null} />
                            </div>
                            <div className="card__item__productinfo--text">
                                {orderLine.short_text && (
                                    <div>
                                        <span className="product">
                                            <span>{capitalizeFirstLetter(orderLine.short_text)}</span>
                                        </span>
                                    </div>
                                )}
                                {orderLine.material_id && (
                                    <div className="product-id">
                                        <span>
                                            <Translation id="material_id" defaultMessage="Material ID" />
                                        </span>
                                        :&nbsp;
                                        <span>
                                            {orderLine.material_id}
                                        </span>
                                    </div>
                                )}
                                {orderLine.lot_id && (
                                    <div className="lot-id">
                                        <span>
                                            <Translation id="lot_id" defaultMessage="Lot ID" />
                                            :&nbsp;
                                        </span>
                                        <span>{orderLine.lot_id}</span>
                                    </div>
                                )}
                                {/* Mobile screen */}
                                <div className="mobile-screen">{this.blockQuantity(orderLine)}</div>
                            </div>
                        </div>
                        {/* Desktop screen */}
                        <div className="desktop-screen">{this.blockQuantity(orderLine)}</div>
                    </div>
                </div>
            )
        }

        const renderContent = () => {
            if (orderLine.product) {
                return descriptionBlock()
            }
        
            if (orderLine.cut_to_size && orderLine.cut_to_size.configurable) {
                return descriptionBlockConfigBath()
            }
        
            return descriptionBlockException()
        }

        return (
            <React.Fragment>
                <div>
                    <div className="order-divider-x"></div>
                    {renderContent()}
                </div>
            </React.Fragment>
        )
    }
}

export default NewShippingCardItem
