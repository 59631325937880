import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import Spinner from '../global/Spinner'
import Alerts from '../alerts/Alerts'
import ChannelV4 from './ChannelV4'
import SalesForceIdV3 from './SalesForceIdV3'
import ProjectIdV3 from './ProjectIdV3'
import UseV3 from './UseV3'
import TypologyV3 from './TypologyV3'
import {SAVED_SEGMENTATIONS_STEP, SAVING_SEGMENTATION_STEP, SEGMENTATION_EVIDENCE_STEP} from "./SegmentationSteps";


class SegmentationV3 extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            saveSegmentationPromptOpen: false,
            active: null,
            excuse: null,
            expandSaved: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.segmentatingProductId !== this.props.segmentatingProductId) {
            this.setState({ product: this.props.getProductById(this.props.segmentatingProductId) })
        }

        if (
            prevState.active && !this.state.active &&
            (this.props.businessSegment != prevProps.businessSegment ||
                this.props.channel != prevProps.channel ||
                this.props.subchannel != prevProps.subchannel ||
                this.props.salesForceId != prevProps.salesForceId ||
                this.props.salesForceName != prevProps.salesForceName ||
                this.props.projectId != prevProps.projectId ||
                this.props.projectName != prevProps.projectName ||
                this.props.subchannelProgram != prevProps.subchannelProgram ||
                this.props.typology != prevProps.typology ||
                this.props.useName != prevProps.useName)
        ) {
            this.setState({ active: null })
        }
    }

    clearSegmentation = () => {
        this.setState({ active: null })
        this.props.clearSegmentation()
    }

    toggleSegmentation = name => {
        const activeSegmentation = this.state.active === name ? null : name
        if (activeSegmentation) {
            this.loadSegmentation(activeSegmentation)
        } else {
            this.clearSegmentation()
        }
        this.setState({ active: activeSegmentation })
    }

    loadSegmentation = name => {
        this.props.loadPreferredSegmentation(name)
        this.setState({ active: name })
    }

    needsEvidence = () => this.props.subchannelProgram && this.props.evidencesType

    componentDidMount() {
        this.props.fetchSegmentation()
    }

    canAddMaterial() {
        if (this.props.isExclusiveHomeDepot) {
            if (!this.props.validHomeDepotSegmentation) {
                if (!this.state.excuse) this.setState({ excuse: 'EXCLUSIVE_HOME_DEPOT' })
                return false
            }
        }

        if (this.state.excuse) this.setState({ excuse: null })

        return true
    }

    scrollDown = () => {
        const element = document.getElementById('seg-content')
        if (element) {
            element.scrollTop = element.scrollHeight
        }
    }

    render() {
        const { channel } = this.props

        const formFullFilled = isFormFullFilled(this.props)

        return (
            <div className="suggested__segmentation">
                <div className="suggested__segmentation__content--input">
                    {(this.props.isSaving) && <Spinner isLoading={this.props.isSaving} />}
                    {this.state.excuse && <Alerts alert={this.state.excuse} status="warning" />}

                    <div id="seg-content">
                        <div>
                            <div className="mt-3">
                                <ChannelV4 intl={this.props.intl} />
                            </div>
                            <div>
                                {channel != null && channel !== 'B0' && channel !== 'HE' && channel !== 'AP' && channel !== 'PO' && (
                                    <div className="mt-2" onClick={this.scrollDown}>
                                        <SalesForceIdV3 intl={this.props.intl} />
                                    </div>
                                )}
                                {(channel === 'C0' ||
                                    channel === 'AP' ||
                                    channel === 'PO' ||
                                    channel === 'BB' ||
                                    channel === 'BD' ||
                                    channel === 'B0' ||
                                    channel === 'HE' ||
                                    channel === 'DA' ||
                                    channel === 'HO' ||
                                    channel === 'nexus_retail' ||
                                    channel === 'nexus_non_retail') && (
                                    <div className="mt-2" onClick={this.scrollDown}>
                                        <ProjectIdV3 intl={this.props.intl} />
                                    </div>
                                )}
                            </div>
                            {this.props.viewFullSegmentationV3 && (
                                <div className="row p-0 m-0 mt-2" style={{ gap: '16px' }}>
                                    <div className="col p-0 m-0" onClick={this.scrollDown}>
                                        <TypologyV3 intl={this.props.intl} product={this.state.product} />
                                    </div>
                                    <div className="col p-0 m-0" onClick={this.scrollDown}>
                                        <UseV3 intl={this.props.intl} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={`mt-3 link ${formFullFilled ? '' : 'disabled'}`} onClick={() => {
                        if (formFullFilled) this.props.onSetStep(SAVING_SEGMENTATION_STEP)
                    }}>
                        <div className="inner">
                            <i className="fal fa-heart"/>
                            &nbsp;&nbsp;
                            <Translation id="save_this_segmentation" defaultMessage="Save this segmentation"/>
                        </div>
                    </div>
                </div>

                <div>
                    <hr />
                    <footer>
                        <div className="double-buttons my-1">
                            <Button onClick={() => this.props.onSetStep(SAVED_SEGMENTATIONS_STEP)} >
                                <Translation id="view_saved_segmentations" defaultMessage="View saved segmentations" />
                            </Button>

                            {formFullFilled && this.canAddMaterial() && !window.location.href.includes('personal') ? (
                                this.needsEvidence() ? (
                                    <Button
                                        onClick={() => this.props.onSetStep(SEGMENTATION_EVIDENCE_STEP)}
                                        inverted
                                        // dataQas="global_segmentation-add-to-cart-button"
                                        icon="fal fa-chevron-right"
                                        iconEnd={true}
                                    >
                                        <Translation id="next" defaultMessage="Next" />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => this.props.onSubmitSegmentation(this.props.callback)}
                                        inverted
                                        dataQas="global_segmentation-add-to-cart-button"
                                        icon="fa fa-shopping-cart"
                                        iconEnd={true}
                                    >
                                        <Translation id="add_to_cart" defaultMessage="Add to cart" />
                                    </Button>
                                )) : (
                                <Button
                                    disabled
                                    inverted
                                    icon="fal fa-chevron-right"
                                    iconEnd={true}
                                >
                                    <Translation id="next" defaultMessage="Next" />
                                </Button>
                            )}
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default injectIntl(SegmentationV3)

export const isFormFullFilled = (props) => {
    let salesForceIsValid = props.sfOptions && Object.keys(props.sfOptions).length > 0 ? props.salesforceId !== null : true
    if (props.channel === 'B0') {
        salesForceIsValid = true
    }
    const isNexusChannel = typeof props.channel === 'string' && props.channel.startsWith('nexus')

    const needsProjectId = (
        (
            (props.channel === 'BD' || props.channel === 'BB')
            && props.salesforceId
            && props.sfOptions[props.salesforceId]
            && Object.keys(props.sfOptions[props.salesforceId].projectOptions).length > 0
        ) || (
            (props.channel === 'PO' || props.channel === 'B0') && Object.keys(props.projectOptions).length > 0
        ) || (
            props.channel === 'C0' && Object.keys(props.projectOptions).length > 0 && !props.isOptionalBuilderProjectCenter
        ) || isNexusChannel
    )

    const needsUseAndEnvironment = (
        (!props.use || !props.typology) && isNexusChannel
    )

    // const needsUse = props.isAdmin || !props.use
    if (needsUseAndEnvironment) return false

    const formIsFullfilled = props.channel === 'A0' || (props.channel && salesForceIsValid && (needsProjectId ? props.projectId : true))

    return formIsFullfilled
}

