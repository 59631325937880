import React from 'react'

import { connect } from 'react-redux'
import {
    getSelectedChannel,
    getSelectedTypology,
    getTypologyOptionsV3
} from '../../store/segmentation/reducers'
import { selectTypology } from '../../store/segmentation/actions'
import Select from 'react-select'
import Translation from '../global/Translation'

const mapStateToProps = state => {
    return {
        selected: getSelectedTypology(state),
        options: getTypologyOptionsV3(state),
        channel: getSelectedChannel(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSelect: (value, name) => {
            dispatch(selectTypology(value, name))
        }
    }
}


const TypologyV3 = ({ selected, product, onSelect, options, intl, channel }) => {

    if (!options || Object.entries(options).length === 0) {
        return ''
    }

    let placeholder = intl.formatMessage({ id: 'choose_option' })
    let isOptional = true

    if (product) {
        const isBath = product.brand === 'BATHS'
        isOptional = !isBath && channel !== 'nexus_retail' && channel !== 'nexus_non_retail'

        if (isBath) options = options.filter(o => o.id === 'BA')
        if (isOptional) placeholder += ' (' + intl.formatMessage({ id: 'optional' }) + ')'
    }

    if (options.length === 1 && selected !== options[0].id) {
        onSelect(options[0].id, options[0].name)
    }

    return (
        <div>
            <div className="segmentation-segment-title">
                <Translation id="enviroment" defaultMessage="Environment" />
                {!isOptional && ( <span>*</span>)}:
            </div>

            <Select
                placeholder={placeholder}
                options={options.map(option => ({
                    label: option.name,
                    value: option.id
                }))}
                value={selected}
                onChange={option => option ? onSelect(option.value, option.label) : onSelect(null, null)}
                clearable={options && options.length > 1}
            />
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypologyV3)
