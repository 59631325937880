import React, {useState} from 'react'
import Button from '../global/Button'
import Translation from '../global/Translation'
import {SEGMENTATION_EVIDENCE_STEP, SEGMENTATION_V3_STEP} from "./SegmentationSteps";
import {RadioButton} from "@progress/kendo-react-inputs";
import LineSegmentationV2 from "../cart-line/LineSegmentationV2";
import InfoWithToolTip from "../global/InfoWithToolTip";
import {defineMessages} from "react-intl";


defineMessages({
    here_some_suggested_segmenations: {
        id: 'no_available_segmentation_no_project',
        description: 'This segmentation is unavailable as because selected project is no available.',
        defaultMessage: 'This segmentation is unavailable as because selected project is no available.'
    },
    none_above_segmentations: {
        id: 'no_available_segmentation_no_sku',
        description: 'This segmentation is unavailable as it is not assigned to the selected product.',
        defaultMessage: 'This segmentation is unavailable as it is not assigned to the selected product.'
    },
    choose_segmentation_manually: {
        id: 'no_available_segmentation_default',
        description: 'This segmentation cannot be selected. For more information, please contact your center.',
        defaultMessage: 'This segmentation cannot be selected. For more information, please contact your center.'
    }
})

const SavedSegmentations = props => {
    const NO_PROJECT=1, NO_SKU=2, NO_SEG=3
    const { onSubmitSegmentation, callback, onSetStep, preferredSegmentation, materialProjects, segmentationProjectsSet, currentFavourite } = props

    const [selectedIndex, setSelectedIndex] = useState()

    const handleAddToCart = (needsEvidence) => {
        const savedSegmentation = preferredSegmentation[selectedIndex]

        props.loadPreferredSegmentation(savedSegmentation.name)
        if (needsEvidence) onSetStep(SEGMENTATION_EVIDENCE_STEP)
        else onSubmitSegmentation(callback)
    }

    const unavailableReasonMsg = reason => {
        switch (reason) {
            case NO_PROJECT:
                return props.intl.formatMessage({
                    id: 'no_available_segmentation_no_project',
                    defaultMessage: 'This segmentation is unavailable as because selected project is no available.'
                })
            case NO_SKU:
                return props.intl.formatMessage({
                    id: 'no_available_segmentation_no_sku',
                    defaultMessage: 'This segmentation is unavailable as it is not assigned to the selected product.'
                })
            default:
                return props.intl.formatMessage({
                    id: 'no_available_segmentation_default',
                    defaultMessage: 'This segmentation cannot be selected. For more information, please contact your center.'
                })
        }
    }

    const getReason = option => {
        if (!option.active) return NO_SEG
        if (option.subchannel === 'NX' && option.projectId && !materialProjects.has(option.projectId)) return NO_SKU
        if (option.projectId && !segmentationProjectsSet.has(option.projectId)) return NO_PROJECT

        return false
    }

    return (
        <div className="suggested__segmentation">
            <div className="suggested__segmentation__content--input">
                <div className="scrolled-list">
                    {preferredSegmentation.map((option, index) => {
                        const key = `saved-segmentation-${index}`
                        const reason = getReason(option)

                        return <div className="my-3 scrolled-list--item saved"
                                    key={key}
                        >
                            <RadioButton
                                onClick={() => setSelectedIndex(index)}
                                id={key}
                                name={key}
                                value={index}
                                disabled={reason !== false}
                                checked={selectedIndex === index}
                            >
                                <label htmlFor={key} className={'k-radio-label'} >
                                    <div className="save-segmentation-name">
                                        <span><strong>{option.name}</strong></span>
                                    </div>
                                    <div className="save-segmentation-option">
                                        <LineSegmentationV2 line={{ ...option, segmentation: option }} showAsList hideSpans />
                                    </div>
                                </label>

                                {reason !== false && (
                                    <InfoWithToolTip toolTipText={unavailableReasonMsg(reason)}>
                                        <Translation id="not_available" defaultMessage="Not available"/>
                                    </InfoWithToolTip>
                                )}
                            </RadioButton>
                        </div>
                    })}
                </div>
            </div>

            <div>
                <hr></hr>

                <footer>
                    <div className="double-buttons my-1">
                        <Button onClick={() => onSetStep(SEGMENTATION_V3_STEP)} >
                            <Translation id="choose_segmentation_manually" defaultMessage="Choose segmentation manually" />
                        </Button>
                        {
                            selectedIndex && preferredSegmentation[selectedIndex].evidence ?
                                <Button
                                    onClick={() => handleAddToCart(true)}
                                    inverted
                                    // dataQas="global_segmentation-add-to-cart-button"
                                    icon="fal fa-chevron-right"
                                    iconEnd={true}
                                >
                                    <Translation id="next" defaultMessage="Next" />
                                </Button>
                                :
                                <Button
                                    onClick={() => handleAddToCart(false)}
                                    inverted
                                    // dataQas="global_segmentation-add-to-cart-button"
                                    icon="fa fa-shopping-cart"
                                    iconEnd={true}
                                    disabled={selectedIndex === undefined}
                                >
                                    <Translation id="add_to_cart" defaultMessage="Add to cart" />
                                </Button>
                        }
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default SavedSegmentations
