import React, { forwardRef } from 'react'
import Translation from '../global/Translation'
import { injectIntl, defineMessages } from 'react-intl'
import debounce from 'lodash/debounce'
import InputNumber from '../global/InputNumber'
import LocalizedLink from '../global/LocalizedLink'
import Alerts from '../alerts/Alerts'
import ProductDisplayName from '../global/ProductDisplayName'
import ProductImage from '../catalog/ProductImage'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import DisplayPrice from '../catalog/rectangleResult/displays/DisplayPrice'
import DisplayPriceMktools from '../catalog/rectangleResult/displays/DisplayPriceMktools'
import DisplayLotId from '../catalog/rectangleResult/displays/DisplayLotId'
import Spinner from '../global/Spinner'
import moment from 'moment'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import { ElaborationOptionsMap } from '../cut-to-size/CutToSizeMaps'
import ReactTooltip from 'react-tooltip'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { getNextWorkingDay } from '../../helpers'
import LineSegmentationV2 from './LineSegmentationV2'
import getNumberFormat from '../getNumberFormat'
import confirm from '../global/extendedConfirm'
import ModalConfigurationDetails from '../cut-to-size/configurable-baths/ModalConfigurationDetails'
import { getConfigLineName } from '../orders/new-order-history/Utils'
import { ReactComponent as QuickShipIcon } from '../../assets/img/cut-to-size/quickShipIcon.svg'
import {FormattedHTMLMessage} from "react-intl"
import { isNewIndicator, isUpcomingIndicator, canDisplayDiscontinued } from '../../store/products/reducers'

// Labels
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayUpcomingIndicator from '../catalog/rectangleResult/displays/DisplayUpcomingIndicator'
import NaturalStoneIndicator from './indicators/NaturalStoneIndicator'
import CustomProductIndicator from './indicators/CustomProductIndicator'
import DisplayDiscontinued from '../catalog/rectangleResult/displays/DisplayDiscontinued'
import DisplayNewIndicator from '../catalog/rectangleResult/displays/DisplayNewIndicator'
import DisplayQuickShipIndicator from '../catalog/rectangleResult/displays/DisplayQuickShipIndicator'

defineMessages({
    WALL_SAMPLE: {
        id: 'wall_sample',
        description: 'wall_sample',
        defaultMessage: 'Wall Sample'
    },
    CUSTOM_SAMPLE: {
        id: 'custom_sample',
        description: 'custom_sample',
        defaultMessage: 'Customer Sample'
    },
    project_expired_prices_not_charged: {
        id: 'project_expired_prices_not_charged',
        description: 'project_expired_prices_not_charged',
        defaultMessage: 'The quoted price has expired. Please contact our commercial team'
    },
    for_full_trucks_or_containers_desired_delivery_date_get_affect: {
        id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
        description: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
        defaultMessage:
            'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the right.'
    },
    changing_the_preferred_delivery_date_will_affect_all_custom_products: {
        id: 'changing_the_preferred_delivery_date_will_affect_all_custom_products',
        description: 'changing_the_preferred_delivery_date_will_affect_all_custom_products',
        defaultMessage: 'Changing the preferred delivery date will affect all custom products in your cart.'
    },
    showing_quickship: {
        id: 'showing_quickship',
        description: 'Showing QuickShip products',
        defaultMessage: 'Showing QuickShip products'
    },
    hiding_quickship: {
        id: 'hiding_quickship',
        description: 'Hiding QuickShip products',
        defaultMessage: 'Hiding QuickShip products'
    },
    quick_ship_estimated_time: {
        id: 'quick_ship_estimated_time',
        description: 'Fast delivery: Estimated time to deliver is <b>2-4 weeks</b>',
        defaultMessage: 'Fast delivery: Estimated time to deliver is <b>2-4 weeks</b>'
    },
    quick_ship_available_colors: {
        id: 'quick_ship_available_colors',
        description: 'Quickship products available on selected colors catalogue',
        defaultMessage: 'Quickship products available on selected colors catalogue'
    },
    no_quick_ship_estimated_time: {
        id: 'no_quick_ship_estimated_time',
        description: 'Estimated time to deliver is 12 weeks',
        defaultMessage: 'Estimated time to deliver is 12 weeks'
    },
    configurable_order_finished_header: {
        id: 'configurable_order_finished_header',
        description: 'Your order for custom-made products has been finished successfully',
        defaultMessage: 'Your order for custom-made products has been finished successfully'
    },
    quick_ship_configurable_order_finished_header: {
        id: 'quick_ship_configurable_order_finished_header',
        description: 'Your order for custom-made products (Quickship) has been finished successfully',
        defaultMessage: 'Your order for custom-made products (Quickship) has been finished successfully'
    },
   /*  estimated_delivery_on_4_weeks: {
        id: 'estimated_delivery_on_4_weeks',
        description: 'Estimated delivery on <b>2-4 weeks</b>',
        defaultMessage: 'Estimated delivery on <b>2-4 weeks</b>'
    } */
})
class CartLine extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showHelp: false,
            showComment: false,
            showConfigBathModal: false,
            slaDate: null,
            hideSlaDateNonCts: false
        }
    }

    componentDidMount() {
        this.setEstimatedDeliveryDate()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.linesSlaDate !== prevProps.linesSlaDate) {
            this.setEstimatedDeliveryDate()
        }
    }

    shouldShowSlaDate() {
        const { line, linesSlaDateVisibility, isLoadingLinesSlaDate, slaDate } = this.props
        const { hideSlaDateNonCts } = this.state

        // Conditions for displaying the block
        if (isLoadingLinesSlaDate) return true
        if (slaDate) return true

        // Conditions to hide the block
        if (linesSlaDateVisibility && linesSlaDateVisibility[line.lineId]) return false
        if (!line.cutToSize && hideSlaDateNonCts) return false
        if (line.cutToSize && this.props.hideSlaDatesForCts) return false

        return true
    }

    setEstimatedDeliveryDate() {
        const slaDateData = this.props.linesSlaDate && this.props.linesSlaDate[this.props.line.lineId]
        let estimatedDeliveryDate

        //If the slaDate is comming with date (yyyy-mm-dd) we use this date
        if (slaDateData && slaDateData.slaDate) {
            estimatedDeliveryDate = moment(slaDateData.slaDate)
        }

        if (slaDateData && slaDateData.naturalDays && !slaDateData.slaDate) {
            //If the slaDate is comming with only natural days we use only natural days to create the date.
            estimatedDeliveryDate = moment().add(slaDateData.naturalDays, 'days')
        }

        if (estimatedDeliveryDate && estimatedDeliveryDate.isValid()) {
            this.setState({ slaDate: estimatedDeliveryDate })
        }
    }

    handleOpenConfigBathModal() {
        this.setState({ showConfigBathModal: true })
    }

    getCheckoutType() {
        if (this.props.isIkeaCenter) return 'ikea'
        else if (this.props.isMktoolsCenter) return 'mktools'
        else if (this.props.isLowesCenter) return 'lowes'
        else if (this.props.userType === 'SHOP') return 'shop'
        else if (this.props.isEndiCenter) return 'endi'
        else return 'default'
    }

    editSegmentation = () => {
        this.props.loadThisSegmentation(this.props.line.segmentation)
        this.props.showSegmentationModal('edit', () => this.props.changeCartLineSegmentation(this.props.line), this.props.line.productId)
    }

    getMin(line) {
        return line.product.min
    }

    getLimit(line) {
        if (line.extra && line.extra.mall) {
            return this.getLimitMall(line)
        }
        if (line.product.discontinued) {
            if (this.props.isMktoolsUsaCenter && this.getLimitMktool(line) < this.getLimitDiscontinued(line)) return this.getLimitMktool(line)
            else return this.getLimitDiscontinued(line)
        } else if (this.props.isMktoolsUsaCenter) return this.getLimitMktool(line)
        else if (line.product.isMktool) return this.getLimitMktool(line)
        else if (line.extra && line.extra.outlet) return this.getLimitOutlet(line)
        else return null
    }

    getLimitMktool(line) {
        let result = line.qty
        if (line.product.isMktool) {
            if (this.props.isMktoolsUsaCenter && (line.product.max || line.product.max === 0)) result = line.qty + line.product.max
            else result = result + 1000
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }

    getLimitOutlet(line) {
        let result = line.qty
        if (line.product.stock_outlet) {
            result = result + parseInt(line.product.stock_outlet, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }

    getLimitDiscontinued(line) {
        let result = line.qty
        if (line.product.stock + line.product.stock_outlet) {
            result = result + parseInt(line.product.stock + line.product.stock_outlet, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }

    getLimitMall(line) {
        let result = line.qty
        if (line.extra && line.extra.mall && line.extra.outlet && line.product.stock_outlet > 0) {
            result = result + parseInt(line.product.stock_outlet, 10)
        } else {
            result = result + parseInt(line.product.stock, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }

    saveComment = debounce(() => this.props.saveCartLineComment(this.props.line.lineId, this.inputComment.value), 500)

    getNewPrice() {
        let { line } = this.props
        let price = null
        if (this.props.linesPrice[line.lineId]) {
            let priceYYYY = this.props.linesPrice[line.lineId].price
            price = priceYYYY ? priceYYYY : 0
        }

        let numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if (this.hasZT00Price() && this.props.linesPrice[line.lineId].coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        return price && numberFormat.format(price)
    }

    hasZT00Price() {
        let { line } = this.props
        let priceZT00 = false
        if (this.props.linesPrice[line.lineId]) {
            priceZT00 = this.props.linesPrice[line.lineId].hasZT00
        }
        return priceZT00
    }

    isOutdated() {
        const { line } = this.props
        const linePrice = this.props.linesPrice[line.lineId]
        return !!linePrice && !!linePrice.outdated
    }

    getNewPricePerUnit() {
        let { line } = this.props
        let price = null
        if (this.props.linesPrice[line.lineId]) {
            let priceYYYY = this.props.linesPrice[line.lineId].pricePerUnit
            price = priceYYYY ? priceYYYY : 0
        }
        let numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if (this.hasZT00Price() && this.props.linesPrice[line.lineId].coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        return price && numberFormat.format(price)
    }

    canViewSLA = () => {
        return this.props.type === 'checkout' && !this.props.isIkeaCenter && !this.props.isLowesCenter && !this.props.isMktoolsCenter
    }

    showLongestDeliveryDate = (storedDesiredDate, slaDate) => {
        let dateToShow = null

        if (storedDesiredDate && slaDate) {
            // If the preferred delivery date is after the sla service date, show the preferred delivery date
            if (storedDesiredDate.isAfter(slaDate)) {
                dateToShow = storedDesiredDate.format('L')
            }

            // If the preferred delivery date is before or the same as the sla service date, show the sla service date
            if (storedDesiredDate.isBefore(slaDate) || storedDesiredDate.isSame(slaDate)) {
                dateToShow = slaDate.format('L')
            }
        } else if (slaDate) {
            // If storedDesiredDate is null, show the sla service date
            dateToShow = slaDate.format('L')
        }

        return dateToShow
    }

    handleShowSlaDateNonCts = (preferredDate, slaDate, line) => {
        if (!line.cutToSize && preferredDate && slaDate) {
            if (preferredDate.isAfter(slaDate, 'days')) {
                this.setState({ hideSlaDateNonCts: true })
            } else {
                this.setState({ hideSlaDateNonCts: false })
            }
        }
    }

    handleSetAllLinesDeliveryDatesCts = (date, line, slaDateState) => {
        if (line.cutToSize) {
            this.props.setAllLinesDeliveryDatesCts(date, slaDateState)
        }
    }

    confirmPreferredDeliveryDateChange = onClick => {
        confirm(this.props.intl.formatMessage({ id: 'changing_the_preferred_delivery_date_will_affect_all_custom_products' }), {
            isV2: true,
            textCancel: this.props.intl.formatMessage({ id: 'cancel' }),
            textOk: this.props.intl.formatMessage({ id: 'confirm' })
        }).then(
            confirm => {
                onClick()
            },
            cancel => {}
        )
    }
    handleShowSlaDate = (preferredDate, slaDate) => {
        if (preferredDate && slaDate) {
            if (preferredDate.isAfter(slaDate, 'days')) {
                this.setState({ showSlaDate: false })
            } else {
                this.setState({ showSlaDate: true })
            }
        }
    }

    canViewConfigBath = () => {
        if (this.props.line.cutToSize && this.props.line.cutToSize.config) {
            return true
        }

        return false
    }

    confirmDeleteCartItem = lineId => {
        confirm(this.props.intl.formatMessage({ id: 'DELETE_ITEM_LOWES_CONFIRM' }), { 
            isV2: true, 
            textOk: this.props.intl.formatMessage({ id: 'delete' }), 
            textCancel: this.props.intl.formatMessage({ id: 'cancel' })
         }).then(
            confirm => {
                this.props.onDelete(lineId)
            },
            cancel => {}
        )
    }
    isQuickShip = () => !!(this.props.line.cutToSize && this.props.line.cutToSize.config && this.props.line.cutToSize.config.thirdParty)

    canShowDatePicker = () => this.isQuickShip() || (!this.props.isCheckoutStepOneButtonNextDisabled && this.state.slaDate && !this.state.slaDate.isSame(moment(), 'day'))

    getMinPreferredDeliveryDate = () => {
        let additionalDays = 1;
        if (this.props.selectedCenter == 1100) additionalDays = this.props.minDeliveryDate
        else if (this.isQuickShip()) additionalDays = 28

        return getNextWorkingDay(additionalDays)
    }

    isProductFromMall() {
        if (this.props.line && this.props.line.extra && this.props.line.extra.mall) {
            return true
        }

        return false
    }

    getProductTitle(line) {
        if (line.cutToSize) {
            return (
                <React.Fragment>
                    {/* Color name (product name) */}
                    {line.cutToSize.colorName && <span className="feature">{line.cutToSize.colorName}</span>}

                    {/* Elaboration */}
                    {line.cutToSize.elaboration && <span className="feature">{this.props.intl.formatMessage(ElaborationOptionsMap[line.cutToSize.elaboration].name)}</span>}

                    {/* Dimensions - Format */}
                    {line.cutToSize.width && line.cutToSize.height && line.cutToSize.thickness && (
                        <span className="feature">
                            {this.props.adaptedFormat(line.cutToSize.width + 'X' + line.cutToSize.height, {
                                upper: true,
                                spaced: true,
                                showUnits: true
                            })}
                        </span>
                    )}

                    {/* Finish */}
                    {line.cutToSize.finish && (
                        <span className="feature">
                            <Translation id={line.cutToSize.finish} />
                        </span>
                    )}

                    {/* Thickness */}
                    {line.cutToSize.thickness && <span className="feature">{line.cutToSize.thickness}&nbsp;cm</span>}
                </React.Fragment>
            )
        } else if (line.product) {
            const product = line.product
            const isCheckoutStepTwo = this.props.typeLine && this.props.typeLine === 'checkout_v2_summary'

            return (
                <React.Fragment>
                    {isCheckoutStepTwo ? (
                        <ProductDisplayName product={product} style={{ display: 'block', fontSize: '14px' }} />
                    ) : (
                        <ProductDisplayName product={product} style={{ marginRight: '4px' }} />
                    )}

                    {/* Subtype */}
                    {product.subtypeName &&
                        <span className={`feature ${isCheckoutStepTwo ? 'feature-in-step-two' : ''}`}>
                            <Translation id={product.subtypeName} />
                        </span>
                    }

                    {/* Dimensions - Format */}
                    {product.format && (
                        <span className={`feature ${isCheckoutStepTwo ? 'feature-in-step-two' : ''}`}>
                            ({this.props.adaptedFormat(product.format, { upper: true, showUnits: true })})
                        </span>
                    )}

                    {/* Finish */}
                    {product.finish && (
                        <span className={`feature ${isCheckoutStepTwo ? 'feature-in-step-two' : ''}`}>
                            <Translation id={product.finish} />
                        </span>
                    )}

                    {/* Thickness */}
                    {product.thickness && <span className={`feature ${isCheckoutStepTwo ? 'feature-in-step-two' : ''}`}>{product.thickness + ' cm'}</span>}
                </React.Fragment>
            )
        } else {
            return null
        }
    }

    renderPriceDetails(line) {
        const isCheckoutStepOne = this.props.typeLine && this.props.typeLine === 'checkout_v2'
        const isCheckoutStepTwo = this.props.typeLine && this.props.typeLine === 'checkout_v2_summary'

        if ((line && line.product && line.product.pricesMktools) || (line.extra && line.extra.itsFree)) {
            // Mostrar precio usando DisplayPriceMktools
            return (
                <div className={`line-price-info ${isCheckoutStepTwo ? 'line-price-info-in-step-two' : ''}`}>
                    <div className="line-price">
                        <DisplayPriceMktools prices={line.product.pricesMktools} group={this.props.priceGroup} />
                    </div>
                </div>
            )
        }

        if (this.props.canViewPricesPhase2) {
            if (this.props.calculatingLinesPrice) {
                // Mostrar spinner mientras se calculan los precios
                return <Spinner isLoading={true} relative small />
            }

            return (
                <div className={`line-price-info ${isCheckoutStepTwo ? 'line-price-info-in-step-two' : ''}`}>
                    <div className="line-price">
                        {(this.getNewPricePerUnit() && this.getNewPrice() == 0) || !this.getNewPrice() || !this.hasZT00Price() ? (
                            <span style={{ fontSize: '16px' }}>
                                <Translation id="total_price" defaultMessage="Total price" />
                                :&nbsp;
                                <Translation id="not_available" defaultMessage="Not available" />
                            </span>
                        ) : (
                            this.getNewPrice()
                        )}{' '}
                        {this.props.linesPrice && this.props.linesPrice[line.lineId] && !this.getNewPrice() == 0 && this.hasZT00Price() && (
                            <React.Fragment>{this.props.linesPrice[line.lineId].coin}</React.Fragment>
                        )}
                    </div>

                    <div className="line-price-sub-info">
                        {this.isOutdated() && (
                            <span
                                data-tip={this.props.intl.formatMessage({
                                    id: 'project_expired_prices_not_charged',
                                    defaultMessage: 'Project is expired. Prices for this line are not charged.'
                                })}
                            >
                                <i className="alert-signal far fa-exclamation-triangle" />
                                <ReactTooltip bg-coloreffect="solid" place="bottom" />
                                &nbsp;&nbsp;
                            </span>
                        )}

                        {isCheckoutStepOne && (
                            <React.Fragment>
                                {(this.getNewPricePerUnit() && this.getNewPricePerUnit() == 0) || !this.getNewPricePerUnit() || !this.hasZT00Price() ? (
                                    <React.Fragment>
                                        <Translation id="unit_price" defaultMessage="Unit price" />
                                        :&nbsp;
                                        <Translation id="not_available" defaultMessage="Not available" />
                                    </React.Fragment>
                                ) : (
                                    this.getNewPricePerUnit()
                                )}{' '}
                            </React.Fragment>
                        )}

                        {isCheckoutStepOne && (
                            <React.Fragment>
                                {this.props.linesPrice && this.props.linesPrice[line.lineId] && !this.getNewPricePerUnit() == 0 && this.hasZT00Price() && (
                                    <React.Fragment>
                                        {this.props.linesPrice[line.lineId].coin} / {this.props.linesPrice[line.lineId].unit}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )
        }

        // Renderizar DisplayPrice para otros casos
        return (
            <React.Fragment>
                <div className={`line-price-info ${isCheckoutStepTwo ? 'line-price-info-in-step-two' : ''}`}>
                    <div className="line-price">
                        {!(line.extra && line.extra.outlet) && (
                            <DisplayPrice
                                userPreferences={this.props.userPreferences}
                                priceList={this.props.priceList}
                                userPriceList={this.props.userPriceList}
                                product={line.product}
                                isMktoolsCenter={this.props.isMktoolsCenter}
                                centerId={this.props.centerId}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderIncrementDecrementBlock(line) {
        const isCustomSample = line.extra && line.extra.type === 'custom_sample'
        const isBrochure = line.extra && line.extra.type === 'brochures'

        let qtyToAdd = null

        if (line.product !== undefined) {
            if (line.extra && line.extra.type === 'custom_sample') {
                qtyToAdd = 6
            } else if (line.cutToSize) {
                qtyToAdd = 1
            } else if (line.product && (line.product.typeId === '01' || line.product.typeId === 'NEWS')) {
                qtyToAdd = 1
            } else {
                qtyToAdd = line.product.minQty[this.props.shippingMethod]
            }
        }

        return (
            <React.Fragment>
                {!(line.bundleId || line.lotId) &&
                    (isCustomSample || isBrochure ? (
                        <React.Fragment>
                            {isCustomSample && (
                                <div className="line-actions--plus-less">
                                    <Translation id="pack_of_qutantity" defaultMessage="{packs} Pack of {quantity}" values={{ packs: '1', quantity: line.qty }} />
                                    &nbsp;
                                    <Translation id="samples" defaultMessage="Samples" />
                                </div>
                            )}
                            {isBrochure && (
                                <div className="line-actions--plus-less">
                                    <Translation id="pack_of_qutantity" defaultMessage="{packs} Pack of {quantity}" values={{ packs: '1', quantity: line.qty }} />
                                    &nbsp;
                                    <Translation id="brochures" defaultMessage="Brochures" />
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {this.props.onlyView ? (
                                <div className="line-actions--plus-less">
                                    {line.extra && line.extra.itsFree && (
                                        <div>
                                            <span className="its_a_gift" style={{ color: 'darkred' }}>
                                                <Translation id="its_a_gift" defaultMessage="Its a gift!" />
                                            </span>
                                        </div>
                                    )}
                                    <span>
                                        <Translation id="qty" defaultMessage="Qty" />: {line.qty}
                                    </span>
                                </div>
                            ) : (
                                <div className="line-actions--plus-less">
                                    <InputNumber
                                        value={line.qty || ''}
                                        change={qty => {
                                           if (qty != line.qty) {
                                               this.props.changeCartLineQty(line.lineId, qty)
                                           }
                                        }}
                                        size="small"
                                        step={this.props.isIkeaCenter && line.product.unit === 'M2' ? 0.01 : qtyToAdd}
                                        limit={this.getLimit(line)}
                                        min={this.getMin(line)}
                                        decimals={this.props.isIkeaCenter && line.product.unit === 'M2' ? 2 : 0}
                                        dataQas="checkout_product_quantity-add-remove-input"
                                        onDelete={() => this.confirmDeleteCartItem(line.lineId)}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
            </React.Fragment>
        )
    }

    renderSegmentationAndComment(line, type) {
        return (
            <div>
                {this.getCheckoutType() !== 'ikea' && this.getCheckoutType() !== 'lowes' && (
                    <React.Fragment>
                        {/* Segmentation */}
                        {type === 'checkout' && !this.props.isMktoolsCenter && !this.props.isMktoolsUsaCenter && (
                            <div className="segmentation-box">
                                <LineSegmentationV2 editSegmentation={this.editSegmentation} line={line} />
                            </div>
                        )}

                        {/* Comments */}
                        <div className="comment-box">
                            <span className="comment-title">
                                <Translation id="comment" defaultMessage="Comments" />:
                            </span>{' '}
                            <span className="comment-content">{line.comment ? line.comment.slice(0, 50) + '... ' : ''}</span>
                            {!this.state.showComment && (
                                <React.Fragment>
                                    <span
                                        className="comment-link"
                                        onClick={() => {
                                            this.setState({ showComment: true })
                                        }}
                                    >
                                        <Translation id="edit" defaultMessage="Edit" />
                                    </span>
                                </React.Fragment>
                            )}
                            {this.state.showComment && (
                                <span
                                    className="comment-link"
                                    onClick={() => {
                                        this.setState({ showComment: false })
                                    }}
                                >
                                    <Translation id="cancel" defaultMessage="Cancel" />
                                </span>
                            )}
                            {this.state.showComment && (
                                <div className={`pt-1 ${!this.state.showComment ? 'info--hidden' : ''}`}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={this.props.intl.formatMessage({ id: 'comment' })}
                                        ref={input => {
                                            this.inputComment = input
                                        }}
                                        onChange={e => {
                                            this.props.changeCartLineComment(line.lineId, e.target.value)
                                        }}
                                        onKeyDown={() => this.saveComment()}
                                        onPaste={() => this.saveComment()}
                                        value={line.comment}
                                        maxLength="105"
                                    />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    renderDeleteLineItem(line) {
        return (
            <div className="line-actions--delete">
                <button onClick={() => this.confirmDeleteCartItem(line.lineId)}>
                    <i className="fal fa-trash-alt" />
                    <Translation id="delete" defaultMessage="Delete" />
                </button>
            </div>
        )
    }

    renderAddToFavouriteLineItem(line) {
        return (
            <div className="line-actions--fav">
                <button onClick={() => this.props.onToggleFavourite(line.product.productId)}>
                    {this.props.isFavouriteById(line.productId) ? (
                        <React.Fragment>
                            <i style={{ color: '#81889C' }} className="fas fa-heart" />
                            <Translation id="remove_favourite" defaultMessage="Remove from favorites" />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <i className="far fa-heart" />
                            <Translation id="add_favourite" defaultMessage="Add to favorites" />
                        </React.Fragment>
                    )}
                </button>
            </div>
        )
    }

    renderCts50PercentInitialMsg() {
        return <Alerts status="warning" alert="CTS_50_PERCENT_DEPOSIT_INITIAL_MSG" cssIcon="fas fa-exclamation-triangle" iconSize="16px" className={'cartline-custom-alert-msg'} />
    }

    render() {
        const { line, onOpenBundleContent, type, intl, linesDeliveryDates, typeLine, isDisabledPreferedDeliveryDatePicker, isDefaultDeliveryDate } = this.props

        const DesiredDatePicker = forwardRef(({ value, onClick }, ref) => {
            const gtmLabel = 'ec_funnel_compra_fecha_entrega_producto_modificar'
            const tooltipId = line.lineId

            const handleClick = event => {
                event.preventDefault()
                if (line.cutToSize && !isDisabledPreferedDeliveryDatePicker) {
                    this.confirmPreferredDeliveryDateChange(onClick)
                } else {
                    onClick()
                }
            }

            if (!desiredDate && !slaDate)
                return (
                    <div onClick={handleClick} className="prefered-date-picker" gtm-label={gtmLabel}>
                        <span
                            data-tip={this.props.intl.formatMessage({
                                id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
                                defaultMessage:
                                    'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the left.'
                            })}
                            data-for={tooltipId}
                            style={{ color: isDisabledPreferedDeliveryDatePicker ? '#7D7D7D' : '' }}
                        >
                            <Translation id="set_preferred_date" defaultMessage="Do you want to set the preferred delivery date?" />
                            {isDisabledPreferedDeliveryDatePicker && <ReactTooltip id={tooltipId} effect="solid" place="bottom" className="delivery__date__tooltip" />}
                        </span>
                    </div>
                )
            else if (desiredDate && desiredDate !== slaDate)
                return (
                    <div onClick={handleClick} className="prefered-date-picker" gtm-label={gtmLabel}>
                        <span
                            data-tip={this.props.intl.formatMessage({
                                id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
                                defaultMessage:
                                    'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the left.'
                            })}
                            data-for={tooltipId}
                            style={{ color: isDisabledPreferedDeliveryDatePicker ? '#7D7D7D' : '' }}
                        >
                            <Translation id="preferred_delivery_date_at" defaultMessage="Preferred delivery date {date}" values={{ date: value }} />
                            {isDisabledPreferedDeliveryDatePicker && <ReactTooltip id={tooltipId} effect="solid" place="bottom" className="delivery__date__tooltip" />}
                        </span>
                    </div>
                )
            else
                return (
                    <div onClick={handleClick} className="prefered-date-picker" gtm-label={gtmLabel}>
                        <span
                            data-tip={this.props.intl.formatMessage({
                                id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
                                defaultMessage:
                                    'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the left.'
                            })}
                            data-for={tooltipId}
                            style={{ color: isDisabledPreferedDeliveryDatePicker ? '#7D7D7D' : '' }}
                        >
                            <Translation id="receive_on_another_date" defaultMessage="Do you want to receive it on another date?" />
                            {isDisabledPreferedDeliveryDatePicker && <ReactTooltip id={tooltipId} effect="solid" place="bottom" className="delivery__date__tooltip" />}
                        </span>
                    </div>
                )
        })

        let image = null
        let todayPlus15Days = new moment().add(15, 'days')
        let today = new moment()
        let launchDate = line.product && line.product.creationDate ? moment(line.product.creationDate) : null
        const isQuickShip = this.isQuickShip()
        const slaDate = this.state.slaDate ? this.state.slaDate.format('L') : null

        const storedDesiredDate = linesDeliveryDates[line.lineId] ? linesDeliveryDates[line.lineId] : null
        const desiredDate = storedDesiredDate ? storedDesiredDate.format('L') : null

        if ((line.extra && (line.extra.type === 'wall_sample' || line.extra.type === 'custom_sample')) || (line.extra && line.extra.type === 'brochures')) {
            image = <img alt="" src={this.props.getImageBackground(line.product)} />
        } else if (line.cutToSize && line.cutToSize.config && line.cutToSize.thumb) {
            image = (
                <div style={{ position: 'relative' }}>
                    {isQuickShip && <DisplayQuickShipIndicator />}
                    <img alt="" src={line.cutToSize.thumb} className="line-configurable-img"/>
                </div>
            )
        } else {
            image = (
                <LocalizedLink
                    className="cart_line__link_image"
                    routeId="ROUTE_PRODUCT_DETAIL"
                    params={{ id: line.product.productId, outlet: line.extra && !!line.extra.outlet ? 'outlet' : '' }}
                    onClick={() => this.props.setLastSelectedProduct(line.product, line.extra)}
                >
                    <ProductImage product={line.product} customClass="recurring_orders_image" inSmallBox={true} />
                </LocalizedLink>
            )
        }
        if (!line || !line.lineId) return null

        if (line.extra && line.extra.itsFree && !this.props.canViewPricesPhase3) return null

        //If the line already had a preferred delivery date and the cart has passed the isDisabledPreferredDeliveryDatePicker condition,
        //we delete the preferred delivery date that already had that position.
        if (linesDeliveryDates && linesDeliveryDates[line.lineId] && isDisabledPreferedDeliveryDatePicker && !isDefaultDeliveryDate) {
            this.props.unsetLineDeliveryDate(line.lineId)
        }

        const viewConfigBathDetailsBlock = (
            <div className="configurable__bath__view__config">
                <button onClick={() => this.handleOpenConfigBathModal()}>
                    <Translation id="view_customization_details" defaultMessage="View customization details" />
                </button>
            </div>
        )

        const isCheckoutStepOne = typeLine && typeLine === 'checkout_v2'
        const isCheckoutStepTwo = typeLine && typeLine === 'checkout_v2_summary'

        return (
            <div className="line__item__checkout" key={line.lineId}>
                {/* Fecha estimada */}
                {this.canViewSLA() && isCheckoutStepOne && (
                    <React.Fragment>
                        <div className="line__item__checkout--delivery-date">
                            {this.shouldShowSlaDate() && (
                                <div className="delivery-date-info">
                                    {this.props.isLoadingLinesSlaDate ? (
                                        <Spinner isLoading={true} relative small />
                                    ) : this.props.isCheckoutStepOneButtonNextDisabled ? (
                                        <span>
                                            <Translation
                                                id="the_estimated_delivery_date_will_be_available_when_minimum_weight_reached"
                                                defaultMessage="The estimated delivery date will be available when the minimum weight is reached."
                                            />
                                        </span>
                                    ) : (
                                        isQuickShip || (this.state.slaDate && !this.state.slaDate.isSame(moment(), 'day')) ? (
                                            isQuickShip ? (
                                                <span className="delivery-date-text">
                                                    <FormattedHTMLMessage
                                                        id="estimated_delivery_on_4_weeks"
                                                        defaultMessage="Estimated delivery on <b>2-4 weeks</b>"
                                                    />
                                                </span>
                                            ) : this.isProductFromMall() ? (
                                                <span>
                                                    <Translation
                                                        id="undefined_delivery_date"
                                                        defaultMessage="Delivery date pending of review by Cosentino"
                                                    />
                                                </span>
                                                ) : (
                                                    <span className="delivery-date-text">
                                                        <Translation
                                                            id="estimated_delivery_on"
                                                            defaultMessage="Estimated delivery on {date}"
                                                            values={{ date: slaDate }}
                                                        />
                                                    </span>
                                                )
                                        ) : (
                                            <span>
                                                <Translation
                                                    id="undefined_delivery_date"
                                                    defaultMessage="Delivery date pending of review by Cosentino"
                                                />
                                            </span>
                                        )
                                    )}
                                </div>
                            )}

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '6px' }}>
                                {this.canShowDatePicker() && !this.isProductFromMall() && (
                                    <div className="delivery-date-selector">
                                        <DatePicker
                                            selected={storedDesiredDate ? storedDesiredDate : this.state.slaDate}
                                            onChange={date => {
                                                this.props.setLineDeliveryDate(line.lineId, date)
                                                this.handleShowSlaDateNonCts(date, this.state.slaDate, line)
                                                this.handleSetAllLinesDeliveryDatesCts(date, line, this.state.slaDate)
                                                this.forceUpdate()
                                            }}
                                            minDate={this.getMinPreferredDeliveryDate()}
                                            className="form-control"
                                            autoComplete="off"
                                            onKeyDown={e => e.preventDefault()}
                                            dropdownMode="select"
                                            customInput={<DesiredDatePicker />}
                                            disabled={isDisabledPreferedDeliveryDatePicker}
                                        />
                                    </div>
                                )}

                                {this.state.slaDate && linesDeliveryDates[line.lineId] && this.state.slaDate.isAfter(linesDeliveryDates[line.lineId].endOf('day')) && (
                                    <div>
                                        <span
                                            data-tip={this.props.intl.formatMessage({
                                                id: 'desired_date_advertisement',
                                                defaultMessage:
                                                    'You have chosen a different date than the estimated delivery. We cannot guarantee this delivery date, but we will try to accommodate your request'
                                            })}
                                            data-for={line.lineId}
                                            className="prefered-date-warning-icon"
                                        >
                                            <i className="far fa-exclamation-triangle"></i>
                                        </span>
                                        <ReactTooltip id={line.lineId} effect="solid" place="bottom" className="delivery__date__tooltip" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}

                <div className="line__item__checkout--content">
                    {/* Left */}
                    <div className="content--left">
                        {/* Img Block*/}
                        <div className="image-block">
                            <div className="indicators" style={{ maxWidth: '100%', padding: '0' }}>
                                {isNewIndicator(line.product.creationDate) && line.product.stock == 0 && <DisplayUpcomingIndicator />}
                                {isUpcomingIndicator(line.product.creationDate) && <DisplayNewIndicator />}
                            </div>

                            {image}

                            <div className="d-flex justify-content-start flex-column" style={{ gap: '2px', paddingTop: '10px' }}>
                                {line.extra &&
                                    line.extra.isCosentinoGlobal &&
                                    this.props.naturalStoneViewingPermissions.canViewHQ &&
                                    (line.extra.outlet ? (
                                        <NaturalStoneIndicator idMessage={'outlet_in_hq'} defaultMessage="Outlet Spain" inSmallBox={true} />
                                    ) : (
                                        <NaturalStoneIndicator idMessage={'natural_stone_hq'} defaultMessage="Natural stone Spain" inSmallBox={true} />
                                    ))}

                                {line.extra && line.extra.isCosentinoBrazil && this.props.naturalStoneViewingPermissions.canViewBrazil && (
                                    <NaturalStoneIndicator idMessage={'natural_stone_brazil'} defaultMessage="Natural stone Brazil" inSmallBox={true} />
                                )}

                                {this.props.line.cutToSize && <CustomProductIndicator />}
                            </div>
                        </div>

                        {/* Text Block*/}
                        <div className="text-block">
                            {/* Brand */}
                            <div style={{ paddingBottom: '6px' }}>
                                {['SENSA', 'SCALEA', 'PREXURY'].includes(line.brand) ? (
                                    <React.Fragment>
                                        <div className="d-flex align-items-center" style={{ gap: '6px' }}>
                                            <DisplayBrand brand={line.brand} />
                                            <div className="product-brand">
                                                <span> ({<Translation id="natural_stone" defaultMessage="Natural Stone" />}*)</span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <DisplayBrand brand={line.brand} />
                                )}
                            </div>
                            {/* Product title */}
                            <div className="product-title-block">
                                {this.canViewConfigBath() ? (
                                    <span className="product-title">
                                        {getConfigLineName(line.cutToSize.config)}&nbsp;
                                        <Translation id="customized" defaultMessage="Custom" />
                                    </span>
                                ) : (
                                    <LocalizedLink
                                        routeId="ROUTE_PRODUCT_DETAIL"
                                        params={{ id: line.product.productId, outlet: line.extra && !!line.extra.outlet ? 'outlet' : '' }}
                                        onClick={() => this.props.setLastSelectedProduct(line.product, line.extra)}
                                    >
                                        {this.getProductTitle(line)}
                                    </LocalizedLink>
                                )}
                            </div>

                            {/* Product ID */}
                            {line.productId && (
                                <div className={`product-id ${isCheckoutStepTwo ? 'product-id-in-step-two' : ''}`}>
                                    <span>
                                        <Translation id="material_id" defaultMessage="Material ID" />
                                    </span>
                                    :&nbsp;
                                    <span>{line.productId}</span>
                                </div>
                            )}

                            {/* Config bath details block */}
                            {this.canViewConfigBath() && isCheckoutStepOne && <div className="config-bath-details-block">{viewConfigBathDetailsBlock}</div>}

                            {/* Other features */}

                            {/* LotId or bundleId */}
                            {line.bundleId ? (
                                <div className="text-feature">
                                    <span className="bundle-btn" onClick={() => onOpenBundleContent(line)}>
                                        <Translation id="bundle_id" defaultMessage="bundle_id" />
                                        :&nbsp;<span>{line.bundleId}</span>
                                    </span>
                                </div>
                            ) : line.lotId ? (
                                <div className="text-feature">
                                    <span>
                                        <DisplayLotId lotId={line.lotId} withDescription />
                                    </span>
                                </div>
                            ) : null}

                            {line.extra && line.extra.brand && (
                                <div className="text-feature">
                                    <span>
                                        <DisplayBrand brand={line.extra.brand} />
                                    </span>
                                </div>
                            )}

                            {line.extra && line.extra.colorName && (
                                 <div className="text-feature">
                                    <span>{line.extra.colorName}</span>
                                </div>
                            )}

                            {/* Type */}
                            {line.extra && line.extra.type && (
                                <div className="text-feature">
                                    <span>{intl.formatMessage({ id: line.extra.type })}</span>
                                </div>
                            )}

                            {/* Mallado (zaddon) */}
                            {line.product.zaddon === 'MAL' && (
                                <div className="text-feature">
                                    <span>
                                        <Translation id="MAL" />
                                    </span>
                                </div>
                            )}

                            {/* Mall img */}
                            {line.extra && line.extra.mall && (
                                <div>
                                    <img style={{ width: '40px' }} width="40" src={mallLarge} alt="logo mall" />
                                </div>
                            )}

                            {line.extra && line.extra.lot && (
                                <React.Fragment>
                                    {line.extra.lot.quarryBlock && (
                                        <div className="text-feature">
                                            <span>
                                                <Translation id="quarryBlock" defaultMessage="Quarry Block" />
                                            </span>
                                            :&nbsp;
                                            <span>{line.extra.lot.quarryBlock}</span>
                                        </div>
                                    )}
                                    {line.extra.lot.quality && (
                                        <div className="text-feature">
                                            <Translation id="quality" defaultMessage="Quality" />
                                            :&nbsp;
                                            <span>{line.extra.lot.quality}</span>
                                        </div>
                                    )}
                                    {line.extra.lot.shade && (
                                        <div className="text-feature">
                                            <Translation id="shade" defaultMessage="Shade" />
                                            :&nbsp;
                                            <span>{line.extra.lot.shade}</span>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}

                            {/* Attached docs CTS */}
                            {line.cutToSize && line.cutToSize.attachedDoc && (
                                <div className="text-feature">
                                    <i className="far fa-file" />
                                    <span>{line.cutToSize.attachedDoc}</span>
                                </div>
                            )}

                            {/* Indicators */}
                            <div className="indicators-block">
                                {!line.cutToSize && canDisplayDiscontinued(line.product.webHeading, line.product.discontinued) && (
                                    <DisplayDiscontinued discontinued={line.product.discontinued} inSmallBox={true} />
                                )}

                                {!line.cutToSize && line.extra && line.extra.outlet && (
                                    <DisplayOutletIndicator
                                        outletQuality={line.extra.outlet}
                                        outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                                        inSmallBox={true}
                                    />
                                )}
                            </div>

                            {/* Price for step two */}
                            {isCheckoutStepTwo && this.renderPriceDetails(line)}

                            {/* Quantity */}
                            {isCheckoutStepTwo && (
                                <div className="mini_cart_line_quantity">
                                    <Translation id="quantity" defaultMessage="Quantity" />
                                    :&nbsp;{line.qty}
                                </div>
                            )}

                            {isCheckoutStepTwo &&
                                this.canViewSLA() &&
                                (this.props.isLoadingLinesSlaDate ? (
                                    <Spinner isLoading={true} relative small />
                                ) : isQuickShip || (this.state.slaDate && !this.state.slaDate.isSame(moment(), 'day')) ? (
                                    <div className="estimated_date_block">
                                        {isQuickShip ? (
                                            <FormattedHTMLMessage
                                                id="estimated_delivery_on_4_weeks"
                                                defaultMessage="Estimated delivery on <b>2-4 weeks</b>"
                                            />
                                        ) : this.isProductFromMall() ? (
                                                <Translation
                                                    id="undefined_delivery_date"
                                                    defaultMessage="Delivery date pending of review by Cosentino"
                                                />
                                            ) : (
                                                <Translation
                                                    id="estimated_delivery_on"
                                                    defaultMessage="Estimated delivery on {date}"
                                                    values={{
                                                        date: this.showLongestDeliveryDate(storedDesiredDate, this.state.slaDate),
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                ) : (
                                    <div className="estimated_date_block">
                                        <Translation
                                            id="undefined_delivery_date"
                                            defaultMessage="Delivery date pending of review by Cosentino"
                                        />
                                    </div>
                                ))}

                            {/* Segmentation and comment - Desktop */}
                            {isCheckoutStepOne && (
                                <div className="segmentation-comment-desktop" style={{ padding: '6px 0' }}>
                                    {this.renderSegmentationAndComment(line, type)}
                                </div>
                            )}

                            {/* CTS 50% initial payment message - Desktop */}
                            {this.canViewConfigBath() && this.props.isUsaOrCanada && isCheckoutStepOne && (
                                <div className="alert-box-desktop">{this.renderCts50PercentInitialMsg()}</div>
                            )}

                            {/* Upcoming product alert */}
                            {launchDate > today && launchDate < todayPlus15Days && isCheckoutStepOne && (
                                <div className="alert-box-desktop">
                                    <Alerts
                                        status="warning"
                                        alert="upcoming_product_alert"
                                        cssIcon="fas fa-exclamation-triangle"
                                        iconSize="16px"
                                        className={'cartline-custom-alert-msg'}
                                    />
                                </div>
                            )}

                            {isCheckoutStepOne && (
                                <div className="line-actions-desktop">
                                    <div className="line-actions">
                                        {/* Actions - Increment or decrement quantity block */}
                                        {this.renderIncrementDecrementBlock(line)}

                                        {/* Actions - Delete material block */}
                                        {this.renderDeleteLineItem(line)}

                                        {/* Actions - Add material to favourite block */}
                                        {!this.canViewConfigBath() && this.renderAddToFavouriteLineItem(line)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Segmentation and comment - Responsive */}
                    {isCheckoutStepOne && (
                        <div className="segmentation-comment-responsive" style={{ padding: '12px 0' }}>
                            {this.renderSegmentationAndComment(line, type)}
                        </div>
                    )}

                    {/* CTS 50% initial payment message - Responsive */}
                    {this.canViewConfigBath() && this.props.isUsaOrCanada && isCheckoutStepOne && <div className="alert-box-responsive">{this.renderCts50PercentInitialMsg()}</div>}

                    {/* Upcoming product alert */}
                    {launchDate > today && launchDate < todayPlus15Days && isCheckoutStepOne && (
                        <div className="alert-box-responsive">
                            <Alerts status="warning" alert="upcoming_product_alert" cssIcon="fas fa-exclamation-triangle" iconSize="16px" className={'cartline-custom-alert-msg'} />
                        </div>
                    )}

                    {/* Right */}
                    {isCheckoutStepOne && (
                        <div className="content--right">
                            {/* Price */}
                            {this.renderPriceDetails(line)}

                            {/* Actions - Increment or decrement quantity block. Responsive */}
                            <div className="increment-decrement-responsive">{this.renderIncrementDecrementBlock(line)}</div>

                            <div className="delete-and-favourite-blocks-responsive">
                                {/* Actions - Delete material block. Responsive */}
                                {this.renderDeleteLineItem(line)}

                                {/* Actions - Add material to favourite block. Responsive */}
                                {!this.canViewConfigBath() && this.renderAddToFavouriteLineItem(line)}
                            </div>
                        </div>
                    )}
                </div>

                {/* Cts config modal configuration */}
                {this.state.showConfigBathModal && isCheckoutStepOne && (
                    <ModalConfigurationDetails
                        config={line.cutToSize.config}
                        refcode={line.cutToSize.ref}
                        showBtnEdit={true}
                        showBtnDownload={true}
                        onClose={() => {
                            this.setState({ showConfigBathModal: false })
                        }}
                    />
                )}
            </div>
        )
    }
}

export default injectIntl(CartLine)
