import {
    getCurrent, getFullUnits,
    getGoalMax,
    getGoalMin,
    getThicknessInCart,
    getUnitsForQty,
    isMallCartForContainer,
    qtySelector
} from '../cart/reducers'
import {
    getDestination,
    getHasDestination,
    getLowesDataEmail,
    getLowesDataFirstName,
    getLowesDataLastName,
    getSelectedShippingCenterId
} from './reducers'
import {
    getHasMinWeight,
    getShippingMethod,
    getShippingMethodWithoutWeights,
    isLatinaVitoria,
    isShippingTypeContainerWhenIreland
} from '../centers/reducers'
import { getWeightUnit } from '../profile-configuration/reducers'
import { getShippingAddresses } from '../shipping-addresses/reducers'
import { setLinesSlaDate } from '../cart/actions'
import {getToken} from "../login/reducers";
import * as api from "../api";
import { FormattedDate } from 'react-intl'


export const setDeliveryDate = deliveryDate => (dispatch) => {
    dispatch({ type: 'CHECKOUT_UNSET_ALL_LINES_DELIVERYDATES' })
    return dispatch({ type: 'CHECKOUT_SET_DELIVERYDATE', deliveryDate })
}
export const setStep = step => (dispatch, getState) => {
           dispatch({ type: 'CHECKOUT_SET_STEP', step: step })
       }
export const setCountryOfSend = countryOfSend => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_COUNTRYOFSEND', countryOfSend })
}
export const setAlert = () => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_ALERT', alert: 'alert' })
}
export const setDestination = destination => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_DESTINATION', destination })
}
export const setHasSelectedCenter = hasSelectedCenter => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_HASSELECTEDCENTER', hasSelectedCenter })
}
export const setSelectedShippingAddress = selectedShippingAddress => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_SELECTEDSHIPPINGADDRESS', selectedShippingAddress })
}
export const setSelectedShippingAddressId = selectedShippingAddressId => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_SELECTEDSHIPPINGADDRESSID', selectedShippingAddressId })
}
export const setSelectedShippingCenterId = selectedShippingCenterId => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_SELECTEDSHIPPINGCENTERID', selectedShippingCenterId })
}
export const setSelectedShippingAddressEcosentinoKey = ecosentinoKey => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_SELECTEDSHIPPINGECOSENTINOKEY', ecosentinoKey })
}
export const setSelectedShippingAddressSalesOrg = selectedShippingSalesOrg => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_SELECTEDSHIPPINGSALESORG', selectedShippingSalesOrg })
}
export const setSelectedCostCenter = selectedCostCenter => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_SELECTEDCOSTCENTER', selectedCostCenter })
}
export const setIkeaTransportArea = ikeaTransportArea => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_IKEATRANSPORTAREA', ikeaTransportArea })
}
export const setShippingAddressesEmails = shippingAddressesEmails => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_SHIPPINGADDRESSESEMAILS', shippingAddressesEmails })
}
export const setProfileConfigurationEmail = profileConfigurationEmail => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_PROFILECONFIGURATIONEMAIL', profileConfigurationEmail })
}
export const toggleSaleConditionsCheck = checked => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_TOGGLE_SALE_CONDITION_CHECK', checked })
}
export const toggleHealthConditionsCheck = checked => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_TOGGLE_HEALTH_CONDITION_CHECK', checked })
}
export const toggleAustraliaLegalProcessConditionsCheck = checked => (dispatch) => {
    dispatch({ type: 'CHECKOUT_TOGGLE_AUSTRALIA_LEGAL_PROCESS_CONDITION_CHECK', checked })
}
export const toggleVirtualVisitMandatorySfId = isMandatory => (dispatch) => {
    dispatch({ type: 'CHECKOUT_TOGGLE_VIRTUAL_VISIT_MANDATORY_SF_ID', isMandatory })
}
export const setVirtualVisitData = vvData => (dispatch) => {
    dispatch({ type: 'SET_VIRTUAL_VISIT_DATA', vvData })
}

//Datos de instalación de ikea (start)
export const setInstallationDataName = name => (dispatch, getState) => dispatch({ type: 'CHECKOUT_INSTALLATION_DATA_SET_NAME_IKEA', name })
export const setInstallationDataAddress = address => (dispatch, getState) => dispatch({ type: 'CHECKOUT_INSTALLATION_DATA_SET_ADDRESS_INSTALLATION_IKEA', address })
export const setInstallationDataTown = town => (dispatch, getState) => dispatch({ type: 'CHECKOUT_INSTALLATION_DATA_SET_TOWN', town })
export const setInstallationDataProvince = province => (dispatch, getState) => dispatch({ type: 'CHECKOUT_INSTALLATION_DATA_SET_PROVINCE', province })
export const setInstallationDataZipCode = zipCode => (dispatch, getState) => dispatch({ type: 'CHECKOUT_INSTALLATION_DATA_SET_ZIPCODE', zipCode })
export const setInstallationDataPhone = phone => (dispatch, getState) => dispatch({ type: 'CHECKOUT_INSTALLATION_DATA_SET_PHONE', phone })
//Datos de instalacion de ikea (end)

export const setComments = comments => (dispatch, getState) => {
    let commentsWithouthEmojis = comments.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    dispatch({ type: 'CHECKOUT_SET_COMMENTS', comments: commentsWithouthEmojis })
}
export const setPo = po => (dispatch, getState) => {
    let poWithouthEmojis = po.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    dispatch({ type: 'CHECKOUT_SET_PO', po: poWithouthEmojis })
}
export const setStoreId = storeId => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_STOREID', storeId })
}
export const setCutToSizeFile = file => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_CTS_FILE', file })
}
export const setEmployeeId = employeeId => (dispatch, getState) => {
    dispatch({ type: 'CHECKOUT_SET_EMPLOYEEID', employeeId })
}
export const setLowesDataFirstName = firstName => (dispatch, getState) => dispatch({ type: 'CHECKOUT_SET_LOWES_DATA_FIRST_NAME', firstName })
export const setLowesDataLastName = lastName => (dispatch, getState) => dispatch({ type: 'CHECKOUT_SET_LOWES_DATA_LAST_NAME', lastName })
export const setLowesDataEmail = email => (dispatch, getState) => dispatch({ type: 'CHECKOUT_SET_LOWES_DATA_EMAIL', email })
export const setLowesDataTitle = title => (dispatch, getState) => dispatch({ type: 'CHECKOUT_SET_LOWES_DATA_TITLE', title })

export const canSendLowesData = () => (dispatch, getState) => !!(getLowesDataFirstName(getState()) && getLowesDataLastName(getState()) && getLowesDataEmail(getState()))

export const resetCheckout = () => (dispatch, getStaet) => {
    return Promise.resolve(dispatch({ type: 'CHECKOUT_RESET' }))
}

export const canSendMinMaxCheckout = () => (dispatch, getState) => {
    const state = getState()

    const currentWeight = getCurrent(state, getWeightUnit(state))
    const goalMax = getGoalMax(state, getWeightUnit(state))
    const goalMin = getGoalMin(state, getWeightUnit(state))
    const shippingMethod = getShippingMethod(state)
    const shippingMethodWithoutWeight = getShippingMethodWithoutWeights(state)



    let unitsForQty;

    if(shippingMethodWithoutWeight === 'SINGLE_SLAB' || shippingMethodWithoutWeight === 'ENDI_TRUCK' || shippingMethodWithoutWeight === 'ENDI_CONTAINER'){
        unitsForQty = getUnitsForQty(goalMin, goalMax, currentWeight, false)
    } else if(shippingMethodWithoutWeight === 'CONTAINER' || shippingMethodWithoutWeight === 'DIRECT_TRUCK' || shippingMethodWithoutWeight === 'FULL_TRUCK'){
        unitsForQty = getUnitsForQty(goalMin, goalMax, currentWeight)
    }

    //if we are buying a container on natural stone brazil, only check the thickness rule
    if (isMallCartForContainer(state)) {
        unitsForQty = getUnitsForQty(goalMin, goalMax, currentWeight)
        dispatch(setUnitsForCart(unitsForQty))

        return unitsForQty.isValid
    }
    if (isLatinaVitoria(state)) {
        return dispatch(canSendNaturalStoneContainer())
    }
    dispatch(setUnitsForCart(unitsForQty))

    return (
        (unitsForQty && unitsForQty.isValid) ||
        (getHasMinWeight(state)) ||
        (shippingMethod === 'SINGLE_SLAB') ||
        shippingMethod === 'MKTOOLS' ||
        shippingMethod === 'LOWES_CUSTOMER_SAMPLES'
    )
}

export const canSendNaturalStoneContainer = () => (dispatch, getState) => {
    const conditionForNaturalStoneContainer = isLatinaVitoria(getState())

    if (conditionForNaturalStoneContainer) {
        const thickness = getThicknessInCart(getState())
        const unitsForQty = getNaturalStoneContainers(thickness, getState());


        dispatch(setUnitsForCart(unitsForQty))

        return unitsForQty.isValid
    }
    return false
}
export const getNaturalStoneContainers = (thickness, state) => {
    const qty = qtySelector(state);
    let minQty = 1; 
    let maxQty;


    //DISABLED REQUEST BY NATURAL STONE TEAM
    if (thickness.includes('3.0') && thickness.includes('2.0')) {
        minQty = 1;
        maxQty = 66;
    } else if (thickness.includes('3.0')) {
        minQty = 1;
        maxQty = 42;
    } else if (thickness.includes('2.0')) {
        minQty = 1;
        maxQty = 70;
    } else {
        return 0;
    }

    return getUnitsForQty(minQty, maxQty, qty)
}

export const setUnitsForCart = (unitsForQty) => (dispatch, getState) => {
    const stateFullUnits = getFullUnits(getState())

    if(unitsForQty && (stateFullUnits.units !== unitsForQty.units ||
      stateFullUnits.isValid !== unitsForQty.isValid ||
      stateFullUnits.qtyForNextUnit !== unitsForQty.qtyForNextUnit)){
        dispatch({type: 'SET_FULL_UNITS_FOR_CART', unitsForQty})
    }

}
//OBSOLETE 06/07/2020 with cansendnaturalstonecontainer
// export const canSendBundleLimit = () => (dispatch, getState) => {
//     let conditionForMaxBundle = getSelectedShippingAddress(getState()) && getShippingMethod(getState()) === 'CONTAINER' && getSelectedCenter(getState()) === 7500

//     if (conditionForMaxBundle) {
//         let bundleLimit = getCountryOfSend(getState()) === 'IN' ? 6 : 7
//         return getLenghtBundlesCart(getState()) === bundleLimit
//     }
//     return true
// }

export const canSendNeedDestination = () => (dispatch, getState) => {
    const state = getState()

    if (isShippingTypeContainerWhenIreland(state)) {
        return true
    }

    if (getHasDestination(state)) {
        return !!getDestination(state)
    }

    return true
}

export const changeShippingAddress = value => (dispatch, getState) => {
    const shippingAddresses = getShippingAddresses(getState())
    // const shippingAddress = shippingAddresses.filter(shippingAddres => {
    //     return shippingAddres.shippingAddressId === value
    // })[0]

    const shippingAddress = shippingAddresses.filter(shippingAddres => {
        return shippingAddres.ecosentinoKey === value
    })[0]
    dispatch(setCountryOfSend(shippingAddress.country))
    dispatch(setSelectedShippingAddress(shippingAddress))
    dispatch(setSelectedShippingAddressId(shippingAddress.shippingAddressId))
    dispatch(setSelectedShippingAddressSalesOrg(shippingAddress.salesOrg))
    dispatch(setSelectedShippingAddressEcosentinoKey(shippingAddress.ecosentinoKey))
    dispatch(setSelectedShippingCenterId(shippingAddress.centerId))
    dispatch(setHasSelectedCenter(getSelectedShippingCenterId(getState()) === value))
    dispatch(setLinesSlaDate())
}

export const setCheckoutStepOneButtonNextDisabled = (checkoutStepOneButtonNextDisabled) => (dispatch) => {
    dispatch({ type: 'CHECKOUT_STEP_ONE_BUTTON_NEXT_DISABLED', checkoutStepOneButtonNextDisabled })
}

export const fetchVirtualVisitSalesforceId = (intl) => (dispatch, getState) => {
    const state = getState()
    const userId = state.login.username
    const token = getToken(state)

    dispatch({ type: 'FETCH_VV_SALESFORCE_IDS_REQUEST', userId})

    return api.fetchVirtualVisitSalesforceId(userId, token).then (
        response => {
            const vvData = response.data.map(r => {
                const date = new Date(r.datetime)
                return {
                    value: r.salesforceId,
                    // label: r.salesforceId + ' - ' + date.toLocaleDateString()
                    // label: intl.formatMessage({id:"virtual_visit", defaultMessage:"Virtual visit"}) + ' - ' + intl.formatDate(date)
                    label: intl.formatDate(date)
                }
            })
            dispatch({ type: 'FETCH_VV_SALESFORCE_IDS_SUCCESS', response: vvData })
        },
        error => {
            dispatch({type: 'FETCH_VV_SALESFORCE_IDS_FAILURE'})
        }
    )
}