import { getShippingMethod, getSelectedCenter, getHQCenterId, getNaturalStoneSpainCenterId, getSpainCenters, isShippingTypeContainerWhenIreland } from '../centers/reducers'
import { getLanguage, getShowSaleConditions, getShowHealthConditions, getShowCheckboxAustraliaLegalProcess } from '../ui/reducers'
import moment from 'moment'
import {
    getIsCartCutToSize,
    getCartSlaDays,
    getFormatedLinesDeliveryDates
} from '../cart/reducers'

export const initState = {
    deliveryDate: null,
    countryOfSend: null,
    alert: null,
    destination: null,
    hasSelectedCenter: null,
    selectedShippingAddress: null,
    selectedShippingAddressId: null,
    selectedShippingCenterId: null,
    selectedShippingSalesOrg: null,
    selectedShippingEcosentinoKey: null,
    selectedCostCenter: null,
    ikeaTransportArea: null,
    shippingAddressesEmails: {},
    profileConfigurationEmail: [],
    comments: '',
    po: '',
    cutToSizeFile: null,
    storeId: null,
    employeeId: null,
    lowesData: { firstName: null, lastName: null, email: null, title: null },
    installationData: {
        name: null,
        address: null,
        town: null,
        province: null,
        zipCode: null,
        phone: null
    },
    saleConditionsCheck: null,
    haveOffer: false,
    healthConditionsCheck: null,
    australiaLegalProcessConditionsCheck: null,
    checkoutStepOneButtonNextDisabled: null,
    virtualVisitSalesforceIds: null,
    loadingVvSalesforceIds: false,
    virtualVisitData: null,
    // isVirtualVisitMandatorySfId: false,

}

const checkout = (state = initState, action) => {
    switch (action.type) {

        case 'CHECKOUT_SET_DELIVERYDATE':
            return { ...state, deliveryDate: action.deliveryDate }
        case 'CHECKOUT_SET_COUNTRYOFSEND':
            return { ...state, countryOfSend: action.countryOfSend }
        case 'CHECKOUT_SET_ALERT':
            return { ...state, alert: action.alert }
        case 'CHECKOUT_SET_DESTINATION':
            return { ...state, destination: action.destination }
        case 'CHECKOUT_SET_HASSELECTEDCENTER':
            return { ...state, hasSelectedCenter: action.hasSelectedCenter }
        case 'CHECKOUT_SET_SELECTEDSHIPPINGADDRESS':
            return { ...state, selectedShippingAddress: action.selectedShippingAddress }
        case 'CHECKOUT_SET_SELECTEDSHIPPINGADDRESSID':
            return { ...state, selectedShippingAddressId: action.selectedShippingAddressId }
        case 'CHECKOUT_SET_SELECTEDSHIPPINGSALESORG':
            return { ...state, selectedShippingSalesOrg: action.selectedShippingSalesOrg }
        case 'CHECKOUT_SET_SELECTEDSHIPPINGECOSENTINOKEY':
            return { ...state, selectedShippingEcosentinoKey: action.ecosentinoKey }

        case 'CHECKOUT_SET_SELECTEDSHIPPINGCENTERID':
            return { ...state, selectedShippingCenterId: action.selectedShippingCenterId }
        case 'CHECKOUT_SET_SELECTEDCOSTCENTER':
            return { ...state, selectedCostCenter: action.selectedCostCenter }
        case 'CHECKOUT_SET_IKEATRANSPORTAREA':
            return { ...state, ikeaTransportArea: action.ikeaTransportArea }
        case 'CHECKOUT_SET_SHIPPINGADDRESSESEMAILS':
            return { ...state, shippingAddressesEmails: action.shippingAddressesEmails }
        case 'CHECKOUT_SET_PROFILECONFIGURATIONEMAIL':
            return { ...state, profileConfigurationEmail: action.profileConfigurationEmail }
        case 'CHECKOUT_SET_COMMENTS':
            return { ...state, comments: action.comments }
        case 'CHECKOUT_SET_PO':
            return { ...state, po: action.po }
        case 'CHECKOUT_SET_CTS_FILE':
            return { ...state, cutToSizeFile: action.file }
        case 'CHECKOUT_SET_STOREID':
            return { ...state, storeId: action.storeId }
        case 'CHECKOUT_SET_EMPLOYEEID':
            return { ...state, employeeId: action.employeeId }
        case 'CHECKOUT_SET_LOWES_DATA_FIRST_NAME':
            return { ...state, lowesData: { ...state.lowesData, firstName: action.firstName } }
        case 'CHECKOUT_SET_LOWES_DATA_LAST_NAME':
            return { ...state, lowesData: { ...state.lowesData, lastName: action.lastName } }
        case 'CHECKOUT_SET_LOWES_DATA_EMAIL':
            return { ...state, lowesData: { ...state.lowesData, email: action.email } }
        case 'CHECKOUT_SET_LOWES_DATA_TITLE':
            return { ...state, lowesData: { ...state.lowesData, title: action.title } }
        case 'CHECKOUT_INSTALLATION_DATA_SET_NAME_IKEA':
            return { ...state, installationData: { ...state.installationData, name: action.name } }
        case 'CHECKOUT_INSTALLATION_DATA_SET_ADDRESS_INSTALLATION_IKEA':
            return { ...state, installationData: { ...state.installationData, address: action.address } }
        case 'CHECKOUT_INSTALLATION_DATA_SET_TOWN':
            return { ...state, installationData: { ...state.installationData, town: action.town } }
        case 'CHECKOUT_INSTALLATION_DATA_SET_PROVINCE':
            return { ...state, installationData: { ...state.installationData, province: action.province } }
        case 'CHECKOUT_INSTALLATION_DATA_SET_ZIPCODE':
            return { ...state, installationData: { ...state.installationData, zipCode: action.zipCode } }
        case 'CHECKOUT_INSTALLATION_DATA_SET_PHONE':
            return { ...state, installationData: { ...state.installationData, phone: action.phone } }
        case 'CHECKOUT_TOGGLE_SALE_CONDITION_CHECK':
            return { ...state, saleConditionsCheck: action.checked }
        case 'CHECKOUT_TOGGLE_HEALTH_CONDITION_CHECK':
            return { ...state, healthConditionsCheck: action.checked }
        case 'CHECKOUT_HAVE_OFFER':
            return { ...state, haveOffer: true }
        case 'CHECKOUT_HAVE_NOT_OFFER':
            return { ...state, haveOffer: false }
        case 'CHECKOUT_TOGGLE_AUSTRALIA_LEGAL_PROCESS_CONDITION_CHECK':
            return { ...state, australiaLegalProcessConditionsCheck: action.checked }
        case 'CHECKOUT_STEP_ONE_BUTTON_NEXT_DISABLED':
            return { ...state, checkoutStepOneButtonNextDisabled: action.checkoutStepOneButtonNextDisabled}

        case 'SET_VIRTUAL_VISIT_DATA':
            return { ...state, virtualVisitData: action.vvData }
        case 'FETCH_VV_SALESFORCE_IDS_REQUEST':
            return { ...state, loadingVvSalesforceIds: true }
        case 'FETCH_VV_SALESFORCE_IDS_SUCCESS':
            return { ...state, virtualVisitSalesforceIds: action.response, loadingVvSalesforceIds: false }
        case 'FETCH_VV_SALESFORCE_IDS_FAILURE':
            return { ...state, loadingVvSalesforceIds: false }

        //submit checkout ??
        case 'CHECKOUT_RESET':
            const virtualVisitSalesforceIds = state.virtualVisitSalesforceIds
            return { ...initState, virtualVisitSalesforceIds}
        default:
            return state
    }
}
export const getHealthConditionsCheck = state => {
    return state.checkout.healthConditionsCheck
}
export const canSendCheckoutHealthConditions = state => {
    return !getShowHealthConditions(state) || (getShowHealthConditions(state) && getHealthConditionsCheck(state))
}

export const getAustraliaLegalProcessConditionsCheck = state => {
    return state.checkout.australiaLegalProcessConditionsCheck
}

export const canSendCheckoutAustraliaLegalProcessConditions = state => {
    return !getShowCheckboxAustraliaLegalProcess(state) || (getShowCheckboxAustraliaLegalProcess(state) && getAustraliaLegalProcessConditionsCheck(state))
}

export const getSaleConditionsCheck = state => {
    return state.checkout.saleConditionsCheck
}
export const canSendCheckoutSaleConditions = state => {
    return !getShowSaleConditions(state) || (getShowSaleConditions(state) && getSaleConditionsCheck(state))
}
export const getDeliveryDate = state => {
    return state.checkout.deliveryDate
}
export const getCountryOfSend = state => {
    return state.checkout.countryOfSend
}
export const getAlert = state => {
    return state.checkout.alert
}
export const getDestination = state => {
    return state.checkout.destination
}
export const getHasSelectedCenter = state => {
    return state.checkout.hasSelectedCenter
}
export const getSelectedShippingAddress = state => {
    return state.checkout.selectedShippingAddress
}
export const getSelectedShippingAddressId = state => {
    return state.checkout.selectedShippingAddressId
}

export const getSelectedShippingCenterId = state => {
    return state.checkout.selectedShippingCenterId
}
export const getSelectedShippingSalesOrg = state => {
    return state.checkout.selectedShippingSalesOrg
}
export const getSelectedShippingEcosentinoKey = state => {
    return state.checkout.selectedShippingEcosentinoKey
}

export const getSelectedCostCenter = state => {
    return state.checkout.selectedCostCenter
}
export const getIkeaTransportArea = state => {
    return state.checkout.ikeaTransportArea
}
export const getShippingAddressesEmails = state => {
    return state.checkout.shippingAddressesEmails
}
export const getProfileConfigurationEmail = state => {
    return state.checkout.profileConfigurationEmail
}
export const getHasDestination = state => {
    return getShippingMethod(state) === 'CONTAINER'
}
export const getComments = state => {
    return state.checkout.comments
}
export const getPo = state => {
    return state.checkout.po
}

export const getCutToSizeFile = state => {
    return state.checkout.cutToSizeFile
}
export const getStoreId = state => {
    return state.checkout.storeId
}
export const getEmployeeId = state => {
    return state.checkout.employeeId
}
export const getLowesDataFirstName = state => state.checkout.lowesData.firstName
export const getLowesDataLastName = state => state.checkout.lowesData.lastName
export const getLowesDataEmail = state => state.checkout.lowesData.email
export const getLowesDataTitle = state => state.checkout.lowesData.title

export const getInstallationDataName = state => {
    return state.checkout.installationData.name
}
export const getInstallationDataAddress = state => {
    return state.checkout.installationData.address
}
export const getInstallationDataTown = state => {
    return state.checkout.installationData.town
}
export const getInstallationDataProvince = state => {
    return state.checkout.installationData.province
}
export const getInstallationDataZipCode = state => {
    return state.checkout.installationData.zipCode
}
export const getInstallationDataPhone = state => {
    return state.checkout.installationData.phone
}
export const getHaveOffer = state => {
    return state.checkout.haveOffer
}
export const canSeeVirtualVisitOptions = state => {
    return (state.login.impersonatedBy === 'VITORIA' || state.login.impersonatedBy === 'VITORIAFT') && state.login.userType === 'CUSTOMER'
    //&& getSalesOrg(state) == '2012'
    //&& getSelectedCenter(state) == getLatinaVitoriaCenterId()
}
export const getVirtualVisitSalesforceIds = state => {
    return state.checkout.virtualVisitSalesforceIds
}

export const getIsVirtualVisitMandatorySfId = state => {
    return state.checkout.virtualVisitData
        && (state.checkout.virtualVisitData.type === 'virtual_visit' || state.checkout.virtualVisitData.isVirtualPromo)
        && state.checkout.virtualVisitData.salesforceId === null
}

export const getMinRequiredDate = state => {
    if (!getSelectedShippingAddress(state)) return 1
    let minDate = 1
    const iberia = [
        '7701',
        '7702',
        '7703',
        '7704',
        '7705',
        '7706',
        '7707',
        '7708',
        '7709',
        '7710',
        '7711',
        '7712',
        '7713',
        '7714',
        '7715',
        '7716',
        '7717',
        '7718',
        '7719'
    ]
    const usaWest = ['7601', '7605', '7608', '7619', '7621', '7620', '7628', '7622', '7610', '7632', '7618', '7623', '7625', '7635']
    const usaEast = [
        '7602',
        '7603',
        '7604',
        '7605',
        '7606',
        '7607',
        '7609',
        '7612',
        '7613',
        '7624',
        '7611',
        '7614',
        '7615',
        '7616',
        '7643',
        '7634',
        '7630',
        '7626',
        '7617',
        '7627',
        '7629',
        '7631',
        '7633',
        '7637',
        '7636',
        '7638',
        '7640',
        '7642',
        '7666'
    ]
    const ukOrScandinavia = ['2002', '2038', '2039', '2016', '2007', '2015']
    const latam = ['2012', '2026', '2027']
    const europe = ['2000', '2001', '2004', '2008', '2009', '2010', '2014', '2042','1100', '2049', '2047', '2050']
    const canada = ['2032', '2036']
    const israel = ['2021']
    const turkeyAndMiddleEast = ['2018', '2035', '2048', '2045']
    const asia = ['2017', '2043', '2034']
    const southafrica = ['2041']
    const australiaAndOceania = ['2031', '2040']
    if ((getSelectedCenter(state) === getHQCenterId() || getSelectedCenter(state) === getNaturalStoneSpainCenterId()) && getShippingMethod !== 'SINGLE_SLAB') {
        let minDateForOrderFromHQ = 7
        if (getSelectedCenter(state) === getNaturalStoneSpainCenterId()) minDateForOrderFromHQ = 3

        // Europa - 9 dias
        if (europe.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 9
        // Iberia 8 dias
        if(iberia.includes(getSelectedShippingAddress(state).centerId)) minDate = 1
        // UK + Escandinavia - 2 semanas
        if (ukOrScandinavia.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 14
        // Israel - 3 semanas
        if (israel.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 14
        // Turquía + Oriente Próximo - 4 semanas
        if (turkeyAndMiddleEast.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 21
        // USA costa este - 5 semanas
        if (usaEast.includes(getSelectedShippingAddress(state).centerId)) minDate = 28
        // USA costa oeste - 7 semanas
        if (usaWest.includes(getSelectedShippingAddress(state).centerId)) minDate = 42
        // Canada - 6 semanas
        if (canada.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 42
        // Asia - 7 semanas
        if (asia.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 42
        // Australia - 9 Semanas
        if (australiaAndOceania.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 56
        // Sudáfrica - 5 semanas
        if (southafrica.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 28
        // América latina - 7 semanas
        if (latam.includes(getSelectedShippingAddress(state).salesOrg)) minDate = 42

        minDate = minDate + minDateForOrderFromHQ
    }else{
        minDate = getCartSlaDays(state)
    }

    return minDate
}

export const getCheckoutData = (isCustomer, state) => {
    let checkoutData = {
        storeId: getStoreId(state),
        shippingAddressId: getSelectedShippingAddressId(state),
        shippingCenterId: getSelectedShippingCenterId(state),
        shippingSalesOrg: getSelectedShippingSalesOrg(state),
        ecosentinoKey: getSelectedShippingEcosentinoKey(state),
        costCenterId: getSelectedCostCenter(state),
        preferredDeliveryDate: (!isCustomer && getDeliveryDate(state)) ? moment(getDeliveryDate(state)).format('YYYY/MM/DD') : null,
        linesPreferredDeliveryDates: getFormatedLinesDeliveryDates(state),
        destination: getDestination(state),
        po: getPo(state),
        comments: getComments(state),
        lang: getLanguage(state),
        shippingType: getShippingMethod(state, isShippingTypeContainerWhenIreland(state)),
        ikeaTransportArea: getIkeaTransportArea(state),
        employeeId: getEmployeeId(state),
        haveCutToSize: getIsCartCutToSize(state),
        saleConditionsCheck: getSaleConditionsCheck(state),
        haveOffer: getHaveOffer(state),
        cutToSizeFile: getCutToSizeFile(state),
        healthConditionsCheck: getHealthConditionsCheck(state),
        healthConditionsVersion: getHealthConditionsVersion(state),
        australiaLegalProcessConditionsCheck: getAustraliaLegalProcessConditionsCheck(state),
        virtualVisitData: state.checkout.virtualVisitData
    }

    return checkoutData
}

export const getCheckoutStepOneButtonNextDisabled = state => {
    return state.checkout.checkoutStepOneButtonNextDisabled
}

export const getHealthConditionsVersion = (state) => {
    if (getShowHealthConditions(state)) {
        if (getUseOldHealthConditionsWorkflow(state)) {
            return null
        } else {
            return 'V.02-01/2025'
        }
    }

    return null
}

/**
 * Determines whether to use the old acceptance flow of health conditions.
 * @returns {boolean}
 */
export const getUseOldHealthConditionsWorkflow = state => {
    const spainCenters = getSpainCenters()

    if (spainCenters.includes(getSelectedCenter(state).toString())) {
        return true
    }

    return false
}

export default checkout
