import Translation from "../global/Translation";

import React from 'react'
import {injectIntl} from "react-intl";
import DisplayOutletIndicator from "../catalog/rectangleResult/displays/DisplayOutletIndicator";
import Button from "../global/Button";
import LocalizedLink from "../global/LocalizedLink";
import IntegerInputNumber from "../input-number-other/IntegerInputNumber";
import noBundleImage from '../../assets/img/no-image-bundle.png'
import cameraOff from "../../assets/img/icons/camera-off.svg";
import DisplayNaturalStoneOrigin from "../catalog/rectangleResult/displays/DisplayNaturalStoneOrigin";
import {v4 as uuidv4} from "uuid";


class LotTableRow extends React.Component {

    render() {

        const { product, centerId, defaultImage, lot, isAdmin, impersonatedBy, outlet, userType, width, length, surface, getMinimumValue,
            isLot, lotImage, maxQuantity, unit, lunit, setQuantity, addToCart, canViewLotId, canViewAllLots, selectedProduct } = this.props


        let _defaultImage = defaultImage;
        if (!_defaultImage) {
            if (!isLot) _defaultImage = noBundleImage;
            else _defaultImage = cameraOff;
        }


        return (

            <tr style={{
                width: '100%',
                //paddingRight: '17px',
                height:'85px',
                alignItems: 'center',
                //borderTop: "1px solid grey",
                fontWeight:'400',
                fontSize: "15px"
            }}>

                <td>
                    <div className="wrapper-product-image">
                        {
                            lotImage ? (
                                <LocalizedLink
                                    // target="_blank"
                                    routeId="ROUTE_LOT_IMAGE_VIEWER"
                                    params={{
                                        parentUrl: window.location.href,
                                        productId: product.productId,
                                        center: centerId,
                                        lotId: lot.lotId,
                                        quarryBlock: lot.quarryBlock.replace('/', '-'),
                                        quality: lot.quality.replace('/', '-'),
                                        outlet: outlet ? outlet : ''
                                    }}
                                    gtmLabel="ec_lupa"
                                >
                                    {/*<img src={lotImage} className="" alt="" style={{ width: '100%' }} />*/}
                                    <div className="magnifier" style={{
                                        position: "relative",
                                        // backgroundImage: `url(${lotImage}), url(${lupa})`,
                                        backgroundImage: `url(${lotImage})`,
                                        height:'60px',
                                        width:'60px',
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundBlendMode: "difference"
                                    }}>
                                        <i className="fas fa-search"
                                           style={{width: '100%', lineHeight:'60px', textAlign:'center', textShadow:'#fff 1px 1px'}}
                                        />
                                        {/*<img src={lupa}  height="60px" width="60px"*/}
                                        {/*    style={{*/}
                                        {/*    position: "absolute",*/}
                                        {/*    backgroundImage: `url(${lupa})`,*/}
                                        {/*    height:'60px',*/}
                                        {/*    width:'60px',*/}
                                        {/*    backgroundSize: "contain",*/}
                                        {/*    backgroundPosition: "center"*/}
                                        {/*}}/>*/}
                                    </div>

                                </LocalizedLink>
                            ) : (
                                <div
                                    className="lot-row-image tooltip-container magnifier-withouth-image"
                                    style={{
                                        backgroundImage: `url(${_defaultImage})`,
                                        backgroundSize: `${isLot ? '75%' : 'cover'}`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat"
                                }}>
                                    {
                                        !defaultImage && (
                                            <span className="tooltip">
                                                <Translation id="image-no-available" defaultMessage="Sorry, the image is not available"/>
                                            </span>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </td>

                <td>
                    {
                        isLot ? (
                            <div>
                                <div><Translation id="lot" defaultMessage="Lot" /></div>
                                {/*<div>{canViewLotId ? lot.lotId : ''}</div>*/}
                                <div>{lot.lotId}</div>
                                {lot.bundleId &&
                                    <div><small>Bundle ID {lot.bundleId}</small></div>
                                }
                            </div>
                        ) : (
                            <div>
                                <div>Bundle</div>
                                <div>{lot.bundleId}</div>
                                {
                                    this.props.isLatinaVitoria ? (
                                        lot.lots && (
                                            <div style={{cursor:'pointer', color:"blue"}}
                                                 onClick={() => this.props.openBundleContent(lot)}
                                            >
                                                <small><Translation id="view_lots" defaultMessage="View lots" /></small>
                                            </div>
                                        )
                                    ) : (
                                        lot.lotId && canViewLotId && (
                                            <div><small><Translation id="lot" defaultMessage="Lot" />: { lot.lotId }</small></div>
                                        )
                                    )
                                }
                            </div>
                        )
                    }
                </td>

                <td>
                    {lot.quarryBlock}
                </td>

                {
                    (!canViewAllLots || isAdmin) && (
                        <td style={{textAlign:'center'}}>
                            {lot.quality}
                        </td>
                    )
                }

                {
                    outlet && (
                        <td style={{textAlign:'center'}}>
                            {
                                lot.qualityOutlet && (
                                    <div className="m-auto" style={{width:'fit-content'}}>
                                        {outlet && (
                                            <DisplayOutletIndicator
                                                outletQuality={lot.qualityOutlet} //{this.product.outletQuality}
                                                outletDeviated={isAdmin || impersonatedBy ? product.outletDeviated : null}
                                            />
                                        )}
                                    </div>
                                )
                            }
                        </td>
                    )
                }

                <td style={{textAlign:'end', position:'relative'}}>
                    {length} x {width} {lunit()}{!isLot && (<div style={{position:"absolute", top:"calc(50% - 0.7em)", right:"-0.25em"}}>*</div> )}
                </td>

                <td style={{textAlign:'end', position:'relative'}}>
                    {surface} {unit}2{!isLot && (<div style={{position:"absolute", top:"calc(50% - 0.7em)", right:"-0.25em"}}>*</div> )}
                </td>

                <DisplayNaturalStoneOrigin product={product} isLotTableView={true}/>

                {(isAdmin  || this.props.impersonatedBy || this.props.isMexico) && (
                    <td style={{textAlign:'center'}}>
                        {lot.shade}
                    </td>
                )}
                {(isAdmin  || this.props.impersonatedBy) && (
                    <td>
                        {lot.location}
                    </td>
                )}

                <td/>

                <td>
                    <div className={`d-flex ${this.props.minimumOrder ? 'justify-content-end' : 'justify-content-between'}`}>
                        <div className="input">
                            <IntegerInputNumber
                                min={getMinimumValue()}
                                max={maxQuantity}
                                onChangeValue={(v) => setQuantity(v)}
                                disabled={isAdmin}
                            />
                        </div>

                        {!isAdmin && !this.props.minimumOrder && (
                            <Button onClick={() => addToCart()} inverted icon="shopping-cart" size="medium" customClass="square btn-purchase-list" gtmLabel="ec_comprar_lista">
                                {userType === 'SHOP' ? <Translation id="reserve" defaultMessage="Reserve" /> : <Translation id="buy" defaultMessage="Buy" />}
                            </Button>
                        )}
                    </div>

                </td>

            </tr>
        )
    }
}

export default injectIntl(LotTableRow)