import React, { useState } from 'react'
import Button from '../global/Button'
import Translation from '../global/Translation'
import TextInput from '../global/TextInput'
import FloatingAlert from '../alerts/FloatingAlert'
import {SEGMENTATION_V3_STEP} from "./SegmentationSteps";

const SegmentationEvidence = props => {
    const { onSubmitSegmentation, callback, onSetStep, onBackStep, setSegmentationEvidence } = props
    const [purchaseOrders, setPurchaseOrders] = useState([])
    const [purchaseOrderInputValue, setPurchaseOrderInputValue] = useState('')
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileSizeError, setFileSizeError] = useState(false)
    const [fileTypeError, setFileTypeError] = useState(false)

    const FILE_LIMIT_SIZE = 4 * 1024 * 1024 // 4 MB in bytes
    const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']

    const handleAddPurchaseOrder = () => {
        if (purchaseOrderInputValue.trim()) {
            setPurchaseOrders([...purchaseOrders, purchaseOrderInputValue])
            setPurchaseOrderInputValue('') // Clear input field after adding
        }
    }

    const handleDeletePurchaseOrder = index => {
        setPurchaseOrders(purchaseOrders.filter((_, i) => i !== index))
    }

    const handlePurchaseOrderInputChange = event => {
        setPurchaseOrderInputValue(event.target.value)
    }

    const handleFileChange = event => {
        const files = Array.from(event.target.files)
        const validFiles = []
        let fileTooLarge = false
        let fileTypeNotAllowed = false
    
        files.forEach(file => {
            if (file.size > FILE_LIMIT_SIZE) {
                fileTooLarge = true
            } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                fileTypeNotAllowed = true
            } else {
                validFiles.push(file)
            }
        })
    
        setFileSizeError(fileTooLarge)
        setFileTypeError(fileTypeNotAllowed)
    
        if (!fileTooLarge && !fileTypeNotAllowed) {
            setUploadedFiles([...uploadedFiles, ...validFiles])
        }
    }

    const handleAddToCart = () => {
        // const formData = new FormData()
        //
        // purchaseOrders && purchaseOrders.forEach((order, index) => {
        //     formData.append(`purchaseOrders[${index}]`, order)
        // })
        //
        // uploadedFiles && uploadedFiles.forEach((file, index) => {
        //     formData.append(`uploadedFiles[${index}]`, file)
        // })

        const formData = {
            purchaseOrders,
            uploadedFiles
        }

        //console.log(Array.from(formData.entries()))

        setSegmentationEvidence(formData).then(() => {
            onSubmitSegmentation(callback)
        })
    }

    const handleDeleteFile = index => {
        setUploadedFiles(uploadedFiles.filter((_, i) => i !== index))
    }

    const mandatoryEvidences = props.evidencesType === '02'

    const disableSubmit = () => {
        return mandatoryEvidences && purchaseOrders.length === 0 && uploadedFiles.length === 0
    }


    return (
        <div className="segmentation__evidence">
            <div className="segmentation__evidence__content">
                <div>
                    <h5>
                        <Translation
                            id="segmentation_evidence_in_order_to_verify_that_this_segmentation_is_correct"
                            defaultMessage="In order to verify that this segmentation is correct, you can upload your customer's purchase documents and/or include your customer's purchase order number."
                        />
                    </h5>
                </div>
                <div className="segmentation__evidence__content--input">
                    <div>
                        <span className="title">
                            <Translation
                                id="segmentation_evidence_enter_one_purchase_order_from_your_customer"
                                defaultMessage="Enter one or more purchase orders from your customer:"
                            />
                            {mandatoryEvidences && ' *'}
                        </span>
                    </div>
                    <div className="input-content">
                        <div className="input-area">
                            <TextInput placeholder={'00000000'} value={purchaseOrderInputValue} onChange={handlePurchaseOrderInputChange} />
                            <Button onClick={handleAddPurchaseOrder} icon="fa fa-plus" className="add-another-purchase-btn">
                                <Translation id="add_another_purchase_order" defaultMessage="Add purchase order" />
                            </Button>
                        </div>

                        {/* Display the list of added purchase orders */}
                        <div className="purchase-orders-list">
                            {purchaseOrders &&
                                purchaseOrders.map((order, index) => (
                                    <div key={index} className="purchase-order-item">
                                        <span>{order}</span>
                                        <Button onClick={() => handleDeletePurchaseOrder(index)} icon="fa fa-trash" className="delete-purchase-btn" />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="segmentation__evidence__content--upload">
                    <div>
                        <span className="title">
                            <Translation id="upload_one_or_more_purchase_documents" defaultMessage="Upload one or more purchase documents:" />
                            {mandatoryEvidences && ' *'}
                        </span>
                    </div>
                    <input type="file" onChange={handleFileChange} id="segmentation_evidence_file_upload" style={{ display: 'none' }} />
                    <Button
                        className="custom-upload-button"
                        onClick={() => document.getElementById('segmentation_evidence_file_upload').click()}
                        icon={'fa fa-upload'}
                        iconEnd={true}
                    >
                        <Translation id="upload" defaultMessage="upload" />
                    </Button>

                    {fileSizeError && (
                            <FloatingAlert
                                warningMessage={
                                    <Translation id="exceed_file_limit" 
                                        defaultMessage={`The file has a limit of {fileLimit} MB`}
                                        values={{fileLimit: FILE_LIMIT_SIZE / (1024 * 1024)}} // Convert to MB
                                    />
                                }
                                onClose={() => setFileSizeError(false)}
                            />
                        )
                    }

                    {fileTypeError && (
                        <FloatingAlert
                            warningMessage={
                                <Translation
                                    id="invalid_file_type"
                                    defaultMessage="This file type is not allowed."
                                />
                            }
                            onClose={() => setFileTypeError(false)}
                        />
                    )}

                    {/* Display the list of uploaded files */}
                    <div className="uploaded-files-list">
                        {uploadedFiles &&
                            uploadedFiles.map((file, index) => (
                                <div key={index} className="uploaded-file-item">
                                    <div className="file-icon">
                                        <i class="fa fa-file" aria-hidden="true" />
                                    </div>
                                    <div>
                                        <span className="file-name">{file.name}</span>
                                        <span className="file-size">&nbsp;{file.size} kb</span>
                                    </div>
                                    <Button onClick={() => handleDeleteFile(index)} icon="fa fa-trash" className="delete-file-btn" />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <hr></hr>
            <footer>
                <Button className={'segmentation-footer-back'} onClick={onBackStep} icon="fal fa-chevron-left">
                    <Translation id="back" defaultMessage="Back" />
                </Button>
                <Button onClick={() => handleAddToCart()}
                        inverted dataQas="global_segmentation-add-to-cart-button"
                        icon="fa fa-shopping-cart"
                        iconEnd={true}
                        disabled={disableSubmit()}
                >
                    <Translation id="add_to_cart" defaultMessage="Add to cart" />
                </Button>
            </footer>
        </div>
    )
}

export default SegmentationEvidence
