import ReactTooltip from "react-tooltip";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as InfoIcon } from '../../assets/img/icons/info.svg'

const InfoWithToolTip = props => {

    return (
        <div className="info_with_tool_tip">
            <span data-tip={props.toolTipText} >
                <ReactTooltip className = "tooltip" bg-coloreffect="solid" place={props.place} openEvents={['click','mouseover ']}/>
                {props.children}
                <InfoIcon/>
            </span>
        </div>
    )
}

InfoWithToolTip.propTypes = {
    toolTipText: PropTypes.string.isRequired,
    place: PropTypes.oneOf(['top', 'bottom'])
}

InfoWithToolTip.defaultProps = {
    place: 'top'
}


export default InfoWithToolTip