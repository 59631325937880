import React, { useEffect, useState } from 'react'
import SegmentationV3 from './SegmentationV3'
import SegmentationEvidence from './SegmentationEvidence'
import SuggestedSegmentation from "./SuggestedSegmentation";
import SavedSegmentations from "./SavedSegmentations";
import SavingSegmentation from "./SavingSegmentations";
import SegmentationSavedSuccesfully from "./SegmentationsSavedSuccesfully";


export const SUGGESTED_SEGMENTATIONS_STEP = 0;
export const SEGMENTATION_V3_STEP = 1;
export const SEGMENTATION_EVIDENCE_STEP = 2;
export const SAVED_SEGMENTATIONS_STEP = 3;
export const SAVING_SEGMENTATION_STEP = 4;
export const SEGMENTATION_SUCCESSFULLY_SAVED = 5;

const SegmentationSteps = (props) => {
    const { show, setSegmentationCurrentStep } = props
    const [currentStep, setCurrentStep] = useState(props.suggestedSegmentations.length > 0 ? SUGGESTED_SEGMENTATIONS_STEP : SEGMENTATION_V3_STEP)
    const [prevStep, setPrevStep] = useState()

    useEffect(() => {
        setSegmentationCurrentStep(currentStep)
    }, [currentStep])

    const handleNextStep = () => {
        setCurrentStep(prevStep => {
            const nextStep = prevStep + 1
            setSegmentationCurrentStep(nextStep)
            return nextStep
        })
    }

    const handleBackStep = () => {
        // setCurrentStep(prevStep => {
        //     const nextStep = prevStep - 1
        //     setSegmentationCurrentStep(nextStep)
        //     return nextStep
        // })

        setSegmentationCurrentStep(prevStep)
        setCurrentStep(prevStep)
    }

    const setStep = step => {
        setPrevStep(currentStep)
        setSegmentationCurrentStep(step)
        setCurrentStep(step)
    }

    const getStepContent = () => {
        switch (currentStep) {
            case SUGGESTED_SEGMENTATIONS_STEP:
                return <SuggestedSegmentation {...props} onSetStep={setStep} />
            case SEGMENTATION_V3_STEP:
                return <SegmentationV3 {...props} onSetStep={setStep} />
            case SEGMENTATION_EVIDENCE_STEP:
                return <SegmentationEvidence {...props} onBackStep={handleBackStep} />
            case SAVED_SEGMENTATIONS_STEP:
                return <SavedSegmentations {...props} onSetStep={setStep} />
            case SAVING_SEGMENTATION_STEP:
                return <SavingSegmentation {...props} onSetStep={setStep} />
            case SEGMENTATION_SUCCESSFULLY_SAVED:
                return <SegmentationSavedSuccesfully {...props} onSetStep={setStep} />
            default:
                return <SegmentationV3 {...props} onSetStep={setStep} />
        }
    }

    return (
        <React.Fragment>
            {getStepContent()}
        </React.Fragment>
    )
}

export default SegmentationSteps
