import React from 'react'
import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import Button from '../global/Button'
import GoBack from '../global/GoBack'
import InputNumber from '../global/InputNumber'
import LocalizedLink from '../global/LocalizedLink'
import ProductDisplayName from '../global/ProductDisplayName'
import Select from 'react-select'
import withRouter from 'react-router/withRouter'
import iconDownload from '../../assets/img/icons/download-gray.svg'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import DisplayStock from '../catalog/rectangleResult/displays/DisplayStock'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import FiltersProduct from '../FiltersProduct'
import ProductCarouselV2 from './ProductCarouselV2'
import DisplayPrice from '../catalog/rectangleResult/displays/DisplayPrice'
import RelatedProductsContainer from '../products/RelatedProductsContainer'
import ProductInfoTabs from './ProductInfoTabs'
import ProductsSimilarColorsContainer from '../products/ProductsSimilarColorsContainer'
import BannerRockMaster from '../banners/banner-rockmaster/BannerRockMaster'
import LengthMeasureTranslation from '../global/LengthMeasureTranslation'
import LengthMeasure from '../global/LengthMeasure'
import DisplayNaturalStoneOrigin from '../catalog/rectangleResult/displays/DisplayNaturalStoneOrigin'
import BannerEarthic from '../banners/banner-earthic/BannerEarthic'
import BannerSuma from '../banners/banner-suma/BannerSuma'
import BannerSilestoneXM from '../banners/banner-silestone-xm/BannerSilestoneXM'
import QuickGuides from '../global/QuickGuides'
import lotsIcon from '../../assets/img/icons/lots-icon.svg'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayDiscontinued from '../catalog/rectangleResult/displays/DisplayDiscontinued'
import DisplayBlackIndicator from '../catalog/rectangleResult/displays/DisplayBlackIndicator'
import DisplayHomeDepotIndicator from '../catalog/rectangleResult/displays/DisplayHomeDepotIndicator'
import DisplayNewIndicator from '../catalog/rectangleResult/displays/DisplayNewIndicator'
import DisplayUpcomingIndicator from '../catalog/rectangleResult/displays/DisplayUpcomingIndicator'
import NaturalStoneIndicator from '../cart-line/indicators/NaturalStoneIndicator'

const typeIdShowDownlad = ['03', '09', '04']

const ProductDescriptionHeader = ({ product, isMkToolsCenter, naturalStoneViewingPermissions, outlet }) => (
    <React.Fragment>
        <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ columnGap: '16px', rowGap: '2px' }}>
            <div>
                {isMkToolsCenter && product.webHeadingDesc && product.subtypeId !== 'MONOCOLOR BOX' ? (
                    <p className="product-serie">{product.webHeadingDesc.toLowerCase()}</p>
                ) : (
                    <DisplayBrand brand={product.brand} colorSerieDesc={product.colorSerieDesc} />
                )}
            </div>
            <div className="d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
                {product.fromMall && (
                    <div>
                        <img style={{ width: '38px' }} src={mallLarge} alt="logo mall" />
                    </div>
                )}
                {product && product.extra && product.extra.isCosentinoGlobal && !(!!outlet && product.outlet) && naturalStoneViewingPermissions.canViewHQ && (
                    <NaturalStoneIndicator idMessage={'natural_stone_hq'} defaultMessage="Natural stone Spain" />
                )}

                {product && product.extra && product.extra.isCosentinoBrazil && naturalStoneViewingPermissions.canViewBrazil && (
                    <NaturalStoneIndicator idMessage={'natural_stone_brazil'} defaultMessage="Natural stone Brazil" />
                )}

                {product && product.extra && product.extra.isCosentinoGlobal && !!outlet && product.outlet && naturalStoneViewingPermissions.canViewHQ && (
                    <NaturalStoneIndicator idMessage={'outlet_in_hq'} defaultMessage="Outlet Spain" />
                )}
            </div>
        </div>
    </React.Fragment>
)

const ProductFilters = ({ product, alternatives, outlet, productId }) => (
    <div className="product-detail-filter-product">
        {product.thickness && alternatives && (
            <FiltersProduct
                selectHeading={<Translation id="thickness" defaultMessage="Thickness" />}
                alternatives={alternatives.thickness}
                outlet={outlet}
                productId={productId}
                translate="thickness"
                value={product.thickness}
            />
        )}
        {product.format && alternatives && (
            <FiltersProduct
                selectHeading={<Translation id="format" defaultMessage="Format" />}
                alternatives={alternatives.format}
                outlet={outlet}
                productId={productId}
                translate="format"
                value={product.format}
            />
        )}
        {product.finish && alternatives && (
            <FiltersProduct
                selectHeading={<Translation id="finish" defaultMessage="Finish" />}
                alternatives={alternatives.finish}
                outlet={outlet}
                productId={productId}
                translate="finish"
                value={product.finish}
                translateValue
            />
        )}
    </div>
)

const ProductFiltersMktools = ({ product, brandsMktoolsAttr, colorsMktoolsAttr, state, changeBrandOption, changeColorOption, getOptionsColors }) => (
    <div className="product-detail-filter-product">
        {product.haveOptions && brandsMktoolsAttr && (
            <div>
                <span className="select-heading-product">
                    <Translation id="choose_brand" defaultMessage="Choose brand" />
                </span>
                <Select
                    options={['No Brand'].concat(brandsMktoolsAttr).map(brand => ({
                        label: brand.toLowerCase(),
                        value: brand
                    }))}
                    className="select-mktools-attr select-mktools-attr--brand"
                    value={state.selectedBrandMktoolAttr}
                    onChange={selectedOption => changeBrandOption(selectedOption)}
                    clearable={false}
                />
            </div>
        )}
        {product.haveOptions && colorsMktoolsAttr && (
            <div>
                <span className="select-heading-product">
                    <Translation id="choose_color" defaultMessage="Choose color" />
                </span>
                <Select
                    options={getOptionsColors()}
                    className="select-mktools-attr select-mktools-attr--color"
                    value={state.selectedColorMktoolAttr}
                    onChange={selectedOption => changeColorOption(selectedOption)}
                    clearable={false}
                />
            </div>
        )}
    </div>
)

class ProductCardV2 extends React.Component {
    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} />
    }

    hasFlagIndicator = () => {
        return (
            this.props.product.discontinued ||
            (this.props.outlet && this.props.product.outlet && this.props.product.outletQuality) ||
            (this.props.product.fromMall && this.props.product.outletQuality && this.props.product.stock_outlet > 0) ||
            this.props.showNewIndicator ||
            this.props.showUpcomingIndicator ||
            this.props.isExclusiveHomeDepot ||
            (this.props.canViewPricesPhase3 && this.props.state && this.props.state.promotions && this.props.state.promotions.length > 0)
        )
    }

    flagIndicator = (
        <div className="group_tag">
            <div className="group_tag--left-side">
                {this.props.product.discontinued && this.props.product.discontinued !== 'DD' && this.props.product.discontinued !== 'DF' && (
                    <DisplayDiscontinued discontinued={this.props.product.discontinued} />
                )}

                {((this.props.outlet && this.props.product.outlet && this.props.product.outletQuality) ||
                    (this.props.product.fromMall && this.props.product.outletQuality && this.props.product.stock_outlet > 0)) && (
                    <DisplayOutletIndicator outletQuality={this.props.product.outletQuality} />
                )}

                {this.props.showNewIndicator && <DisplayNewIndicator />}
                {this.props.showUpcomingIndicator && <DisplayUpcomingIndicator />}
                {this.props.isExclusiveHomeDepot && <DisplayHomeDepotIndicator />}
            </div>

            {this.props.canViewPricesPhase3 &&
                this.props.state &&
                this.props.state.promotions &&
                this.props.state.promotions.length > 0 &&
                (this.props.state.promotions[0].dealType === 'A' ? (
                    <div className="d-flex">
                        <DisplayBlackIndicator value={this.props.intl.formatMessage({ id: 'new_price' })} />
                    </div>
                ) : this.props.state.promotions[0].dealType === 'B' ? (
                    <div className="d-flex">
                        <DisplayBlackIndicator value={`-${this.props.state.promotions[0].value}%`} />
                    </div>
                ) : null)}
        </div>
    )

    productAdditionalInfo = (
        <div className="productid-info">
            <span>
                <Translation id="material_id" defaultMessage="Material ID" />
            </span>
            : <span>{this.props.productId}</span>
            {this.props.product.zaddon === 'MAL' && (
                <span>
                    {' '}
                    (<Translation id="MAL" defaultMessage="Meshed" />)
                </span>
            )}
        </div>
    )

    render() {
        let { product, isLogged, canBuy, canViewOutlet, isMkToolsCenter, colorsMktoolsAttr, brandsMktoolsAttr, isIkeaCenter } = this.props

        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div style={{ display: 'inline' }}>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }

        const displayStock = (
            <DisplayStock
                value={this.props.getStock()}
                measure={quantity}
                surface={this.getUnitBy()}
                product={product}
                hideSurface={product.fromMall}
                watchingOutlet={this.props.outlet}
                canViewThresholdMax={this.props.canViewThresholdMax}
            />
        )

        const displayLotsButton =
            this.props.showLots() && !this.props.outlet ? (
                <LocalizedLink routeId="ROUTE_LOTS" params={{ id: product.productId, outlet: '' }} gtmLabel="ec_ver_lotes" className="d-inline-block">
                    <button className="buttons-lots">
                        <div>
                            <img src={lotsIcon} alt="Lots" />
                        </div>
                        <div>
                            <Translation id="select_your_lot" defaultMessage="Select your lot" />
                            &nbsp;({this.props.getStock()}&nbsp;
                            <Translation id="lots" defaultMessage="Lots" />)
                        </div>
                    </button>
                </LocalizedLink>
            ) : null

        const displayOutletLotsButton =
            this.props.showLots() && canViewOutlet && this.props.outlet ? (
                <LocalizedLink routeId="ROUTE_LOTS" params={{ id: product.productId, outlet: 'outlet' }} className="d-inline-block">
                    <button className="buttons-lots">
                        <div>
                            <img src={lotsIcon} alt="Lots" />
                        </div>
                        <div>
                            <Translation id="outlet" defaultMessage="Outlet" /> ({this.props.getStock()} <Translation id="lots" defaultMessage="Lots" />)
                        </div>
                    </button>
                </LocalizedLink>
            ) : null

        const productColorsFormulations =
            product.type === 'PPCC' && product.colorFormulations && product.colorFormulations.length > 0 ? (
                <div className="row-others">
                    <p className="finished-title">
                        <Translation id="compatible_with_next_colors" defaultMessage="Compatible with next colors:" />
                    </p>
                    <div className="finished" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                        {product.colorFormulations.map((colorFormulation, index) => {
                            const colorName = colorFormulation.colorName || 'Unknown' // Fallback for null or undefined
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <img alt="product" style={{ height: '40px', width: '60px', border: '1px dashed #ccc' }} src={colorFormulation.thumb} />
                                    <p className="finished-subtitle">
                                        <span style={{ textTransform: 'capitalize', fontSize: '12px' }}>{colorName.toLowerCase()}</span>
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : null

        return (
            <div className="container product-detail product-detail-card" data-type={product.type}>
                <div style={{ marginBottom: '24px' }}>
                    <GoBack text={this.props.intl.formatMessage({ id: 'keep_buying' })} datacy={'product_detail__go_back'} />
                </div>
                <div className="row">
                    <div id="box" className="col-12 col-md-8">
                        {/* Product image */}
                        {product && <ProductCarouselV2 product={product} isLogged={isLogged} />}

                        {/* Btn download pdf */}
                        {isMkToolsCenter && product.download && typeIdShowDownlad.includes(product.typeId) && (
                            <a href={product.download} className="bt-download">
                                <div className="icon">
                                    <img alt="" src={iconDownload} />
                                </div>
                                <div className="label">
                                    <Translation id="download_pdf" defaultMessage="Download pdf" />
                                </div>
                            </a>
                        )}

                        {/* Hybriq patent pending */}
                        {product.technology == 'HYBRIQ' && (
                            <div className="product-image" style={{ minHeight: '0' }}>
                                <div className="fit-image-v2 row no-gutters" style={{ fontSize: '12px', textAlign: 'left' }}>
                                    <div style={{ paddingRight: '10px' }}>
                                        <img
                                            style={{ width: '70px' }}
                                            className="image img-fluid"
                                            src="https://assetsstatic.cosentino.com/img/Patent-Pending-Logo.svg"
                                            alt="Patent"
                                        ></img>
                                    </div>
                                    <div className="col-8 col-md-10 box-patent-pending">
                                        <Translation
                                            id="hybriq_incorporate_patented"
                                            defaultMessage="HybriQ and HybriQ+® incorporate patented or patent-pending technologies."
                                        ></Translation>
                                        <br />
                                        <Translation
                                            id="hybriq_registered_trademarks"
                                            defaultMessage="HybriQ+® and HybriQ Technology® are registered trademarks owned by Cosentino S.A.U."
                                        ></Translation>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Mobile version */}
                        <div className="product-description product-description-phone">
                            {/* Indicators */}
                            {this.hasFlagIndicator() && this.flagIndicator}

                            {/* Description header */}
                            <ProductDescriptionHeader
                                product={product}
                                isMkToolsCenter={isMkToolsCenter}
                                naturalStoneViewingPermissions={this.props.naturalStoneViewingPermissions}
                                outlet={this.props.outlet}
                            />

                            {/* Display product name */}
                            <div className="product-name-content" data-cy="product_detail__title">
                                <ProductDisplayName product={product} />
                            </div>

                            {this.productAdditionalInfo}

                            <DisplayNaturalStoneOrigin product={product} />

                            {product.technology == 'HYBRIQ' && product.brand && product.brand !== 'XERON' && (
                                <div style={{ textAlign: 'initial' }}>
                                    <p style={{ fontSize: '12px' }}>
                                        <Translation
                                            id="hybriq_loft_series"
                                            defaultMessage="The Silestone® LOFT series designs have been registered by Cosentino S.A.U."
                                        ></Translation>
                                        <br />
                                    </p>
                                </div>
                            )}

                            {process.env.REACT_APP_FEATURE_ZAPATILLAS && product.type === 'TABLA' && (
                                <ProductFilters
                                    product={product}
                                    alternatives={this.props.alternatives}
                                    outlet={this.props.outlet}
                                    productId={this.props.productId}
                                />
                            )}

                            <ProductFiltersMktools 
                                product={product}
                                brandsMktoolsAttr={brandsMktoolsAttr}
                                colorsMktoolsAttr={colorsMktoolsAttr}
                                state={this.props.state}
                                changeBrandOption={this.props.changeBrandOption}
                                changeColorOption={this.props.changeColorOption}
                                getOptionsColors={this.props.getOptionsColors}
                            />

                            {productColorsFormulations}

                            {isLogged && (
                                <div className="product-detail-stock">{displayStock}</div>
                            )}

                            {displayLotsButton && isLogged && (
                                <div className="my-3 d-flex items-center justify-content-start align-items-center">{displayLotsButton}</div>
                            )}

                            {product.outlet && displayOutletLotsButton && isLogged && (
                                <div className="my-3 d-flex items-center justify-content-start align-items-center">{product.outlet && displayOutletLotsButton}</div>
                            )}

                            <div className="x-divider my-3"></div>
                            {canBuy && isLogged && <DisplayPrice product={this.props.product} outlet={this.props.outlet} />}

                            {canBuy && (
                                <div className="product-detail-canbuy">
                                    <div className="product-detail-canbuy--input">
                                        <InputNumber
                                            value={this.props.getValueToAdd()}
                                            change={qty => this.props.state.setQtyToAdd(qty)}
                                            step={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 0.01 : this.props.state.step}
                                            limit={this.props.getLimit()}
                                            min={this.props.getMin()}
                                            decimals={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 2 : 0}
                                        />
                                    </div>
                                    <div className="bt-add-to-cart product-detail-canbuy--btn">
                                        <Button
                                            far={true}
                                            inverted
                                            icon="shopping-cart"
                                            disabled={this.props.isAdding || !this.props.canAddCart()}
                                            onClick={() => this.props.addToCart()}
                                            datacy="product_detail__add"
                                            customClass="bt-add-to-cart-product-v2"
                                            dataQas="global_product_detail-add-to-cart-button"
                                        >
                                            <Translation id="add_to_cart" defaultMessage="Add" />
                                        </Button>
                                    </div>

                                    {!this.props.isShowingSegmentationModal && (
                                        <div className="bt-add-to-cart fixed-bottom">
                                            <Button
                                                far={true}
                                                inverted
                                                icon="shopping-cart"
                                                disabled={this.props.isAdding || !this.props.canAddCart()}
                                                onClick={() => this.props.addToCart()}
                                                datacy="product_detail__add"
                                                customClass="bt-add-to-cart-product-v2"
                                                dataQas="global_product_detail-add-to-cart-button"
                                            >
                                                <Translation id="add_to_cart" defaultMessage="Add" />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <QuickGuides subtypeId={this.props.product.subtypeId} lang={this.props.locale} />
                        <RelatedProductsContainer productId={this.props.productId} sectionFlag="FJ" />
                        <ProductInfoTabs />
                        <ProductsSimilarColorsContainer productId={this.props.productId} sectionFlag="FS" />
                    </div>

                    {/* Desktop version */}
                    <div className="col-md-4 product-description product-description-desktop">
                        {/* Indicators */}
                        {this.hasFlagIndicator() && this.flagIndicator}

                        {/* Description header */}
                        <ProductDescriptionHeader
                            product={product}
                            isMkToolsCenter={isMkToolsCenter}
                            naturalStoneViewingPermissions={this.props.naturalStoneViewingPermissions}
                            outlet={this.props.outlet}
                        />

                        {/* Display product name */}
                        <div className="product-name-content" data-cy="product_detail__title">
                            <ProductDisplayName product={product} />
                        </div>

                        {this.productAdditionalInfo}

                        <DisplayNaturalStoneOrigin product={product} />

                        {product.technology == 'HYBRIQ' && product.brand && product.brand !== 'XERON' && (
                            <p style={{ fontSize: '12px' }}>
                                <Translation id="hybriq_loft_series" defaultMessage="The Silestone® LOFT series designs have been registered by Cosentino S.A.U."></Translation>
                                <br />
                            </p>
                        )}

                        {process.env.REACT_APP_FEATURE_ZAPATILLAS && product.type === 'TABLA' && (
                            <ProductFilters
                                product={product}
                                alternatives={this.props.alternatives}
                                outlet={this.props.outlet}
                                productId={this.props.productId}
                            />
                        )}

                        <ProductFiltersMktools 
                            product={product}
                            brandsMktoolsAttr={brandsMktoolsAttr}
                            colorsMktoolsAttr={colorsMktoolsAttr}
                            state={this.props.state}
                            changeBrandOption={this.props.changeBrandOption}
                            changeColorOption={this.props.changeColorOption}
                            getOptionsColors={this.props.getOptionsColors}
                        />

                        {productColorsFormulations}

                        {isLogged && (
                            <div className="product-detail-stock">{displayStock}</div>
                        )}

                        {displayLotsButton && isLogged && (
                            <div className="my-3 d-flex items-center justify-content-start align-items-center">
                                {displayLotsButton}
                            </div>
                        )}

                        {product.outlet && displayOutletLotsButton && isLogged && (
                            <div className="my-3 d-flex items-center justify-content-start align-items-center">{product.outlet && displayOutletLotsButton}</div>
                        )}

                        <div className="x-divider my-3"></div>

                        {canBuy && isLogged && <DisplayPrice product={this.props.product} outlet={this.props.outlet} />}

                        {canBuy && (
                            <div className="product-detail-canbuy">
                                <div className="product-detail-canbuy--input">
                                    <InputNumber
                                        value={this.props.getValueToAdd()}
                                        change={qty => this.props.state.setQtyToAdd(qty)}
                                        step={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 0.01 : this.props.state.step}
                                        limit={this.props.getLimit()}
                                        min={this.props.getMin()}
                                        decimals={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 2 : 0}
                                    />
                                </div>
                                <div className="bt-add-to-cart product-detail-canbuy--btn">
                                    <Button
                                        far={true}
                                        inverted
                                        icon="shopping-cart"
                                        disabled={this.props.isAdding || !this.props.canAddCart()}
                                        onClick={() => this.props.addToCart()}
                                        datacy="product_detail__add"
                                        customClass="bt-add-to-cart-product-v2"
                                        dataQas="global_product_detail-add-to-cart-button"
                                    >
                                        <Translation id="add_to_cart" defaultMessage="Add" />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {/* {this.props.canViewEarthicPromotion && <BannerEarthic />} */}
                        {this.props.canViewSumaPromotion && <BannerSuma />}
                        {this.props.canViewSilestonePromotionXM && <BannerSilestoneXM />}
                        {this.props.canViewRockMasterPromotion && <BannerRockMaster />}
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(injectIntl(ProductCardV2))
