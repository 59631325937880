import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import { showingSegmentationModal } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import SegmentationContainer from './SegmentationContainer'
import { getSegmentationCurrentStep } from '../../store/segmentation/reducers'
import {
    SAVED_SEGMENTATIONS_STEP, SAVING_SEGMENTATION_STEP,
    SEGMENTATION_EVIDENCE_STEP, SEGMENTATION_SUCCESSFULLY_SAVED,
    SEGMENTATION_V3_STEP,
    SUGGESTED_SEGMENTATIONS_STEP
} from "./SegmentationSteps";

const mapStateToProps = state => {
    return {
        show: showingSegmentationModal(state),
        segmentationCurrentStep: getSegmentationCurrentStep(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeSegmentationModal())
        }
    }
}

class SegmentationModal extends React.Component {
    render() {
        const { show, onClose, segmentationCurrentStep } = this.props

        let modalLabel
        switch (segmentationCurrentStep) {
            case SAVED_SEGMENTATIONS_STEP:
                modalLabel =  <Translation id="choose_saved_segmentation" defaultMessage="Choose saved segmentation" />
                break
            case SAVING_SEGMENTATION_STEP:
                modalLabel =  <Translation id="saving_segmentation" defaultMessage="Saving segmentation" />
                break
            case SEGMENTATION_EVIDENCE_STEP:
                modalLabel = <Translation id="attach_evidence" defaultMessage="Attach evidence" />
                break
            case SEGMENTATION_SUCCESSFULLY_SAVED:
                modalLabel = <Translation id="segmentation_successfully_saved" defaultMessage="Segmentation successfully saved" />
                break
            case SEGMENTATION_V3_STEP:
                modalLabel = <Translation id="choose_segmentation_manually" defaultMessage="Choose segmentation manually" />
                break
            case SUGGESTED_SEGMENTATIONS_STEP:
            default:
                modalLabel = <Translation id="choose_segmentation" defaultMessage="Choose segmentation" />
        }

        if (!show) return (
            <React.Fragment/>
        )

        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                {show &&
                    <div id="modal-segmentation-container" className="modal-segmentation container segmentation">
                        <header>
                            <div className="d-flex align-items-center justify-content-between pb-3">
                                <div>
                                    <h4 className="m-0">
                                        {modalLabel}
                                    </h4>
                                </div>
                                <div>
                                    <div className="bt-close" onClick={onClose}>
                                        <i className="fal fa-times" />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <SegmentationContainer modal />
                    </div>
                }
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SegmentationModal)
)
