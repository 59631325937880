import React, {useEffect, useState} from 'react'
import Button from '../global/Button'
import Translation from '../global/Translation'
import {defineMessages, injectIntl} from "react-intl";
import {SAVED_SEGMENTATIONS_STEP, SEGMENTATION_EVIDENCE_STEP, SEGMENTATION_V3_STEP} from "./SegmentationSteps";
import {RadioButton} from "@progress/kendo-react-inputs";

defineMessages({
    here_some_suggested_segmentations: {
        id: 'here_some_suggested_segmentations',
        description: 'Here are some suggested segmentations containing the material you have selected:',
        defaultMessage: 'Here are some suggested segmentations containing the material you have selected:'
    },
    none_above_segmentations: {
        id: 'none_above_segmentations',
        description: 'None of the above segmentations?',
        defaultMessage: 'None of the above segmentations?'
    },
    choose_segmentation_manually: {
        id: 'choose_segmentation_manually',
        description: 'Choose segmentation manually',
        defaultMessage: 'Choose segmentation manually'
    },
    view_saved_segmentations: {
        id: 'view_saved_segmentations',
        description: 'View saved segmentations',
        defaultMessage: 'View saved segmentations'
    },
})


const SuggestedSegmentation = props => {
    const { onSubmitSegmentation, callback, onSetStep } = props

    const [selectedIndex, setSelectedIndex] = useState()

    const handleAddToCart = (needsEvidence) => {
        const selectedSegmentation = props.suggestedSegmentations[selectedIndex]
        props.setSegmentationFromSuggested(selectedSegmentation)
        if (needsEvidence) onSetStep(SEGMENTATION_EVIDENCE_STEP)
        else onSubmitSegmentation(callback)
    }

    const needsEvidence = () => selectedIndex !== undefined
        && props.suggestedSegmentations[selectedIndex].subchannelProgram
        && (props.suggestedSegmentations[selectedIndex].evidences === '01' || props.suggestedSegmentations[selectedIndex].evidences === '02')

    // const needsEvidence = () => selectedIndex === 3


    return (
        <div className="suggested__segmentation">
            <div className="suggested__segmentation__content--input" >
                <div>
                    <span className="title">
                        <Translation
                            id="here_some_suggested_segmentations"
                            defaultMessage="Here are some suggested segmentations containing the material you have selected:"
                        />
                    </span>
                </div>
                <div className="scrolled-list">
                    {
                        props.suggestedSegmentations.map((value, index) => {
                            // const label = props.intl.formatMessage({ id: 'project', defaultMessage: 'Project'}) + ': ' +
                            const key = `suggested-segmentation-${index}`

                            return <div className="my-3 scrolled-list--item"
                                        key={key}
                            >
                                <RadioButton
                                    onClick={() => setSelectedIndex(index)}
                                    id={key}
                                    name={key}
                                    value={index}
                                    checked={selectedIndex === index}
                                >
                                    <label htmlFor={key} className={'k-radio-label'} >
                                        <Translation id="project" defaultMessage="Project"/>:&nbsp;
                                        <strong>{value.projectName}</strong>
                                    </label>
                                </RadioButton>
                            </div>

                        })
                    }
                </div>
                <div>
                    <span className="title">
                        <Translation
                            id="none_above_segmentations"
                            defaultMessage="None of the above segmentations?"
                        />
                    </span>
                </div>

                <div className="double-buttons my-1">
                    <Button onClick={() => onSetStep(SAVED_SEGMENTATIONS_STEP)} >
                        <Translation id="view_saved_segmentations" defaultMessage="View saved segmentations" />
                    </Button>

                    <Button onClick={() => onSetStep(SEGMENTATION_V3_STEP)} >
                        <Translation id="choose_segmentation_manually" defaultMessage="Choose segmentation manually" />
                    </Button>
                </div>

            </div>

            <div>
                <hr></hr>
                <footer>
                    {
                        needsEvidence() ?
                            <Button
                                onClick={() => handleAddToCart(true)}
                                inverted
                                // dataQas="global_segmentation-add-to-cart-button"
                                icon="fal fa-chevron-right"
                                iconEnd={true}
                            >
                                <Translation id="next" defaultMessage="Next" />
                            </Button>
                            :
                            <Button
                                onClick={() => handleAddToCart(false)}
                                inverted
                                // dataQas="global_segmentation-add-to-cart-button"
                                icon="fa fa-shopping-cart"
                                iconEnd={true}
                                disabled={selectedIndex === undefined}
                            >
                                <Translation id="add_to_cart" defaultMessage="Add to cart" />
                            </Button>
                    }
                </footer>
            </div>


        </div>
    )
}

export default SuggestedSegmentation
