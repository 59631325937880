import React from 'react'

import { Link  } from 'react-router-dom'
import { injectIntl, defineMessages } from 'react-intl'
import { connect } from 'react-redux'

import { getCurrentUrl } from '../../store/ui/reducers'
import { withRouter } from 'react-router-dom'


const mapStateToProps = (state ,{match}) => {
    return { 
        currentUrl: getCurrentUrl(state),
    }
}

const LocalizedLink = ({ currentUrl, intl, routeId, params = {}, children, className, queryString = '', target, onClick, style, gtmLabel, dataQas}) => {

    // Construye la URL base con el prefijo de idioma si no es 'en-US'
    const prefix = intl.locale !== 'en-US' ? `/${intl.locale}` : ''

    const route = prefix + Object.keys(params).reduce((previous, value) => {
        const optional = previous.replace(`:${value}?`, params[value])
        return optional.replace(`:${value}`, params[value])
    }, intl.formatMessage({ id: routeId }))

    // Limpia la URL base y añade el queryString (si existe)
    // let url = `${route}${queryString}`.replace('//', '/').replace(/\/+$/, '')
    let url = `${route}${queryString}`.replace(/\/\/+/g, '/').replace(/\/$/, '');

    // Calcula `active` basándose en la coincidencia exacta de pathname y queryString
    const active = url === `${currentUrl.pathname}${currentUrl.search}`

    return (
        <React.Fragment>
            {
                <Link to={url} isActive={false} className={`${className} ${active ? ' active' : ''}`} target={target} onClick={onClick} style={style} data-qas={dataQas} gtm-label={gtmLabel}>
                    {children}
                </Link>
            }
        </React.Fragment>
    )
}
defineMessages({
    ABU: {
        id: 'ABU',
        description: 'ABU',
        defaultMessage: 'BUSH'
    },
    AN1: {
        id: 'AN1',
        description: 'AN1',
        defaultMessage: 'ANTICO'
    },
    AN2: {
        id: 'AN2',
        description: 'AN2',
        defaultMessage: 'ANTIQUE'
    },
    ANC: {
        id: 'ANC',
        description: 'ANC',
        defaultMessage: 'ANCIENT'
    },
    AND: {
        id: 'AND',
        description: 'AND',
        defaultMessage: 'GRIP +'
    },
    ANT: {
        id: 'ANT',
        description: 'ANT',
        defaultMessage: 'ANTICATO'
    },
    APO: {
        id: 'APO',
        description: 'APO',
        defaultMessage: 'HONED'
    },
    AW: {
        id: 'AW',
        description: 'AW',
        defaultMessage: 'AW'
    },
    BOM: {
        id: 'BOM',
        description: 'BOM',
        defaultMessage: 'ENV A BOMBO'
    },
    BRU: {
        id: 'BRU',
        description: 'BRU',
        defaultMessage: 'ROUGH'
    },
    BU: {
        id: 'BU',
        description: 'BU',
        defaultMessage: 'Builder'
    },
    CAR: {
        id: 'CAR',
        description: 'CAR',
        defaultMessage: 'CARESSE'
    },
    CAS: {
        id: 'CAS',
        description: 'CAS',
        defaultMessage: 'SENSA CARESSE'
    },
    CEM: {
        id: 'CEM',
        description: 'CEM',
        defaultMessage: 'CONCRETE'
    },
    CEP: {
        id: 'CEP',
        description: 'CEP',
        defaultMessage: 'BRUSHED'
    },
    CMB: {
        id: 'CMB',
        description: 'CMB',
        defaultMessage: 'POLISHED - SUEDE'
    },
    CO: {
        id: 'CO',
        description: 'CO',
        defaultMessage: 'Commercial'
    },
    CODIGO: {
        id: 'CODIGO',
        description: 'CODIGO',
        defaultMessage: 'ES'
    },
    CONTAINER: {
        id: 'CONTAINER',
        description: 'CONTAINER',
        defaultMessage: 'Container'
    },
    ENDI_CONTAINER: {
        id: 'ENDI_CONTAINER',
        description: 'ENDI_CONTAINER',
        defaultMessage: 'Container'
    },
    CPV: {
        id: 'CPV',
        description: 'CPV',
        defaultMessage: 'POLISHED - VOLCANO'
    },
    DIRECT_TRUCK: {
        id: 'DIRECT_TRUCK',
        description: 'DIRECT_TRUCK',
        defaultMessage: 'Direct truck'
    },
    SEND_PROMOTION_HEADER_SUCCESS: {
        id: 'SEND_PROMOTION_HEADER_SUCCESS',
        description: 'SEND_PROMOTION_HEADER_SUCCESS',
        defaultMessage: 'General info saved successfully'
    },
    SEND_PROMOTION_CENTERS_SUCCESS: {
        id: 'SEND_PROMOTION_CENTERS_SUCCESS',
        description: 'SEND_PROMOTION_CENTERS_SUCCESS',
        defaultMessage: 'Market target saved successfully'
    },
    SEND_CUSTOMERS_SELECTED_SUCCESS: {
        id: 'SEND_CUSTOMERS_SELECTED_SUCCESS',
        description: 'SEND_CUSTOMERS_SELECTED_SUCCESS',
        defaultMessage: 'Customers saved successfully'
    },
    SEND_MATERIALS_SELECTED_SUCCESS: {
        id: 'SEND_MATERIALS_SELECTED_SUCCESS',
        description: 'SEND_MATERIALS_SELECTED_SUCCESS',
        defaultMessage: 'Materials saved successfully'
    },
    SEND_TEMPLATES_SELECTED_SUCCESS: {
        id: 'SEND_TEMPLATES_SELECTED_SUCCESS',
        description: 'SEND_TEMPLATES_SELECTED_SUCCESS',
        defaultMessage: 'Templates saved successfully'
    },
    SEND_BANNERS_SUCCESS: {
        id: 'SEND_BANNERS_SUCCESS',
        description: 'SEND_BANNERS_SUCCESS',
        defaultMessage: 'Banners saved successfully'
    },
    REQUESTED_CAMPAIGN_DISCOUNT: {
        id: 'REQUESTED_CAMPAIGN_DISCOUNT',
        description: 'REQUESTED_CAMPAIGN_DISCOUNT',
        defaultMessage:
            'The selected discounts have been requested to Pricing. \n' +
            'When the discounts have been approved you will be able to see them in the campaign. \n' +
            'You can continue creating the campaign'
    },
    NOT_REQUESTED_CAMPAIGN_DISCOUNT: {
        id: 'NOT_REQUESTED_CAMPAIGN_DISCOUNT',
        description: 'NOT_REQUESTED_CAMPAIGN_DISCOUNT',
        defaultMessage: 'There are no modifications. The discount has not been requested.'
    },
    ENDI_TRUCK: {
        id: 'ENDI_TRUCK',
        description: 'ENDI_TRUCK',
        defaultMessage: 'Truck'
    },
    DOA: {
        id: 'DOA',
        description: 'DOA',
        defaultMessage: 'TWO FACES HONNED'
    },
    DOL: {
        id: 'DOL',
        description: 'DOL',
        defaultMessage: 'TWO FACES LEATHER'
    },
    DOS: {
        id: 'DOS',
        description: 'DOS',
        defaultMessage: 'TWO FACES POLISHED'
    },
    DOV: {
        id: 'DOV',
        description: 'DOV',
        defaultMessage: 'TWO FACES VOLCANO'
    },
    DSL: {
        id: 'DSL',
        description: 'DSL',
        defaultMessage: 'SUEDE TWO FACES'
    },
    ENA: {
        id: 'ENA',
        description: 'ENA',
        defaultMessage: 'CENTURY - GLASE'
    },
    ENV: {
        id: 'ENV',
        description: 'ENV',
        defaultMessage: 'CENTURY'
    },
    ESC: {
        id: 'ESC',
        description: 'ESC',
        defaultMessage: 'SCARIFIED'
    },
    FH: {
        id: 'FH',
        description: 'FH',
        defaultMessage: 'Franchises'
    },
    FLA: {
        id: 'FLA',
        description: 'FLA',
        defaultMessage: 'FLAMED'
    },
    FLC: {
        id: 'FLC',
        description: 'FLC',
        defaultMessage: 'FLAMEADO - CENTURY'
    },
    DOE: {
        id: 'DOE',
        description: 'DOE',
        defaultMessage: 'DOE'
    },
    ROU: {
        id: 'ROU',
        description: 'RAW',
        defaultMessage: 'RAW'
    },
    FULL_TRUCK: {
        id: 'FULL_TRUCK',
        description: 'FULL_TRUCK',
        defaultMessage: 'Full truck'
    },
    GC2: {
        id: 'GC2',
        description: 'GC2',
        defaultMessage: 'GRIP'
    },
    HD: {
        id: 'HD',
        description: 'HD',
        defaultMessage: 'Home Depot'
    },
    HI: {
        id: 'HI',
        description: 'HI',
        defaultMessage: 'Home Depot Interiors'
    },
    HP: {
        id: 'HP',
        description: 'HP',
        defaultMessage: 'HP'
    },
    IN: {
        id: 'IN',
        description: 'IN',
        defaultMessage: 'Inventory'
    },
    KB: {
        id: 'KB',
        description: 'KB',
        defaultMessage: 'Kitchen & Bath'
    },
    LEA: {
        id: 'LEA',
        description: 'LEA',
        defaultMessage: 'Leather'
    },
    LES: {
        id: 'LES',
        description: 'LES',
        defaultMessage: 'Sensa Leather'
    },
    LW: {
        id: 'LW',
        description: 'LW',
        defaultMessage: 'Lowes'
    },
    M1: {
        id: 'M1',
        description: 'M1',
        defaultMessage: 'Extra'
    },
    M2: {
        id: 'M2',
        description: 'M2',
        defaultMessage: 'Select'
    },
    M3: {
        id: 'M3',
        description: 'M3',
        defaultMessage: 'Standard'
    },
    MAL: {
        id: 'MAL',
        description: 'MAL',
        defaultMessage: 'Meshed'
    },
    MAT: {
        id: 'MAT',
        description: 'MAT',
        defaultMessage: 'Matte'
    },
    MIR: {
        id: 'MIR',
        description: 'MIR',
        defaultMessage: 'Sensa Mirage'
    },
    NA: {
        id: 'NA',
        description: 'NA',
        defaultMessage: 'NA'
    },
    NAC: {
        id: 'NAC',
        description: 'NAC',
        defaultMessage: 'NATURAL CALIBRATED'
    },
    NAT: {
        id: 'NAT',
        description: 'NATURAL',
        defaultMessage: 'NATURAL'
    },
    NOT: {
        id: 'NOT',
        description: 'NOT',
        defaultMessage: 'No apply'
    },
    OCE: {
        id: 'OCE',
        description: 'OCE',
        defaultMessage: 'OCEAN'
    },
    PI: {
        id: 'PI',
        description: 'PI',
        defaultMessage: 'MAXIM'
    },
    LIN: {
        id: 'LIN',
        description: 'LINEARITY',
        defaultMessage: 'LINEARITY'
    },
    QUA: {
        id: 'QUA',
        description: 'QUARRY',
        defaultMessage: 'QUARRY'
    },
    PR: {
        id: 'PR',
        description: 'PR',
        defaultMessage: 'PR'
    },
    PS: {
        id: 'PS',
        description: 'PS',
        defaultMessage: 'Ps'
    },
    PUA: {
        id: 'PUA',
        description: 'PUA',
        defaultMessage: 'Mirage - Glase'
    },
    PUC: {
        id: 'PUC',
        description: 'PUC',
        defaultMessage: 'Mirage - Century'
    },
    PUL: {
        id: 'PUL',
        description: 'PUL',
        defaultMessage: 'Polished'
    },
    PULMAL: {
        id: 'PULMAL',
        description: 'PULMAL',
        defaultMessage: 'Polished-Meshed'
    },
    PUN: {
        id: 'PUN',
        description: 'PUN',
        defaultMessage: 'Polished N-Boost'
    },
    RD: {
        id: 'RD',
        description: 'RD',
        defaultMessage: 'RD'
    },
    DA: {
        id: 'DA',
        description: 'DA',
        defaultMessage: 'Direct Accounts'
    },
    HE: {
        id: 'HE',
        description: 'HE',
        defaultMessage: 'High end'
    },
    RE: {
        id: 'RE',
        description: 'RE',
        defaultMessage: 'Residential'
    },
    RUG: {
        id: 'RUG',
        description: 'RUG',
        defaultMessage: 'ROUGH'
    },
    SAT: {
        id: 'SAT',
        description: 'SAT',
        defaultMessage: 'SATIN'
    },
    SIN: {
        id: 'SIN',
        description: 'SIN',
        defaultMessage: 'NO FINISH'
    },
    SINGLE_SLAB: {
        id: 'SINGLE_SLAB',
        description: 'SINGLE_SLAB',
        defaultMessage: 'Single slab'
    },
    SLE: {
        id: 'SLE',
        description: 'SLE',
        defaultMessage: 'SUEDE LEATHER'
    },
    SNB: {
        id: 'SNB',
        description: 'SNB',
        defaultMessage: 'SUEDE N - BOOST'
    },
    SR: {
        id: 'SR',
        description: 'SR',
        defaultMessage: 'Sears'
    },
    SUE: {
        id: 'SUE',
        description: 'SUE',
        defaultMessage: 'SUEDE'
    },
    TEX: {
        id: 'TEX',
        description: 'TEX',
        defaultMessage: 'TEXTURIZED'
    },
    TOC: {
        id: 'TOC',
        description: 'TOC',
        defaultMessage: 'BILLET'
    },
    VEL: {
        id: 'VEL',
        description: 'VEL',
        defaultMessage: 'VELVET'
    },
    VOL: {
        id: 'VOL',
        description: 'VOL',
        defaultMessage: 'VOLCANO'
    },
    ZM: {
        id: 'ZM',
        description: 'ZM',
        defaultMessage: 'ZM'
    },
    EMP: {
        id: 'EMP',
        description: 'EMPASTADO',
        defaultMessage: 'EMPASTADO'
    },
    SOF: {
        id: 'SOF',
        description: 'SOFTGRAIN',
        defaultMessage: 'SOFTGRAIN'
    },
    GRV: {
        id: 'GRV',
        descreiption: 'GRV',
        defaultMessage: 'High roughness'
    },
    MTT: {
        id: 'MTT',
        descreiption: 'MTT',
        defaultMessage: 'Texturized mate'
    },
    CUSTOMER_REQUIRED: {
        id: 'category',
        description: 'category',
        defaultMessage: 'Category'
    },
    SELECT: {
        id: 'select',
        description: 'Select',
        defaultMessage: 'Select'
    },
    ABRASIVO: {
        id: 'ABRASIVE',
        description: 'ABRASIVO',
        defaultMessage: 'ABRASIVO'
    },
    COLORDEK: {
        id: 'COLORDEK',
        description: 'COLORDEK',
        defaultMessage: 'COLORDEK'
    },
    DIAMANTADO: {
        id: 'DIAMANTADO',
        description: 'DIAMANTADO',
        defaultMessage: 'DIAMANTADO'
    },
    HERRAMIENTA: {
        id: 'HERRAMIENTA',
        description: 'HERRAMIENTA',
        defaultMessage: 'HERRAMIENTA'
    },
    JUNTAX: {
        id: 'JUNTAX',
        description: 'JUNTAX',
        defaultMessage: 'JUNTAX'
    },

    MANTENIMIENTO: {
        id: 'MANTENIMIENTO',
        description: 'MANTENIMIENTO',
        defaultMessage: 'MANTENIMIENTO'
    },
    MASTIDEK: {
        id: 'MASTIDEK',
        description: 'MASTIDEK',
        defaultMessage: 'MASTIDEK'
    },
    SOLUMASTIK: {
        id: 'SOLUMASTIK',
        description: 'SOLUMASTIK',
        defaultMessage: 'SOLUMASTIK'
    },
    'SPARE PARTS': {
        id: 'SPARE PARTS',
        description: 'SPARE PARTS',
        defaultMessage: 'SPARE PARTS'
    },

    a_center_near_to_you: {
        id: 'a_center_near_to_you',
        description: 'a_center_near_to_you',
        defaultMessage: 'Your nearest center'
    },
    add_favourite: {
        id: 'add_favourite',
        description: 'add_favourite',
        defaultMessage: 'Add favourite'
    },
    and_get_discount: {
        id: 'and_get_discount',
        description: 'and_get_discount',
        defaultMessage: 'and get the discount for full truck shipping'
    },
    apps: {
        id: 'apps',
        description: 'apps',
        defaultMessage: 'Apps'
    },

    bills_issued: {
        id: 'bills_issued',
        description: 'bills_issued',
        defaultMessage: 'Bills issued'
    },
    brands: {
        id: 'brands',
        description: 'brands',
        defaultMessage: 'Brands'
    },
    builders: {
        id: 'builders',
        description: 'builders',
        defaultMessage: 'Builders'
    },
    buybundle: {
        id: 'buybundle',
        description: 'buybundle',
        defaultMessage: 'Buy bundle'
    },
    buylot: {
        id: 'buylot',
        description: 'buylot',
        defaultMessage: 'Buy lot'
    },

    center_preferred: {
        id: 'center_preferred',
        description: 'center_preferred',
        defaultMessage: 'Center Preferred'
    },
    change_password_disabled: {
        id: 'change_password_disabled',
        description: 'change_password_disabled',
        defaultMessage: 'Change password is temporally disabled, to ask for a new password, please contact us at ecommerce@cosentino.com'
    },
    clear: {
        id: 'clear',
        description: 'clear',
        defaultMessage: 'Clear'
    },
    click_here: {
        id: 'click_here',
        description: 'click_here',
        defaultMessage: 'click here'
    },
    color: {
        id: 'color',
        description: 'color',
        defaultMessage: 'Color'
    },
    colorName: {
        id: 'colorName',
        description: 'colorName',
        defaultMessage: 'Color'
    },
    finish: {
        id: 'finish',
        description: 'finish',
        defaultMessage: 'Finish'
    },
    image: {
        id: 'image',
        description: 'image',
        defaultMessage: 'Image'
    },
    stock: {
        id: 'stock',
        description: 'stock',
        defaultMessage: 'Stock'
    },
    stock_outlet: {
        id: 'stock_outlet',
        description: 'stock_outlet',
        defaultMessage: 'Stock outlet'
    },
    stock_ft2: {
        id: 'stock_ft2',
        description: 'stock_ft2',
        defaultMessage: 'ft2'
    },
    stock_m2: {
        id: 'stock_m2',
        description: 'stock_m2',
        defaultMessage: 'm2'
    },
    stock_outlet_ft2: {
        id: 'stock_outlet_ft2',
        description: 'stock_outlet_ft2',
        defaultMessage: 'Stock ft2 outlet'
    },
    stock_outlet_m2: {
        id: 'stock_outlet_m2',
        description: 'stock_outlet_m2',
        defaultMessage: 'Stock m2 outlet'
    },
    subtypeName: {
        id: 'subtypeName',
        description: 'subtypeName',
        defaultMessage: 'Subtype'
    },
    outletQuality: {
        id: 'outletQuality',
        description: 'outletQuality',
        defaultMessage: 'Outlet quality'
    },
    finishFamily: {
        id: 'finishFamily',
        description: 'finishFamily',
        defaultMessage: 'Finish Family'
    },
    commercial_project: {
        id: 'commercial_project',
        description: 'commercial_project',
        defaultMessage: 'Commercial / Project'
    },
    conduct_code: {
        id: 'conduct_code',
        description: 'conduct_code',
        defaultMessage: 'Conduct code'
    },
    contact: {
        id: 'contact',
        description: 'contact',
        defaultMessage: 'Contact us'
    },
    cookies_policy: {
        id: 'cookies_policy',
        description: 'cookies_policy',
        defaultMessage: 'Cookies policy'
    },
    cosentino_center: {
        id: 'cosentino_center',
        description: 'cosentino_center',
        defaultMessage: 'Cosentino center'
    },
    customer: {
        id: 'customer',
        description: 'customer',
        defaultMessage: 'Customer'
    },
    customer_hub: {
        id: 'customer_hub',
        description: 'customer_hub',
        defaultMessage: 'Customer Hub'
    },
    cosentino_hub: {
        id: 'cosentino_hub',
        description: 'cosentino_hub',
        defaultMessage: 'Cosentino HUB'
    },
    direct_sale: {
        id: 'direct_sale',
        description: 'direct_sale',
        defaultMessage: 'Direct sale'
    },
    dekton: {
        id: 'dekton',
        description: 'dekton',
        defaultMessage: 'Dekton'
    },
    dekton_message: {
        id: 'dekton_message',
        description: 'dekton_message',
        defaultMessage: 'Take training courses about treatments for Dekton.'
    },
    delete: {
        id: 'delete',
        description: 'delete',
        defaultMessage: 'Remove'
    },
    deliveries_in_progress: {
        id: 'deliveries_in_progress',
        description: 'deliveries_in_progress',
        defaultMessage: 'Deliveries in progress'
    },
    deviated: {
        id: 'deviated',
        description: 'deviated',
        defaultMessage: 'Deviated'
    },
    download_texture: {
        id: 'download_texture',
        description: 'download_texture',
        defaultMessage: 'Download Texture'
    },
    erróneo: {
        id: 'erróneo',
        description: 'erróneo',
        defaultMessage: 'Wrong'
    },
    facebook: {
        id: 'facebook',
        description: 'facebook',
        defaultMessage: 'Facebook'
    },

    faqs: {
        id: 'faqs',
        description: 'faqs',
        defaultMessage: 'FAQs'
    },
    filter_discounts: {
        id: 'filter_discounts',
        description: 'filter_discounts',
        defaultMessage: 'Discounts'
    },
    favourites: {
        id: 'favourites',
        description: 'favourites',
        defaultMessage: 'Favourites'
    },
    for_full_truck: {
        id: 'for_full_truck',
        description: 'for_full_truck',
        defaultMessage: 'for full truck shipping'
    },
    google: {
        id: 'google',
        description: 'google',
        defaultMessage: 'Google'
    },
    home: {
        id: 'home',
        description: 'home',
        defaultMessage: 'Home'
    },

    hybriq_loft_series: {
        id: 'hybriq_loft_series',
        description: 'hybriq_loft_series',
        defaultMessage: 'The Silestone® LOFT series designs have been registered by Cosentino S.A.U."'
    },
    hybriq_incorporate_patented: {
        id: 'hybriq_incorporate_patented',
        description: 'hybriq_incorporate_patented',
        defaultMessage: 'HybriQ and HybriQ+® incorporate patented or patent-pending technologies.'
    },
    hybriq_registered_trademarks: {
        id: 'hybriq_registered_trademarks',
        description: 'hybriq_registered_trademarks',
        defaultMessage: 'HybriQ+® and HybriQ Technology® are registered trademarks owned by Cosentino S.A.U.'
    },
    professional_products: {
        id: 'home.slider.professional_products',
        description: 'home.slider.professional_products',
        defaultMessage: 'Professional products'
    },
    home_design: {
        id: 'home_design',
        description: 'home_design',
        defaultMessage: 'Home design'
    },
    im_looking: {
        id: 'im_looking',
        description: 'im_looking',
        defaultMessage: 'Im looking for...'
    },
    im_looking_for: {
        id: 'im_looking_for',
        description: 'im_looking_for',
        defaultMessage: 'I´m looking for...'
    },
    invoice_id: {
        id: 'invoice_id',
        description: 'invoice_id',
        defaultMessage: 'Invoice ID'
    },
    key_accounts: {
        id: 'key_accounts',
        description: 'key_accounts',
        defaultMessage: 'Key Accounts'
    },
    landCode: {
        id: 'landCode',
        description: 'landCode',
        defaultMessage: 'us'
    },
    last_colors: {
        id: 'last_colors',
        description: 'last_colors',
        defaultMessage: 'Latest colors'
    },
    legal_notice: {
        id: 'legal_notice',
        description: 'legal_notice',
        defaultMessage: 'Legal notice'
    },
    linkedin: {
        id: 'linkedin',
        description: 'linkedin',
        defaultMessage: 'Linkedin'
    },
    login_with: {
        id: 'login_with',
        description: 'login_with',
        defaultMessage: 'Login with'
    },
    login_with_Salesforce: {
        id: 'login_with_Salesforce',
        description: 'login_with_Salesforce',
        defaultMessage: 'Login with Salesforce'
    },
    login_with_your_email: {
        id: 'login_with_your_email',
        description: 'login_with_your_email',
        defaultMessage: 'Log in with your email'
    },
    login_with_another_account: {
        id: 'login_with_another_account',
        description: 'login_with_another_account',
        defaultMessage: 'Log in with another account'
    },

    login_with_your_id: {
        id: 'login_with_your_id',
        description: 'login_with_your_id',
        defaultMessage: 'Log in with your ID'
    },
    loyalty_message: {
        id: 'loyalty_message',
        description: 'loyalty_message',
        defaultMessage: 'Cosentino Loyalty is a programme aimed at stone masons, rewarding their loyalty to Cosentino by offering them exclusive benefits.'
    },
    min_to_checkout: {
        id: 'min_to_checkout',
        description: 'min_to_checkout',
        defaultMessage: 'to be able to check out'
    },
    mktools: {
        id: 'mktools',
        description: 'mktools',
        defaultMessage: 'Mktools'
    },
    my_personal_area: {
        id: 'my_personal_area',
        description: 'my_personal_area',
        defaultMessage: 'My personal area'
    },
    national_accounts: {
        id: 'national_accounts',
        description: 'national_accounts',
        defaultMessage: 'National Accounts'
    },
    online_visualizer: {
        id: 'online_visualizer',
        description: 'online_visualizer',
        defaultMessage: 'Online visualizer'
    },
    only_show_favourites: {
        id: 'only_show_favourites',
        description: 'only_show_favourites',
        defaultMessage: 'Only my favourite products'
    },
    only_show_discounts: {
        id: 'only_show_discounts',
        description: 'only_show_discounts',
        defaultMessage: 'Only promotions'
    },
    orders: {
        id: 'orders',
        description: 'orders',
        defaultMessage: 'Orders'
    },
    outbound_deliveries: {
        id: 'outbound_deliveries',
        description: 'outbound_deliveries',
        defaultMessage: 'Outbound Deliveries'
    },
    operation_type_worktop: {
        id: 'operation_type_worktop',
        description: 'operation_type_worktop',
        defaultMessage: 'Operation for worktop'
    },
    operation_type_edge: {
        id: 'operation_type_edge',
        description: 'operation_type_edge',
        defaultMessage: 'Operation for edge'
    },
    operation_type_hole: {
        id: 'operation_type_hole',
        description: 'operation_type_hole',
        defaultMessage: 'Operation hole'
    },

    outlet_o: {
        id: 'outlet_o',
        description: 'outlet_o',
        defaultMessage: 'Outlet Basic'
    },

    outlet_x: {
        id: 'outlet_x',
        description: 'outlet_x',
        defaultMessage: 'Outlet Xtreme'
    },
    outlet_v: {
        id: 'outlet_v',
        description: 'outlet_v',
        defaultMessage: 'Outlet Premium'
    },
    outlet_stock_available: {
        id: 'outlet_stock_available',
        description: 'outlet_stock_available',
        defaultMessage: 'Outlet stock available'
    },

    other_deliveries_pending: {
        id: 'other_deliveries_pending',
        description: 'other_deliveries_pending',
        defaultMessage: 'Other deliveries pending'
    },
    partial_import: {
        id: 'partial_import',
        description: 'partial_import',
        defaultMessage: 'Partial import'
    },

    pool: {
        id: 'pool',
        description: 'pool',
        defaultMessage: 'Pool'
    },
    pounds: {
        id: 'pounds',
        description: 'pounds',
        defaultMessage: 'Pounds'
    },

    reach_to: {
        id: 'reach_to',
        description: 'reach_to',
        defaultMessage: 'Reach to'
    },
    repeat_password: {
        id: 'repeat_password',
        description: 'repeat_password',
        defaultMessage: 'Repeat password'
    },
    read_more: {
        id: 'read_more',
        description: 'read_more',
        defaultMessage: 'Read More'
    },
    recover_password: {
        id: 'recover_password',
        description: 'recover_password',
        defaultMessage: 'Recover password'
    },
    remember_me: {
        id: 'remember_me',
        description: 'remember_me',
        defaultMessage: 'Remember me'
    },
    remove_favourite: {
        id: 'remove_favourite',
        description: 'remove_favourite',
        defaultMessage: 'Remove favourite'
    },

    residential_kb: {
        id: 'residential_kb',
        description: 'residential_kb',
        defaultMessage: 'Residential / K&B'
    },

    save_cart: {
        id: 'save_cart',
        description: 'save_cart',
        defaultMessage: 'Save cart'
    },
    save_changes: {
        id: 'save_changes',
        description: 'save_changes',
        defaultMessage: 'Save changes'
    },
    save_segmentation: {
        id: 'save_segmentation',
        description: 'save_segmentation',
        defaultMessage: 'Save segmentation'
    },
    save_this_selection_as_favourite: {
        id: 'save_this_selection_as_favourite',
        description: 'save_this_selection_as_favourite',
        defaultMessage: 'Save this selection as favourite'
    },
    search: {
        id: 'search',
        description: 'search',
        defaultMessage: 'Search'
    },
    search_order_history: {
        id: 'search_order_history',
        description: 'search_order_history',
        defaultMessage: 'Order#, reservation#, PO, product...'
    },

    select_transport_area: {
        id: 'select_transport_area',
        description: 'select_transport_area',
        defaultMessage: 'Select transport area'
    },
    shipping_costs: {
        id: 'shipping_costs',
        description: 'shipping_costs',
        defaultMessage: 'Shipping costs'
    },
    shipment: {
        id: 'shipment',
        description: 'shipment',
        defaultMessage: 'Shipment'
    },
    silestone: {
        id: 'silestone',
        description: 'silestone',
        defaultMessage: 'Silestone'
    },
    silestone_25: {
        id: 'silestone_25',
        description: 'silestone_25',
        defaultMessage: 'Silestone 25'
    },
    subtype: {
        id: 'subtype',
        description: 'subtype',
        defaultMessage: 'Subtype'
    },
    subtype1: {
        id: 'subtype1',
        description: 'subtype1',
        defaultMessage: 'Subtype 1'
    },
    subtype2: {
        id: 'subtype2',
        description: 'subtype2',
        defaultMessage: 'Subtype 2'
    },
    subtype3: {
        id: 'subtype3',
        description: 'subtype3',
        defaultMessage: 'Subtype 3'
    },
    mktoolShippingCenter: {
        id: 'mktoolShippingCenter',
        description: 'mktoolShippingCenter',
        defaultMessage: 'Destination center'
    },
    optional: {
        id: 'optional',
        description: 'optional',
        defaultMessage: 'Optional'
    },
    total_items: {
        id: 'total_items',
        description: 'total_items',
        defaultMessage: 'Total items'
    },
    type: {
        id: 'type',
        description: 'type',
        defaultMessage: 'Type'
    },

    you_have: {
        id: 'you_have',
        description: 'you_have',
        defaultMessage: 'You have'
    },
    DF_SHORT: {
        id: 'DF_SHORT',
        description: 'DF_SHORT',
        defaultMessage: 'Reformulation'
    },
    DF_LONG: {
        id: 'DF_LONG',
        description: 'DF_LONG',
        defaultMessage: 'Material available while there is stock. Consult new formulation with your nearest center'
    },
    DE_SHORT: {
        id: 'DE_SHORT',
        description: 'DE_SHORT',
        defaultMessage: 'Thickness'
    },
    DE_LONG: {
        id: 'DE_LONG',
        description: 'DE_LONG',
        defaultMessage: 'Material available while there is stock. Consult new thickness with your nearest center'
    },
    DA_SHORT: {
        id: 'DA_SHORT',
        description: 'DA_SHORT',
        defaultMessage: 'Finish'
    },
    DA_LONG: {
        id: 'DA_LONG',
        description: 'DA_LONG',
        defaultMessage: 'Material available while there is stock. Consult new finish with your nearest center'
    },
    DD_SHORT: {
        id: 'DD_SHORT',
        description: 'DD_SHORT',
        defaultMessage: 'Format'
    },
    DD_LONG: {
        id: 'DD_LONG',
        description: 'DD_LONG',
        defaultMessage: 'Format available while there is stock. Available in jumbo format'
    },
    LD_SHORT: {
        id: 'LD_SHORT',
        description: 'LD_SHORT',
        defaultMessage: 'Total'
    },
    LD_LONG: {
        id: 'LD_LONG',
        description: 'LD_LONG',
        defaultMessage: 'Available color while there is stock'
    },
    stock_high_to_low: {
        id: 'stock_high_to_low',
        description: 'stock_high_to_low',
        defaultMessage: 'Stock: high to low'
    },
    stock_low_to_high: {
        id: 'stock_low_to_high',
        description: 'stock_low_to_high',
        defaultMessage: 'Stock: low to high'
    },
    newest_arrivals: {
        id: 'newest_arrivals',
        description: 'newest_arrivals',
        defaultMessage: 'Newest arrivals'
    },
    oldest_materials: {
        id: 'oldest_materials',
        description: 'oldest_materials',
        defaultMessage: 'Oldest materials'
    },
    material_id: {
        id: 'material_id',
        description: 'Material ID',
        defaultMessage: 'Material ID'
    },
    JUST_CUT: {
        id: 'JUST_CUT',
        description: 'just cut',
        defaultMessage: 'Just cut'
    },
    WEBHEADING: {
        id: 'webHeading',
        description: 'category',
        defaultMessage: 'Category'
    },
    TYPE: {
        id: 'type',
        description: 'type',
        defaultMessage: 'Type'
    },
    SUBTYPE1NAME: {
        id: 'subtype1Name',
        description: 'subtype1Name',
        defaultMessage: 'Subtype 1'
    },
    SUBTYPE2NAME: {
        id: 'subtype2Name',
        description: 'subtype2Name',
        defaultMessage: 'Subtype 2'
    },
    FINISHFAMILY: {
        id: 'finishFamily',
        description: 'finishFamily',
        defaultMessage: 'Finish Family'
    },
    TIENDA_REST_BANCO: {
        id: 'TIENDA/REST./BANCO',
        description: 'TIENDA/REST./BANCO',
        defaultMessage: 'SHOP / REST. / BANK'
    },
    ASCENSORES_MOBILIARI: {
        id: 'ASCENSORES/MOBILIARI',
        description: 'ASCENSORES/MOBILIARI',
        defaultMessage: 'ELEVATORS / FURNITURE'
    },
    TAPA_MESA_MOBILIARIO: {
        id: 'Tapa Mesa/Mobiliario',
        description: 'Tapa Mesa/Mobiliario',
        defaultMessage: 'Table Top / Furniture'
    },
    BANCADA_MOSTRADOR: {
        id: 'Bancada/Mostrador',
        description: 'Bancada/Mostrador',
        defaultMessage: 'Bench / Counter'
    },
    PLATOS_DUCHA: {
        id: 'Platos Ducha',
        description: 'Platos Ducha',
        defaultMessage: 'Shower trays'
    },
    NO_USAR: {
        id: 'NO USAR',
        description: 'NO USAR',
        defaultMessage: 'DO NOT USE'
    },
    FACHADA_VENTILADA: {
        id: 'Fachada Ventilada',
        description: 'Fachada Ventilada',
        defaultMessage: 'Ventilated Facade'
    },
    FACHADA_VENTILADA_CE: {
        id: 'Fachada Ventilada CE',
        description: 'Fachada Ventilada CE',
        defaultMessage: 'CE Ventilated Facade'
    },
    REVEST_INTERIOR: {
        id: 'Revest. Interior',
        description: 'Revest. Interior',
        defaultMessage: 'Interior Coating'
    },
    ENCIMERA_COCINA: {
        id: 'Encimera Cocina',
        description: 'Encimera Cocina',
        defaultMessage: 'Kitchen counter'
    },
    FACHADA_PEGAMENTO: {
        id: 'Fachada Pegamento',
        description: 'Fachada Pegamento',
        defaultMessage: 'Glue facade'
    },
    SUELO: {
        id: 'Suelo',
        description: 'Suelo',
        defaultMessage: 'Ground'
    },
    ENCIMERA_BAÑO: {
        id: 'Encimera Baño',
        description: 'Encimera Baño',
        defaultMessage: 'Countertop Bathroom'
    },
    SUSTITUCIÓN: {
        id: 'Sustitución',
        description: 'Sustitución',
        defaultMessage: 'Substitution'
    },
    MODELO: {
        id: 'Modelo',
        description: 'Modelo',
        defaultMessage: 'Model'
    },
    SERIE: {
        id: 'Serie',
        description: 'Serie',
        defaultMessage: 'Serie'
    },
    VERSÁTIL: {
        id: 'Versátil',
        description: 'Versátil',
        defaultMessage: 'Versatile'
    },
    //filters subtype
    SUBTYPE_01: { id: 'subtype_01', description: 'CAJA MONOCOLOR', defaultMessage: 'MONOCOLOR BOX' },
    SUBTYPE_02: { id: 'subtype_02', description: 'PROMO', defaultMessage: 'PROMO' },
    SUBTYPE_03: { id: 'subtype_03', description: 'ADHESIVOS', defaultMessage: 'ADHESIVES' },
    SUBTYPE_04: { id: 'subtype_04', description: 'CATALOGOS, FOLLETOS Y MANUALES', defaultMessage: 'CATALOGUES, BROCHURES AND MANUALS' },
    SUBTYPE_05: { id: 'subtype_05', description: 'TRIPTICOS', defaultMessage: 'POCKETS' },
    SUBTYPE_06: { id: 'subtype_06', description: 'OTROS', defaultMessage: 'OTHERS' },
    SUBTYPE_07: { id: 'subtype_07', description: 'TEE', defaultMessage: 'TEE' },
    SUBTYPE_08: { id: 'subtype_08', description: 'ANCLAJES', defaultMessage: 'ANCHORING' },
    SUBTYPE_09: { id: 'subtype_09', description: 'CAJA SURTIDA', defaultMessage: 'ASSORTED BOX' },
    SUBTYPE_10: { id: 'subtype_10', description: 'CAJA VACIA', defaultMessage: 'EMPTY BOXES' },
    SUBTYPE_11: { id: 'subtype_11', description: 'SOBREMESA', defaultMessage: 'TABLE TOPPER' },
    SUBTYPE_12: { id: 'subtype_12', description: 'SUELO', defaultMessage: 'STANDALONE' },
    SUBTYPE_13: { id: 'subtype_13', description: 'ETIQUETAS', defaultMessage: 'LABELS' },
    SUBTYPE_14: { id: 'subtype_14', description: 'PLACA METALICA', defaultMessage: 'METALIC SIGN' },
    SUBTYPE_15: { id: 'subtype_15', description: 'LIBROS A&D', defaultMessage: 'A&D BOOKS' },
    SUBTYPE_16: { id: 'subtype_16', description: 'OTROS LIBROS', defaultMessage: 'OTHER BOOKS' },
    SUBTYPE_17: { id: 'subtype_17', description: 'CARPETAS', defaultMessage: 'FOLDERS' },
    SUBTYPE_18: { id: 'subtype_18', description: 'CARAMELOS', defaultMessage: 'SWEETS' },
    SUBTYPE_19: { id: 'subtype_19', description: 'BOLIGRAFOS', defaultMessage: 'PENS' },
    SUBTYPE_20: { id: 'subtype_20', description: 'LIBRETAS', defaultMessage: 'NOTEBOOKS' },
    SUBTYPE_21: { id: 'subtype_21', description: 'DELANTALES', defaultMessage: 'APRONS' },
    SUBTYPE_22: { id: 'subtype_22', description: 'PORTA CD`S', defaultMessage: 'CD HOLDERS' },
    SUBTYPE_23: { id: 'subtype_23', description: 'METROS', defaultMessage: 'MEASURING TAPE' },
    SUBTYPE_24: { id: 'subtype_24', description: 'BOLSAS', defaultMessage: 'BAGS' },
    SUBTYPE_25: { id: 'subtype_25', description: 'CALENDARIOS', defaultMessage: 'CALENDARS' },
    SUBTYPE_26: { id: 'subtype_26', description: 'BATERIAS', defaultMessage: 'BATTERIES' },
    SUBTYPE_27: { id: 'subtype_27', description: 'ABANICOS', defaultMessage: 'FANS' },
    SUBTYPE_28: { id: 'subtype_28', description: 'MEMORIAS USB', defaultMessage: 'FLASH DRIVE' },
    SUBTYPE_29: { id: 'subtype_29', description: 'POSAVASOS', defaultMessage: 'COASTERS' },
    SUBTYPE_30: { id: 'subtype_30', description: 'PACK SURTIDO', defaultMessage: 'ASSORTED PACK' },
    SUBTYPE_31: { id: 'subtype_31', description: 'CLIPS', defaultMessage: 'CLIPS' },
    SUBTYPE_32: { id: 'subtype_32', description: 'REVISTAS Y LIBROS', defaultMessage: 'MAGAZINES AND BOOKS' },
    SUBTYPE_33: { id: 'subtype_33', description: 'CARTELES', defaultMessage: 'SIGNAGE' },
    SUBTYPE_34: { id: 'subtype_34', description: 'POLO TECNICO', defaultMessage: 'TECHNICAL POLO SHIRT' },
    SUBTYPE_35: { id: 'subtype_35', description: 'PANTALON', defaultMessage: 'PANTS' },
    SUBTYPE_36: { id: 'subtype_36', description: 'FORRO POLAR', defaultMessage: 'FLEECE' },
    SUBTYPE_37: { id: 'subtype_37', description: 'CHALECO REFLECTANTE', defaultMessage: 'HIGH VISIBILITY VEST' },
    SUBTYPE_38: { id: 'subtype_38', description: 'PARKA', defaultMessage: 'OILSKIN' },
    SUBTYPE_39: { id: 'subtype_39', description: 'ZAPATO TRABAJO', defaultMessage: 'PROTECTION SHOES' },
    SUBTYPE_40: { id: 'subtype_40', description: 'ESCALERA', defaultMessage: 'STAIRS' },
    SUBTYPE_41: { id: 'subtype_41', description: 'EMBALAJE METÁLICO', defaultMessage: 'METAL PACKING' },
    SUBTYPE_42: { id: 'subtype_42', description: 'ESLINGAS', defaultMessage: 'SLINGS' },
    SUBTYPE_43: { id: 'subtype_43', description: 'HERRAMIENTAS CARGA', defaultMessage: 'LOAD TOOLS' },
    SUBTYPE_44: { id: 'subtype_44', description: 'CASCO PROTECTOR', defaultMessage: 'CRASH HELMET' },
    SUBTYPE_45: { id: 'subtype_45', description: 'TAPON PROTECCIÓN', defaultMessage: 'PROTECTION PLUG' },
    SUBTYPE_46: { id: 'subtype_46', description: 'MASCARILLAS PROTECCIÓN', defaultMessage: 'PROTECTION MASKS' },
    SUBTYPE_47: { id: 'subtype_47', description: 'EMBALAJE PLÁSTICO', defaultMessage: 'PLASTIC PACKING' },
    SUBTYPE_48: { id: 'subtype_48', description: 'ACCESORIOS BAÑOS', defaultMessage: 'BATHROOM ACCESSORIES' },
    SUBTYPE_49: { id: 'subtype_49', description: 'ACCESORIOS INTEGRITY', defaultMessage: 'INTEGRITY ACCESSORIES' },
    SUBTYPE_50: { id: 'subtype_50', description: 'VALVULAS', defaultMessage: 'VALVES' },
    SUBTYPE_51: { id: 'subtype_51', description: 'SEPARADOR TABLAS', defaultMessage: 'SEPARATOR TABLES' },
    SUBTYPE_52: { id: 'subtype_52', description: 'GUANTES PROTECCIÓN', defaultMessage: 'GLOVES PROTECTION' },
    SUBTYPE_53: { id: 'subtype_53', description: 'TORNILLERÍA', defaultMessage: 'SCREWS' },
    SUBTYPE_54: { id: 'subtype_54', description: 'CINTA ADHESIVA', defaultMessage: 'SCOTCH TAPE' },
    SUBTYPE_55: { id: 'subtype_55', description: 'MOCHILAS', defaultMessage: 'BACKPACKS' },
    SUBTYPE_56: { id: 'subtype_56', description: 'PARAGUAS', defaultMessage: 'UMBRELLA' },
    SUBTYPE_57: { id: 'subtype_57', description: 'POLOS', defaultMessage: 'POLO' },
    SUBTYPE_58: { id: 'subtype_58', description: 'CORREAS', defaultMessage: 'STRAPS' },
    SUBTYPE_59: { id: 'subtype_59', description: 'VASOS', defaultMessage: 'GLASSES' },
    SUBTYPE_60: { id: 'subtype_60', description: 'CAMISETAS', defaultMessage: 'TSHIRTS' },
    SUBTYPE_61: { id: 'subtype_61', description: 'GORRAS', defaultMessage: 'HATS' },
    SUBTYPE_62: { id: 'subtype_62', description: 'PISAPAPELES', defaultMessage: 'PAPERS' },
    SUBTYPE_63: { id: 'subtype_63', description: 'OTHERS', defaultMessage: 'OTHERS' },
    SUBTYPE_64: { id: 'subtype_64', description: 'CHALECOS', defaultMessage: 'VESTS' },
    SUBTYPE_65: { id: 'subtype_65', description: 'CAMISAS', defaultMessage: 'SHIRTS' },
    SUBTYPE_66: { id: 'subtype_66', description: 'CHAQUETAS', defaultMessage: 'JACKETS' },
    SUBTYPE_67: { id: 'subtype_67', description: 'BOTELLEROS', defaultMessage: 'BOTTLERS' },
    SUBTYPE_68: { id: 'subtype_68', description: 'PANEL MUESTRAS', defaultMessage: 'SAMPLE PANEL' },
    SUBTYPE_NEWS: { id: 'subtype_news', description: 'NOVEDADES', defaultMessage: 'NEWS' },
    //subtype_1
    SUBTYPE_1_001: { id: 'subtype_1_001', description: 'FOLLETOS', defaultMessage: 'BROCHURES' },
    SUBTYPE_1_002: { id: 'subtype_1_002', description: 'MANUALES', defaultMessage: 'MANUALS' },
    SUBTYPE_1_003: { id: 'subtype_1_003', description: 'CATALOGOS', defaultMessage: 'CATALOGS' },
    SUBTYPE_1_004: { id: 'subtype_1_004', description: 'FICHAS PRODUCTO', defaultMessage: 'PRODUCT SHEETS' },
    SUBTYPE_1_005: { id: 'subtype_1_005', description: 'PLACA METALICA', defaultMessage: 'METAL BOARD' },
    SUBTYPE_1_006: { id: 'subtype_1_006', description: 'REVISTAS', defaultMessage: 'JOURNALS' },
    //subtype_2
    SUBTYPE_2_001: { id: 'subtype_2_001', description: 'GARANTIA', defaultMessage: 'WARRANTY' },
    SUBTYPE_2_002: { id: 'subtype_2_002', description: 'MANUAL DE LIMPIEZA', defaultMessage: 'CLEANING MANUAL' },
    SUBTYPE_2_003: { id: 'subtype_2_003', description: 'MANUAL DE INSTALACIÓN', defaultMessage: 'INSTALLATION MANUAL' },
    SUBTYPE_2_004: { id: 'subtype_2_004', description: 'MANUAL DE SEGURIDAD', defaultMessage: 'SECURITY MANUAL' },
    SUBTYPE_2_005: { id: 'subtype_2_005', description: 'TEE', defaultMessage: 'TEE' },
    SUBTYPE_2_006: { id: 'subtype_2_006', description: 'MANUAL DE USO PROFESIONAL', defaultMessage: 'PROFESSIONAL USE MANUAL' },
    SUBTYPE_2_007: { id: 'subtype_2_007', description: 'FOLLETOS K&B', defaultMessage: 'BROCHURES K&B' },
    SUBTYPE_2_008: { id: 'subtype_2_008', description: 'CATALOGO DE CONTRATOS', defaultMessage: 'CONTRACT CATALOG' },
    SUBTYPE_2_009: { id: 'subtype_2_009', description: 'CATALOGO DE BAÑO', defaultMessage: 'BATH CATALOG' },
    SUBTYPE_2_010: { id: 'subtype_2_010', description: 'RSC - RESPONSABILIDAD SOCIAL CORPORATIVA', defaultMessage: 'CSR - CORPORATE SOCIAL RESPONSIBILITY' },
    SUBTYPE_2_011: { id: 'subtype_2_011', description: 'MANUAL DE BUENAS PRÁCTICAS', defaultMessage: 'GOOD PRACTICE MANUAL' },
    THERE_ARE_ONLY_A_FEW_REMAINING_ITEMS_IN_STOCK_IN_AT_LEAST_ONE_OF_THE_CHOSEN_MATERIALS_IN_THE_ORDER_WHICH_MAY_CONSTRAIN_THE_AVAILABILITY_UPON_ORDEN_CONFIRMATION: {
        id: 'there_are_only_a_few_remaining_items_in_stock_in_at_least_one_of_the_chosen_materials_in_the_order_which_may_constrain_the_availability_upon_orden_confirmation',
        description:
            'There are only a few remaining items in stock in at least one of the chosen materials in the order, which may constrain the availability upon orden confirmation',
        defaultMessage:
            'There are only a few remaining items in stock in at least one of the chosen materials in the order, which may constrain the availability upon order confirmation'
    },
    PLEASE_CONFIRM_THE_ORDER: {
        id: 'please_confirm_the_order',
        description: 'Please confirm the order',
        defaultMessage: 'Please confirm the order'
    },
    PRODUCT_PORTFOLIO: {
        id: 'PRODUCT_PORTFOLIO',
        description: 'PRODUCT_PORTFOLIO',
        defaultMessage: 'PRODUCT PORTFOLIO'
    },
    SHOPPING_CART_CHECKOUT: {
        id: 'SHOPPING_CART_CHECKOUT',
        description: 'SHOPPING_CART_CHECKOUT',
        defaultMessage: 'SHOPPING CART & CHECKOUT'
    },
    ORDERS: {
        id: 'ORDERS',
        description: 'ORDERS',
        defaultMessage: 'ORDERS'
    },
    PERSONAL_AREA: {
        id: 'PERSONAL_AREA',
        description: 'PERSONAL_AREA',
        defaultMessage: 'PERSONAL AREA'
    },
    ORDER_HISTORY: {
        id: 'ORDER_HISTORY',
        description: 'ORDER_HISTORY',
        defaultMessage: 'ORDER HISTORY'
    },
    OTHERS: {
        id: 'OTHERS',
        description: 'OTHERS',
        defaultMessage: 'OTHERS'
    },
    NEWS: {
        id: 'NEWS',
        description: 'NEWS',
        defaultMessage: 'NEWS'
    },
    SAVE_COLORS_MKTOOLS_ATTR_SUCCESS: {
        id: 'SAVE_COLORS_MKTOOLS_ATTR_SUCCESS',
        description: 'SAVE_COLORS_MKTOOLS_ATTR_SUCCESS',
        defaultMessage: 'The color list has been saved'
    },
    SAVE_CCOLORS_MKTOOLS_ATTR_FAILURE: {
        id: 'SAVE_CCOLORS_MKTOOLS_ATTR_FAILURE',
        description: 'SAVE_CCOLORS_MKTOOLS_ATTR_FAILURE',
        defaultMessage: 'The color list could not be saved'
    },
    DUPLICATED_COLOR_MKTOOLS_ATTR: {
        id: 'DUPLICATED_COLOR_MKTOOLS_ATTR',
        description: 'DUPLICATED_COLOR_MKTOOLS_ATTR',
        defaultMessage: 'The color is already in the list'
    },
    EMAIL_SENT: {
        id: 'EMAIL_SENT',
        description: 'EMAIL_SENT',
        defaultMessage: 'Email sent'
    },
    PROMOTION_OR_EMAIL_NOT_FOUND: {
        id: 'PROMOTION_OR_EMAIL_NOT_FOUND',
        description: 'PROMOTION_OR_EMAIL_NOT_FOUND',
        defaultMessage: 'Promotion or email not found'
    },
    theres_no_stock_available_now: {
        id: 'theres_no_stock_available_now',
        description: 'theres_no_stock_available_now',
        defaultMessage: 'You can buy this product even if it is out of stock. We will delivery it to you from our supply chain. Delivery time may increase slightly'
    }
})

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            null
        )(LocalizedLink)
    )
)
