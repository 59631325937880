import React from 'react'
import { connect } from 'react-redux'
import Translation from '../../global/Translation'
import { FormattedHTMLMessage } from 'react-intl'
import backgroundImage from '../../../assets/img/banner-silestone/banner-silestone-background.png'
import { isOnlyUsaSalesOrg } from '../../../store/centers/reducers'
import canadaFlayer from './Silestone-Flash-Promo-Flyer-Canada.pdf'
import usaFlayer from './Silestone-Flash-Promo-Flyer-USA.pdf'


const mapStateToProps = state => {
    return {
        isOnlyUsaSalesOrg: isOnlyUsaSalesOrg(state)
    }
}

class BannerSilestoneContainer extends React.Component {
    constructor(props) {
        super(props)

        this.discount = '10%'
    }

    render() {
        const { className, isCheckoutView, isOnlyUsaSalesOrg } = this.props

        const footerDisclaimer = (
            <span>
                <FormattedHTMLMessage
                    id="promotion_includes_all_silestone_colors"
                    defaultMessage="Promotion includes <b>all Silestone®</b> colors, thicknesses, and finishes. The possibilities are endless."
                />
            </span>
        )

        const headerContent = (
            <div>
                <h2>
                    <Translation id="silestone_container_flash_promo" defaultMessage="Silestone® Container Flash Promo" />
                </h2>
                <h3>
                    <Translation id="march_19_28_2025" defaultMessage="March 19th - 28th 2025" />
                </h3>
            </div>
        )

        const discountContent = (
            <React.Fragment>
                <div className="number-percent">
                    <span>{this.discount}</span>
                </div>
                <div className="text-percent">
                    <div>
                        <span>
                            <Translation id="additional_discount" defaultMessage="additional discount" />
                        </span>
                    </div>
                    <div>
                        <span>
                            <Translation id="and_6_month_equal_payment_terms" defaultMessage="and 6-month equal payment terms" />
                        </span>
                    </div>
                </div>
            </React.Fragment>
        )

        const footerContent = (
            <div className="d-flex justify-content-start flex-column" style={{ gap: '8px' }}>
                <div>
                    <span>
                        <Translation id="contact_your_local_cosentino_center_for_support" defaultMessage="Contact your local Cosentino Center for support." />
                    </span>
                </div>
                <div>
                    <a href={isOnlyUsaSalesOrg ? usaFlayer : canadaFlayer} target="_blank" rel="noopener noreferrer" className="view-conditions-btn">
                        <button>
                            <Translation id ="view_conditions" defaultMessage="View conditions" />
                        </button>
                    </a>
                </div>
            </div>
        )

        return (
            <React.Fragment>
                {!isCheckoutView ? (
                    <div
                        className={`big__banner__silestone ${className}`}
                        style={{
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom'
                        }}
                    >
                        <div className="big__banner__silestone--header">
                            <div className="left-side">
                                {headerContent}
                                <div className="d-none d-md-block">
                                    <div className="left-side--footer">{footerDisclaimer}</div>
                                </div>
                            </div>

                            <div className="right-side">
                                <div className="right-side--content">{discountContent}</div>
                                <div className="d-block d-md-none">
                                    <div className="left-side--footer">{footerDisclaimer}</div>
                                </div>
                                <div className="right-side--footer">{footerContent}</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="small__banner__silestone"
                        style={{
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}
                    >
                        <div className="small__banner__silestone--title">{headerContent}</div>
                        <div className="small__banner__silestone--content">{discountContent}</div>
                        <div className="small__banner__silestone--footer">{footerDisclaimer}</div>
                        <div className="small__banner__silestone--discount">{footerContent}</div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, null)(BannerSilestoneContainer)
