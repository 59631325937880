import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import {
    getCurrentSegmentation,
    getSelectedChannel,
    getSelectedUse,
    getUseOptions
} from '../../store/segmentation/reducers'
import { selectUse, toggleSegmentation } from '../../store/segmentation/actions'
import Select from 'react-select'

const mapStateToProps = state => {
    return {
        show: getCurrentSegmentation(state) === 'USE',
        selected: getSelectedUse(state),
        options: getUseOptions(state),
        channel: getSelectedChannel(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('USE'))
        },
        onSelect: (value, label) => {
            dispatch(selectUse(value, label))
        }
    }
}

const DEFAULT_VALUE = '10'

const UseV3 = ({ show, selected, onToggle, onSelect, options, intl, channel }) => {
    if (!options || Object.entries(options).length === 0) {
        return ''
    }

    const isOptional = channel !== 'nexus_retail' && channel !== 'nexus_non_retail'
    let placeholder= intl.formatMessage({ id: 'use' })


    if (options.length === 1 && selected !== options[0].id) {
        onSelect(options[0].id, options[0].name)
    }
    if (isOptional) placeholder += ' (' + intl.formatMessage({ id: 'optional' }) + ')'

    return (
        <div>
            <div className="segmentation-segment-title">
                <Translation id="use" defaultMessage="Use" />
                {!isOptional && ( <span>*</span>)}:
            </div>
            <Select
                // placeholder={intl.formatMessage({ id: 'use' })}
                placeholder={placeholder}
                options={options.map(option => ({
                    label: option.name,
                    value: option.id
                }))}
                value={selected}
                onChange={option => option ? onSelect(option.value, option.label) : onSelect(null, null)}
                clearable={options && options.length > 1}
            />
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UseV3)
