import React, {useState} from 'react'
import Button from '../global/Button'
import Translation from '../global/Translation'
import {SAVED_SEGMENTATIONS_STEP, SEGMENTATION_EVIDENCE_STEP, SEGMENTATION_V3_STEP} from "./SegmentationSteps";
import {RadioButton} from "@progress/kendo-react-inputs";
import LineSegmentationV2 from "../cart-line/LineSegmentationV2";



const SegmentationSavedSuccesfully = props => {
    const { onSetStep } = props
    const option = {
        businessSegment: props.businessSegment,
        channel: props.channel,
        projectId: props.projectId,
        salesforceId: props.salesforceId,
        subChannel: props.subchannel,
        typology: props.typology,
        use: props.use,
    }

    return (
        <div className="suggested__segmentation">
            <div className="suggested__segmentation__content--input">
                <div>
                    <strong>
                        <LineSegmentationV2 line={{ ...option, segmentation: option }} showAsList hideSpans />
                    </strong>
                </div>
            </div>

            <div>
                <hr></hr>

                <footer>
                    <div className="double-buttons my-1">
                        <Button onClick={() => onSetStep(SAVED_SEGMENTATIONS_STEP)} >
                            <Translation id="view_saved_segmentations" defaultMessage="View saved segmentations" />
                        </Button>

                        <Button
                            onClick={() => onSetStep(SEGMENTATION_V3_STEP)}
                            inverted
                            // dataQas="global_segmentation-add-to-cart-button"
                            icon="fal fa-chevron-right"
                            iconEnd={true}
                        >
                            <Translation id="continue" defaultMessage="Continue" />
                        </Button>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default SegmentationSavedSuccesfully
