import * as api from '../api'
import {getIsLogged, getToken} from '../login/reducers'
import { getIKEACenterId, getIKEACanariasCenterId, getIsLowesCenter, getSelectedCenter } from '../centers/reducers'
import { getLanguage, getLanguageToFetchZ2 } from '../ui/reducers'
import { byProductId, getProductsIdFavourite, similarProductByProductId, getColorsMktoolsAttr, getFetchedProducts } from './reducers'

export const fetchProduct = (productId, extra) => (dispatch, getState) => {
    if (productId === 'CUT_TO_SIZE' || productId === '999999') {
        return dispatch({ type: 'TRY_LOAD_A_CUT_TO_SIZE' })
    }


    const state = getState()

    let centerId = state.centers.selectedCenter ? state.centers.selectedCenter : 'near'
    // const controlProduct = state.products.fetchedProducts.find((el) => el.control && el.product.productId == productId && el.centerId == centerId)
    // if (controlProduct) return Promise.resolve()
    //
    // if (isComingFromMall(state)) {
    //
    //     const product = getSelectedProduct(state)
    //     if (product) centerId = product.center_id
    // }

    const locale = getLanguageToFetchZ2(state)

    const selectedProduct = byProductId(state, productId, true)
    if (selectedProduct) {
        const fetchedProducts = getFetchedProducts(state)
        fetchedProducts.sort((a, b) => a.product == selectedProduct ? - 1 : b.product == selectedProduct ? 1 : 0)

        return Promise.resolve()
    }

    // //Mecanismo simploncete para que no haga el fetch simultaneamente más de una vez para el mismo material
    // state.products.fetchedProducts.push(
    //     {
    //         product: {productId},
    //         centerId,
    //         locale,
    //         control: true
    //     }
    // )
    //
    // const cleanControlProduct = (productId) => {
    //     const indexToRemove = state.products.fetchedProducts.findIndex((el) => el.control && el.product.productId == productId && el.product.centerId == centerId)
    //     state.products.fetchedProducts.splice(indexToRemove, 1)
    // }

    dispatch({
        type: 'FETCH_PRODUCT_REQUEST',
        productId,
        centerId,
        locale
    })

    return api
        .fetchProductById(centerId, productId, locale)
        .then(response => {
            // cleanControlProduct(productId)
            const product = response.data
            if (extra) product.extra = extra
            return dispatch({ type: 'FETCH_PRODUCT_SUCCESS', product: response.data, centerId, locale })
        })
        .catch(error => {
            // cleanControlProduct(productId)
            return dispatch({ type: 'FETCH_PRODUCT_FAILURE', response: error.response })
        })
}
export const fetchSimilarProduct = (productId, colorId) => (dispatch, getState) => {
    if (similarProductByProductId(getState(), productId)) return Promise.resolve()
    dispatch({ type: 'FETCH_SIMILAR_PRODUCT_REQUEST', colorId })
    const centerId = getState().centers.selectedCenter ? getState().centers.selectedCenter : 'near'
    return api
        .fetchSimilarProduct(centerId, colorId)
        .then(response => {
            dispatch({ type: 'FETCH_SIMILAR_PRODUCT_SUCCESS', productId: productId, products: response.data, centerId, locale: getLanguage(getState()) })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_SIMILAR_PRODUCT_FAILURE' })
        })
}
// const fetchLastProductsBoughtIds = () => (dispatch, getState) => {
//     dispatch({ type: 'LAST_PRODUCTS_BOUGHT_REQUEST' })
//
//     return api
//         .fetchLastProductsBought(getToken(getState()), getSelectedCenter(getState()))
//         .then(response => {
//             dispatch({ type: 'LAST_PRODUCTS_BOUGHT_SUCCESS', products: response.data })
//         })
//         .catch(error => {
//             dispatch({ type: 'LAST_PRODUCTS_BOUGHT_FAILURE' })
//             api.errorHandler(dispatch, error.response)
//             return Promise.reject()
//         })
// }
//
// export const fetchLastProductsBought = () => (dispatch, getState) => {
//     dispatch({ type: 'LAST_PRODUCTS_BOUGHT_INIT' })
//
//     return dispatch(fetchLastProductsBoughtIds()).then(
//         () => Promise.all(getLastProductBoughtsId(getState()).map(productId => dispatch(fetchProduct(productId)))).then(() => dispatch({ type: 'LAST_PRODUCTS_BOUGHT_FINISH' })),
//         () => {}
//     )
// }
// const fetchProductsForYouIds = () => (dispatch, getState) => {
//     dispatch({ type: 'PRODUCTS_FOR_YOU_REQUEST' })
//
//     return api
//         .fetchProductsForYou(getToken(getState()), getSelectedCenter(getState()))
//         .then(response => {
//             dispatch({ type: 'PRODUCTS_FOR_YOU_SUCCESS', products: response.data })
//         })
//         .catch(error => {
//             dispatch({ type: 'PRODUCTS_FOR_YOU_FAILURE' })
//             api.errorHandler(dispatch, error.response)
//             return Promise.reject()
//         })
// }
//
// export const fetchProductsForYou = () => (dispatch, getState) => {
//     dispatch({ type: 'PRODUCTS_FOR_YOU_INIT' })
//
//     return dispatch(fetchProductsForYouIds()).then(
//         () => Promise.all(getProductsForYouId(getState()).map(productId => dispatch(fetchProduct(productId)))).then(() => dispatch({ type: 'PRODUCTS_FOR_YOU_FINISH' })),
//         () => {}
//     )
// }
export const fetchSpecialProductNames = () => (dispatch, getState) => {
    let token = getToken(getState())
    dispatch({ type: 'FETCH_SPECIAL_NAMES', token })

    return api
        .fetchSpecialNames(token, getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_SPECIAL_NAMES_SUCCESS', specialNames: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_SPECIAL_NAMES_FAILURE' })
        })
}

export const fetchUserPriceList = () => (dispatch, getState) => {
    let token = getToken(getState())
    dispatch({ type: 'FETCH_USER_PRICE_LIST', token })

    return api
        .fetchUserPriceList(token, getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_USER_PRICE_LIST_SUCCESS', priceList: response.data.userPriceList })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_USER_PRICE_LIST_FAILURE' })
        })
}

export const fetchRefreshUserPriceList = () => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)    
    const centerId = getSelectedCenter(state)
    const productIds = state.landing.productsLanding && state.landing.productsLanding.map(v => v.productId)

    if (productIds && productIds.length) {
        dispatch({ type: 'REFRESH_USER_PRODUCTS_CACHE', values : { token, productIds}})

    return api
        .fetchRefreshUserPriceList(token, centerId, productIds)
        .then(response => {
            dispatch({ type: 'REFRESH_USER_PRODUCTS_CACHE_SUCCESS' })
            dispatch({ type: 'CLEAR_PHASE2_PRODUCT_BY_CLIENT' })
        })
        .catch(error => {
            dispatch({ type: 'REFRESH_USER_PRODUCTS_CACHE_FAILURE' })
        })
    }    
}

export const fetchUserPriceListByUser = userId => (dispatch, getState) => {
    let token = getToken(getState())
    dispatch({ type: 'FETCH_USER_PRICE_LIST', token })

    return api
        .fetchUserPriceListByUser(token, userId, getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_USER_PRICE_LIST_SUCCESS', priceList: response.data.userPriceList })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_USER_PRICE_LIST_FAILURE' })
        })
}

export const fetchColorsMktoolsAttr = brand => (dispatch, getState) => {
    dispatch({ type: 'FETCH_COLORS_MKTOOLS_ATTR_REQUEST' })
    return api
        .fetchColorsMktoolsAttr()
        .then(response => {
            dispatch({ type: 'FETCH_COLORS_MKTOOLS_ATTR_SUCCESS', colors: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_CCOLORS_MKTOOLS_ATTR_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const saveColorsMktoolsAttr = () => (dispatch, getState) => {
    dispatch({ type: 'SAVE_COLORS_MKTOOLS_ATTR_REQUEST' })

    return api
        .saveColorsMktoolsAttr(getToken(getState()), getColorsMktoolsAttr(getState()))
        .then(response => {
            dispatch({ type: 'SAVE_COLORS_MKTOOLS_ATTR_SUCCESS', colors: response.data })
        })
        .catch(error => {
            dispatch({ type: 'SAVE_CCOLORS_MKTOOLS_ATTR_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const addNewColorMktoolsAttr = (nameColor, brand) => (dispatch, getState) => {
    if (typeof nameColor !== 'string' || nameColor.trim() === '') return null
    let nameColorToAdd = nameColor
        .split(' ')
        .map(word => {
            return (
                word
                    .toLowerCase()
                    .charAt(0)
                    .toUpperCase() + word.toLowerCase().slice(1)
            )
        })
        .join(' ')

    if ((getColorsMktoolsAttr(getState()) && !getColorsMktoolsAttr(getState())[brand]) || getColorsMktoolsAttr(getState())[brand].findIndex(name => name === nameColorToAdd) === -1)
        dispatch({ type: 'ADD_COLOR_MKTOOLS_ATTR_SUCCESS', color: nameColorToAdd, brand })
    else dispatch({ type: 'DUPLICATED_COLOR_MKTOOLS_ATTR' })

    return dispatch(getIndexOfColorsMktoolsAtter(nameColorToAdd, brand))
}
export const getIndexOfColorsMktoolsAtter = (nameColor, brand) => (dispatch, getState) => {
    return getColorsMktoolsAttr(getState())[brand] ? getColorsMktoolsAttr(getState())[brand].findIndex(name => name === nameColor) : -1
}
export const removeColorMktoolsAttr = (i, brand) => (dispatch, getState) => {
    dispatch({ type: 'REMOVE_COLOR_MKTOOLS_ATTR_SUCCESS', index: i, brand })
}
export const fetchColorsCutToSize = brand => (dispatch, getState) => {
    dispatch({ type: 'FETCH_COLORS_CUT_TO_SIZE_REQUEST' })
    return api
        .fetchColorsCutToSize(brand.toUpperCase(), getLanguage(getState()), getToken(getState()))
        .then(response => {
            let productsId = response.data.map(product => {
                return product.id
            })
            dispatch({ type: 'FETCH_COLORS_CUT_TO_SIZE_SUCCESS', productsId: productsId, products: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_COLORS_CUT_TO_SIZE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const fetchSamplesCutToSize = brand => (dispatch, getState) => {
    dispatch({ type: 'FETCH_SAMPLES_CUT_TO_SIZE_REQUEST' })
    return api
        .fetchSamplesCutToSize(brand.toUpperCase(), getLanguage(getState()), getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_SAMPLES_CUT_TO_SIZE_SUCCESS', samples: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_SAMPLES_CUT_TO_SIZE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const fetchFurnituresCutToSize = brand => (dispatch, getState) => {
    dispatch({ type: 'FETCH_FURNITURES_CUT_TO_SIZE_REQUEST' })
    return api
        .fetchFurnituresCutToSize(brand.toUpperCase(), getLanguage(getState()), getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_FURNITURES_CUT_TO_SIZE_SUCCESS', furnitures: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_FURNITURES_CUT_TO_SIZE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const fetchProductsFavourite = () => (dispatch, getState) => {
    if (getProductsIdFavourite(getState()).length !== 0) return

    dispatch({ type: 'FETCH_PRODUCTS_FAVOURITE_REQUEST' })
    return api
        .fetchProductsFavourite(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            let productsId = response.data.products.map(product => {
                return product.productId
            })
            dispatch({ type: 'FETCH_PRODUCTS_FAVOURITE_SUCCESS', productsId: productsId, products: response.data.products })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCTS_FAVOURITE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const fetchProductsDiscounts = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PRODUCTS_DISCOUNTS_REQUEST' })
    return api
        .fetchProductsDiscounts(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCTS_DISCOUNTS_SUCCESS', discounts: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCTS_DISCOUNTS_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}
export const fetchProductsDiscountsByToken = token => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PRODUCTS_DISCOUNTS_REQUEST' })
    return api
        .fetchProductsDiscounts(token, getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCTS_DISCOUNTS_SUCCESS', discounts: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCTS_DISCOUNTS_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}
export const addProductFavourite = productId => (dispatch, getState) => {
    dispatch({ type: 'ADD_PRODUCT_FAVOURITE_REQUEST', product: productId })

    return api
        .addProductsFavourite(getToken(getState()), getSelectedCenter(getState()), productId)
        .then(async response => {
            await dispatch(fetchProduct(productId))
            await dispatch({
                type: 'ADD_PRODUCT_FAVOURITE_SUCCESS',
                productId: productId,
                product: byProductId(getState(), productId)
            })
        })
        .catch(error => {
            dispatch({ type: 'ADD_PRODUCT_FAVOURITE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const removeProductFavourite = productId => (dispatch, getState) => {
    dispatch({ type: 'REMOVE_PRODUCT_FAVOURITE_REQUEST', productId: productId })

    return api
        .removeProductsFavourite(getToken(getState()), getSelectedCenter(getState()), productId)
        .then(response => {
            dispatch({ type: 'REMOVE_PRODUCT_FAVOURITE_SUCCESS', productId: productId })
        })
        .catch(error => {
            dispatch({ type: 'REMOVE_PRODUCT_FAVOURITE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const toogleFavourite = productId => (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_PRODUCT_FAVOURITE' })

    if (getProductsIdFavourite(getState()).find(id => id === productId) !== undefined) {
        dispatch(removeProductFavourite(productId))
    } else {
        dispatch(addProductFavourite(productId))
    }
}
export const isSample = product => {
    return product.typeId === '01'
}
export const isOtherMask = product => {

    //lavabo integrity
    //For production
    if(product.subtypeId === 'LAVABO' && product.brand === 'INTEGRITY' && !product.displayName.includes('ZOE')) return true
    //for pcm
    if ((product.type === 'BASIN' || product.type === 'VANITY') && product.brand === 'INTEGRITY' && !product.displayName.includes('ZOE')) return true
    //products with default render image
    if(product.img && product.img.includes('-default')) return true
    //sample box
    return (
        (product.typeId === 'NEWS' || product.typeId === '01') &&
        ((product.format && product.format.includes('9,8X9,8')) ||
            (product.format && product.format.includes('15X15')) ||
            (product.format && product.format.includes('15X12')) ||
            (product.format && product.format.includes('9,8X4,7X0,4')) ||
            (product.format && product.format.includes('20X10X0,4')) ||
            (product.format && product.format.includes('30X15X2')) ||
            (product.format && product.format.includes('30X30X2')))
    )
}
//HARDCODE: getImageBackground
export const getImageBackground = product => (dispatch, getState) => {
    const APP_URL = process.env.REACT_APP_API_HOST

    if (product.centerId == getIKEACenterId() || product.centerId == getIKEACanariasCenterId()) {
        if (product.brand === 'SERVICES') {
            if (product.type === 'ENCIMERA') {
                return product.thumbMd
            }
            return APP_URL + '/ikea/' + product.productId + '.png'
        }
    }

    if (getIsLowesCenter(getState())) {
        if (['907218', '905252', '909258'].includes(product.productId)) {
            return APP_URL + '/lowes/' + product.productId + '.jpg'
        }
    }

    if(!!product.imgLarge && product.imgLarge !== 'null') return product.imgLarge
    // if(!product.img) return noBundleImage
    return product.img
}

export const getImageThumb = product => (dispatch, getState) => {
    const APP_URL = process.env.REACT_APP_API_HOST

    if (product.centerId == getIKEACenterId() || product.centerId == getIKEACanariasCenterId()) {
        if (product.brand === 'SERVICES') {
            if (product.type === 'ENCIMERA') {
                return product.thumbMd
            }
            return APP_URL + '/ikea/' + product.productId + '.png'
        }
    }

    if (getIsLowesCenter(getState())) {
        if (['907218', '905252', '909258'].includes(product.productId)) {
            return APP_URL + '/lowes/' + product.productId + '.jpg'
        }
    }


    // if(!!product.thumbMd) return product.thumbMd
    return product.img
}

export const fetchProductsMktToMax = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PRODUCTS_MKT_TO_MAX_REQUEST' })
    return api
        .fetchProductsMktToMax(getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCTS_MKT_TO_MAX_SUCCESS', productsMktToMax: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCTS_MKT_TO_MAX_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        })
}
export const fetchProductsDeviatedAdmin = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PRODUCTS_DEVIATED_ADMIN_REQUEST' })
    return api
        .fetchProductsDeviatedAdmin(getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCTS_DEVIATED_ADMIN_SUCCESS', productsDeviatedAdmin: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCTS_DEVIATED_ADMIN_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        })
}
export const saveProductsDeviatedAdmin = descriptions => (dispatch, getState) => {
    dispatch({ type: 'SAVE_PRODUCTS_DEVIATED_ADMIN_REQUEST', descriptions })
    return api
        .saveProductsDeviatedAdmin(getToken(getState()), descriptions)
        .then(response => {
            dispatch({ type: 'SAVE_PRODUCTS_DEVIATED_ADMIN_SUCCESS', products: response.data })
            dispatch({
                type: 'FLOATING_ALERTS_ADD_SUCCESS_MESSAGE',
                successMessage: 'THE_PRODUCTS_HAVE_BEEN_SAVED_SUCCESSFULLY',
                values: { listOfProducts: response.data.map(item => item.productId).join(', ') }
            })
        })
        .catch(error => {
            dispatch({ type: 'SAVE_PRODUCTS_DEVIATED_ADMIN_FAILURE' })
            dispatch({
                type: 'FLOATING_ALERTS_ADD_FAILURE_MESSAGE',
                failureMessage: 'SOMETHING_WENT_WRONG'
            })
            // api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const fetchDeviatedDescriptions = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_DEVIATED_DESCRIPTIONS_REQUEST' })
    return api
        .fetchDeviatedDescriptions(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_DEVIATED_DESCRIPTIONS_SUCCESS', descriptions: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_DEVIATED_DESCRIPTIONS_FAILURE', error: error })
        })
}
export const saveProductsMktToMax = products => (dispatch, getState) => {
    dispatch({ type: 'SAVE_PRODUCTS_MKT_TO_MAX_REQUEST', products })
    return api
        .saveProductsMktToMax(getToken(getState()), products)
        .then(response => {
            dispatch({ type: 'SAVE_PRODUCTS_MKT_TO_MAX_SUCCESS', productsMktToMax: response.data })
            dispatch({
                type: 'FLOATING_ALERTS_ADD_SUCCESS_MESSAGE',
                successMessage: 'THE_PRODUCTS_HAVE_BEEN_SAVED_SUCCESSFULLY',
                values: { listOfProducts: response.data.map(item => item.productId).join(', ') }
            })
        })
        .catch(error => {
            dispatch({ type: 'SAVE_PRODUCTS_MKT_TO_MAX_FAILURE' })
            dispatch({
                type: 'FLOATING_ALERTS_ADD_FAILURE_MESSAGE',
                failureMessage: 'SOMETHING_WENT_WRONG'
            })
            // api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const getProductById = productId => (dispatch, getState) => {
    return byProductId(getState(), productId)
}

export const fetchLastProductsBought = () => (dispatch, getState) => {
    dispatch({ type: 'LAST_PRODUCTS_BOUGHT_REQUEST' })

    return api
        .fetchLastProductsBought(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'LAST_PRODUCTS_BOUGHT_SUCCESS', products: response.data })
        })
        .catch(error => {
            dispatch({ type: 'LAST_PRODUCTS_BOUGHT_FAILURE' })
            api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const fetchProductsForYou = () => (dispatch, getState) => {
    dispatch({ type: 'PRODUCTS_FOR_YOU_REQUEST' })

    return api
        .fetchProductsForYou(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'PRODUCTS_FOR_YOU_SUCCESS', products: response.data })
        })
        .catch(error => {
            dispatch({ type: 'PRODUCTS_FOR_YOU_FAILURE' })
            api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const fetchProductsSimilarColors = (productId) => (dispatch, getState) => {
    dispatch({ type: 'PRODUCTS_SIMILAR_COLORS_REQUEST' })

    return api
        .fetchProductsSimilarColors(getToken(getState()), getSelectedCenter(getState()), productId)
        .then(response => {
            dispatch({ type: 'PRODUCTS_SIMILAR_COLORS_SUCCESS', products: response.data })
        })
        .catch(error => {
            dispatch({ type: 'PRODUCTS_SIMILAR_COLORS_FAILURE' })
            api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const fetchLastVisitedProducts = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LAST_VISITED_PRODUCTS_REQUEST' })

    return api
        .fetchLastVisitedProducts(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_LAST_VISITED_PRODUCTS_REQUEST_SUCCESS', products: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LAST_VISITED_PRODUCTS_REQUEST_ERROR', error: error })
            api.errorHandler(dispatch, error.response)
            return Promise.reject()
        })
}

export const fetchSlidersProducts = () => (dispatch, getState) => {
    dispatch(fetchLastVisitedProducts())
    dispatch(fetchProductsForYou())
    dispatch(fetchLastProductsBought())
}

export const postVisitedProduct = (productId, outlet) => (dispatch, getState) => {
    dispatch({
        type: 'POST_VISITED_PRODUCT',
        productId
    })
    const state = getState()

    return api
        .postVisitedProduct(getToken(state), getSelectedCenter(state), productId, outlet)
        .then(response => {
            dispatch({ type: 'POST_VISITED_PRODUCT_SUCCESS' })
            dispatch(fetchLastVisitedProducts())
        })
        .catch(error => {
            dispatch({ type: 'POST_VISITED_PRODUCT_FAILURE' })
        })
}

export const addSelectedProductToFetched = (product) => (dispatch, getState) => {
    const state = getState()
    if (getIsLogged(state)) {
        let centerId = state.centers.selectedCenter
        const locale = getLanguageToFetchZ2(state)
        dispatch({ type: 'ADD_SELECTED_PRODUCT_TO_FETCHED', product, centerId, locale})
    }
}
