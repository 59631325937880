import React from 'react'
import { injectIntl } from 'react-intl'

class DisplayOutletIndicator extends React.Component {
    render() {
        const { outletQuality, inSmallBox = false, isSimplified = false, displayInLine = false } = this.props

        // Styles for small box
        const smallBoxStyles = {
            fontSize: '8px',
            lineHeight: '9px',
            padding: '2px 4px',
            minHeight: '21px',
            letterSpacing: '0.5px'
        }

        // General styles
        const styles = {
            backgroundColor: '#C76923',
            color: '#FFFFFF',
            fontWeight: '500',
            fontSize: inSmallBox ? smallBoxStyles.fontSize : '10px',
            lineHeight: inSmallBox ? smallBoxStyles.lineHeight : '11px',
            padding: inSmallBox ? smallBoxStyles.padding : '4px 8px',
            textTransform: 'uppercase',
            zIndex: 1,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            position: 'relative',
            display: displayInLine ? 'inline' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            minHeight: inSmallBox ? smallBoxStyles.minHeight : '21px',
            textAlign: 'left'
        }

        if (outletQuality) {
            return (
                <div className="outlet-indicator" style={styles}>
                    {this.props.outletQuality &&
                        this.props.intl.formatMessage({
                            id: `outlet_${this.props.outletQuality.toLowerCase()}`,
                        })}{' '}
                    {this.props.outletDeviated &&
                        this.props.intl.formatMessage({
                            id: 'deviated',
                        })}
                </div>
            )
        }

        if (isSimplified) {
            return (
                <div className="outlet-indicator" style={styles}>
                    outlet
                </div>
            )
        }

        return null
    }
}

export default injectIntl(DisplayOutletIndicator)
