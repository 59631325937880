import React, {useState} from 'react'
import Button from '../global/Button'
import Translation from '../global/Translation'
import {SEGMENTATION_EVIDENCE_STEP, SEGMENTATION_SUCCESSFULLY_SAVED, SEGMENTATION_V3_STEP} from "./SegmentationSteps";
import {RadioButton} from "@progress/kendo-react-inputs";
import LineSegmentationV2 from "../cart-line/LineSegmentationV2";



const SavingSegmentation = props => {
    const { saveSegmentationPreferred, onSetStep } = props

    const [selectedIndex, setSelectedIndex] = useState()
    const [inputSegmentation, setInputSegmentation] = useState()
    const option = {
        businessSegment: props.businessSegment,
        channel: props.channel,
        projectId: props.projectId,
        salesforceId: props.salesforceId,
        subChannel: props.subchannel,
        typology: props.typology,
        use: props.use,
    }

    console.log('OPTION', option)

    return (
        <div className="suggested__segmentation">
            <div className="suggested__segmentation__content--input">
                <div>
                    <strong>
                        <LineSegmentationV2 line={{ ...option, segmentation: option }} showAsList hideSpans />
                    </strong>
                </div>
                <p>
                    <Translation id="segmentation_prompt_body" defaultMessage="Please choose a unique name for this configuration" />
                </p>
                <div className="form-group">
                    <b>
                        <Translation id="name" defaultMessage="Name" />
                    </b>
                    <input
                        ref={ref => setInputSegmentation(ref)}
                        type="text"
                        className="form-control"
                        data-cy="modal_segmentation__input_name_save_segmentation"
                        placeholder={props.intl.formatMessage({
                            id: 'name'
                        })}
                    />
                </div>
            </div>

            <div>
                <hr></hr>

                <footer>
                    <div className="double-buttons my-1">
                        <Button onClick={() => onSetStep(SEGMENTATION_V3_STEP)} >
                            <Translation id="cancel" defaultMessage="Cancel" />
                        </Button>

                        <Button
                            onClick={() => {
                                saveSegmentationPreferred(inputSegmentation.value)
                                onSetStep(SEGMENTATION_SUCCESSFULLY_SAVED)
                            }}
                            inverted
                            // dataQas="global_segmentation-add-to-cart-button"
                            icon="fal fa-chevron-right"
                            iconEnd={true}
                        >
                            <Translation id="next" defaultMessage="Next" />
                        </Button>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default SavingSegmentation
