import React from "react";
import Translation from "../global/Translation";
import Select from "react-select";
import {RadioButton} from "@progress/kendo-react-inputs";
import {injectIntl} from "react-intl";
import {
    canSeeVirtualVisitOptions, getVirtualVisitSalesforceIds
} from "../../store/checkout/reducers";
import {
    fetchVirtualVisitSalesforceId, setVirtualVisitData
} from "../../store/checkout/actions";
import {connect} from "react-redux";

class SelectVirtualVisitComponent extends React.Component {

    orderOriginOptions = [
        {
            value: "normal_order",
            label: <Translation id="normal_order" defaultMessage="Normal order" gtm-label="virtual_origin_normal_order"/>
        },
        {
            value: "virtual_visit",
            label: <Translation id="virtual_visit" defaultMessage="Virtual visit" gtm-label="virtual_origin_virtual_visit"/>
        },
        {
            value: "brazil_promo",
            label: <Translation id="brazil_promo" defaultMessage="Brazil Promo" gtm-label="virtual_origin_brazil_promo"/>
        },
        {
            value: "brazil_trip",
            label: <Translation id="brazil_trip" defaultMessage="Brazil trip" gtm-label="virtual_origin_brazil_trip"/>
        }
    ]

    constructor(props) {
        super(props)
        this.state = {
            fromPromo: false,
            fromVirtualVisit: false,
            selectedOrderOriginOption: this.orderOriginOptions[0],
            selectedSalesforceIdOption: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.selectedSalesforceIdOption !== prevState.selectedSalesforceIdOption
            || this.state.fromVirtualVisit !== prevState.fromVirtualVisit
            || this.state.selectedOrderOriginOption.value !== prevState.selectedOrderOriginOption.value
        ) {
            this.props.setVirtualVisitData(this.getVirtualVisitData())
        }
    }

    componentWillUnmount() {
        this.props.setVirtualVisitData(null)
    }

    getVirtualVisitData = () => {
        if (
            this.state.selectedOrderOriginOption.value === 'virtual_visit'
            || this.state.selectedOrderOriginOption.value === 'brazil_promo'
            || this.state.selectedOrderOriginOption.value === 'brazil_trip'
        ) {
            return {
                type: this.state.selectedOrderOriginOption.value,
                salesforceId: this.state.selectedSalesforceIdOption ? this.state.selectedSalesforceIdOption.value : null,
                isVirtualPromo: this.state.fromPromo || this.state.fromVirtualVisit
            }
        }
        return null
    }


    render() {
        if (!this.props.canSeeVirtualVisitOptions) return <React.Fragment/>

        return (
            <div className="mb-5">
                <h1 className="shipping_information">
                    <Translation id="virtual_visit_section_title" defaultMessage="Information about the cause / origin of the order"/>
                </h1>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col-xl-4 p-0 pr-xl-3">
                            <label htmlFor="select-order-origin">
                                <Translation id="order_origin" defaultMessage="Order origin" />
                            </label>
                            <Select
                                key={this.state.selectedOrderOriginOption}
                                id="select-order-origin"
                                name="select-order-origin"
                                value={this.state.selectedOrderOriginOption}
                                className={'form-control'}
                                options={this.orderOriginOptions}
                                onChange={(v) => {
                                    this.setState({
                                        selectedOrderOriginOption: v,
                                        fromPromo: false,
                                        fromVirtualVisit: false,
                                        selectedSalesforceIdOption: null
                                    })
                                    if (!this.props.virtualVisitSalesforceIds) this.props.fetchVirtualVisitSalesforceId()

                                    this.props.trigVirtualVisitAnalyticsEvent('virtual_visit', 'select', 'order_origin_' + v.value)
                                }}
                                searchable={false}
                                clearable={false}
                            />
                        </div>
                        {
                            this.state.selectedOrderOriginOption.value === 'brazil_promo' &&
                            <div className="col-xl-3 p-0 pr-xl-3">
                                <label htmlFor="radio-comes-from-vv">
                                    <Translation id="comes_from_vv" defaultMessage="Comes from virtual visit?" />
                                </label>
                                <div id="radio-comes-from-vv" style={{display: "flex", alignItems: "center", height: "36px", columnGap: "24px"}}>
                                                    <span>
                                                        <RadioButton onClick={() => {
                                                                this.setState({fromVirtualVisit: true})
                                                                this.props.trigVirtualVisitAnalyticsEvent('virtual_visit', 'click', 'from_virtual_yes')
                                                            }}
                                                             label={this.props.intl.formatMessage({ id: 'yes' })}
                                                             name="radio-comes-from-vv-1"
                                                             value="1"
                                                             checked={this.state.fromVirtualVisit}
                                                             key={'radio-comes-from-vv' + this.state.fromVirtualVisit}
                                                             gtm-label="virtual_visit_yes"
                                                        />
                                                    </span>
                                    <span>
                                                        <RadioButton onClick={() => {
                                                                this.setState({fromVirtualVisit: false, selectedSalesforceIdOption: null})
                                                                this.props.trigVirtualVisitAnalyticsEvent('virtual_visit', 'click', 'from_virtual_no')
                                                            }}
                                                             label={this.props.intl.formatMessage({ id: 'no' })}
                                                             name="radio-comes-from-vv-2"
                                                             value="2"
                                                             checked={!this.state.fromVirtualVisit}
                                                             key={'radio-comes-from-vv-2' + this.state.fromVirtualVisit}
                                                             gtm-label="virtual_visit_no"
                                                        />
                                                    </span>
                                </div>
                            </div>
                        }
                        {
                            (this.state.selectedOrderOriginOption.value === 'virtual_visit' || this.state.fromVirtualVisit) &&
                            <div className="col-xl-4 p-0">
                                <label htmlFor="select-salesforce-id">
                                    <Translation id="virtual_visit" defaultMessage="Virtual visit" />
                                    {
                                        !this.state.selectedSalesforceIdOption &&
                                        <strong style={{color:'red'}}>
                                            &nbsp;*
                                        </strong>
                                    }
                                </label>
                                <Select
                                    key={this.state.selectedSalesforceIdOption}
                                    id="select-salesforce-id"
                                    name="select-salesforce-id"
                                    value={this.state.selectedSalesforceIdOption}
                                    className={'form-control'}
                                    options={this.props.virtualVisitSalesforceIds}
                                    onChange={(v) => this.setState({selectedSalesforceIdOption: v})}
                                    searchable={false}
                                    clearable={false}
                                    isLoading={this.props.loadingVvSalesforceIds}
                                />
                            </div>
                        }
                        {
                            this.state.selectedOrderOriginOption.value === 'virtual_visit' &&
                            <div className="col-xl-4 p-0 pl-xl-3">
                                <label htmlFor="radio-select-promo">
                                    <Translation id="is_brazil_promo_order" defaultMessage="It's a Brazil Promo order?" />
                                </label>
                                <div id="radio-select-promo" style={{display: "flex", alignItems: "center", height: "36px", columnGap: "24px"}}>
                                    <span>
                                        <RadioButton onClick={() => {
                                                this.setState({fromPromo: true})
                                                this.props.trigVirtualVisitAnalyticsEvent('virtual_visit', 'click', 'promotion_yes')
                                            }}
                                            label={this.props.intl.formatMessage({ id: 'yes' })}
                                            name="radio-select-promo-1"
                                            value="1"
                                            checked={this.state.fromPromo}
                                            key={"radio-select-promo-1" + this.state.fromPromo}
                                            gtm-label="virtual_promotion_yes"
                                        />
                                    </span>
                                    <span>
                                        <RadioButton onClick={() => {
                                                this.setState({fromPromo: false})
                                                this.props.trigVirtualVisitAnalyticsEvent('virtual_visit', 'click', 'promotion_no')
                                            }}
                                            label={this.props.intl.formatMessage({ id: 'no' })}
                                            name="radio-select-promo-2"
                                            value="2"
                                            checked={!this.state.fromPromo}
                                            key={"radio-select-promo-2" + this.state.fromPromo}
                                            gtm-label="virtual_promotion_no"
                                        />
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        canSeeVirtualVisitOptions: canSeeVirtualVisitOptions(state),
        virtualVisitSalesforceIds: getVirtualVisitSalesforceIds(state),
        loadingVvSalesforceIds: state.checkout.loadingVvSalesforceIds
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchVirtualVisitSalesforceId: () => dispatch(fetchVirtualVisitSalesforceId(props.intl)),
        setVirtualVisitData: (vvData) => dispatch(setVirtualVisitData(vvData)),
        trigVirtualVisitAnalyticsEvent: ((category, action, label) => dispatch({
            type: 'SELECT_VIRTUAL_VISIT', values: {category, action, label}
        })),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectVirtualVisitComponent))