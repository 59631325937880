import { injectIntl } from 'react-intl';
import React from 'react'
import Translation from '../global/Translation'

import Lightbox from 'react-image-lightbox'
import { copy } from '../../helpers'
import { connect } from 'react-redux'
import Add2CartSuccessContainer from '../add2cart-success/Add2CartSuccessContainer'
import Spinner from '../global/Spinner'
import { byProductId } from '../../store/products/reducers'
import { fetchProduct } from '../../store/products/actions'
import {getCurrentProductLots, getBundlesRejectionCodes} from '../../store/lots/reducers'
import { goBack } from 'react-router-redux'
import IntegerInputNumber from "../input-number-other/IntegerInputNumber";
import {addCartLineBundle, addCartLineLot} from "../../store/cart/actions";
import DisplayOutletIndicator from "../catalog/rectangleResult/displays/DisplayOutletIndicator";
import {getIsAdding, isSuccessfullyAddedToCart} from "../../store/cart/reducers";
import {canViewAllLots} from "../../store/centers/reducers";
import {getLastSelectedProduct} from "../../store/selected-objects/reducers";
import ProductDisplayName from '../global/ProductDisplayName'
import { rejectionCodeMap, getBundleRejectionCode } from '../lots/utils';


const mapStateToProps = (state, ownProps) => {
    return {
        product: byProductId(state, ownProps.productId),
        productLots: getCurrentProductLots(state),
        isSuccessfullyAddedToCart: isSuccessfullyAddedToCart(state),
        isAddingToCart: getIsAdding(state),
        canViewAllLots: canViewAllLots(state),
        lastSelectedProduct: getLastSelectedProduct(state),
        bundlesRejectionCodes: getBundlesRejectionCodes(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchProduct: productId => {
            dispatch(fetchProduct(productId))
        },
        goBack: () => {dispatch(goBack())},
        buyLot: (productId, lotId, centerId) => {
            dispatch(addCartLineLot(productId, lotId, centerId))
        },
        buyBundle: (productId, bundleId, qty, centerId) => {
            dispatch(addCartLineBundle(productId, bundleId, qty, centerId))
        }
    }
}


class LotImageViewer extends React.Component {
    constructor(props) {
        super(props)

        props.fetchProduct(this.props.productId)

        this.state = {
            photoIndex: this.getIndex(),
            quantity: 1,
            addedLots: {},
            isAddingToCart: false
        }

        this.closeWindow = this.closeWindow.bind(this)
        this.copyLink = this.copyLink.bind(this)
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden'
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }

    componentDidUpdate(prevProps) {
        if (this.state.isAddingToCart && prevProps.isAddingToCart && !this.props.isAddingToCart) {
            this.setState({isAddingToCart: false})
            if (this.props.isSuccessfullyAddedToCart) this.closeWindow()
        }
    }

    getIndex = () => {
        return Math.max(0 , this.props.productLots.findIndex(b => b.lot_id === this.props.lotId))
    }

    closeWindow() {
        this.props.goBack()
    }

    copyLink() {
        copy(global.location.href)
    }

    setQuantity = (quantity) => {
        this.setState({quantity: quantity})
    }

    addToCart = (currentLot) => {
        this.setState({isAddingToCart: true})

        const {productId, centerId, buyLot, buyBundle} = this.props
        const isBundle = currentLot && currentLot.bundleId && true
        if (isBundle) buyBundle(productId, currentLot.bundleId, this.state.quantity, centerId)
        else if (currentLot && currentLot.lot_id) buyLot(productId, currentLot.lot_id, centerId)

        //Update available lots count
        // {
        //     let addedLots = this.state.addedLots[this.state.photoIndex] ? this.state.addedLots[this.state.photoIndex] : 0;
        //     addedLots += this.state.quantity
        //     this.state.addedLots[this.state.photoIndex] = addedLots
        //     this.forceUpdate()
        // }
    }

    getMeasure = (field, current_lot, isSurface) => {
        let localeField = field + '_' + this.props.unit
        if (isSurface) localeField += '2'
        return current_lot[localeField]
    }

    render() {
        const { isAdmin, productId, productLots, outlet, impersonatedBy, canViewAllLots, bundlesRejectionCodes } = this.props
        const { photoIndex } = this.state;
        const prevIndex = (photoIndex + productLots.length - 1) % productLots.length
        const nextIndex = (photoIndex + 1) % productLots.length
        const currentLot = productLots && productLots.length && productLots[photoIndex]

        if (!currentLot) return null

        const width =this.getMeasure('width', currentLot)
        const length = this.getMeasure('length', currentLot)
        const surface = this.getMeasure('qty', currentLot, true)
        const isBundle = currentLot.bundleId && true

        const addedLots = this.state.addedLots[this.state.photoIndex] ? this.state.addedLots[this.state.photoIndex] : 0;
        const maxCount = currentLot && (currentLot.count - addedLots)



        return (
            <section id="section-lots-viewer">
                <div className="carousel">
                    {currentLot && !this.state.isAddingToCart ? (
                        <div>
                            <Lightbox
                                mainSrc={currentLot.image}
                                // mainSrcThumbnail={currentLot.thumb}
                                // prevSrcThumbnail={productLots[prevIndex].thumb}
                                // nextSrcThumbnail={productLots[nextIndex].thumb}
                                prevSrc={productLots[prevIndex].image}
                                nextSrc={productLots[nextIndex].image}
                                onCloseRequest={this.closeWindow}
                                wrapperClassName={'carousel-custom'}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + productLots.length - 1) % productLots.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % productLots.length
                                    })
                                }
                                imagePadding={60}
                                imageTitle={
                                    <div className="lot-viewer-title">
                                        <div>
                                            <div>
                                                {isBundle ? (
                                                        <React.Fragment>Bundle {currentLot.bundleId}</React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {(isAdmin || impersonatedBy || canViewAllLots) && (
                                                                <span>
                                                                    <Translation id="lot" defaultMessage="Lot" /> {currentLot.lot_id}
                                                                </span>
                                                            )}
                                                        </React.Fragment>
                                                    )}{' '}
                                                    <ProductDisplayName product={this.props.lastSelectedProduct} />
                                                    {this.props.lastSelectedProduct.thickness}cm{' '}
                                                    {this.props.intl.formatMessage({ id: this.props.lastSelectedProduct.finish })}{' '}
                                            </div>

                                            {outlet && currentLot.qualityOutlet && (
                                                <div className="m-auto" style={{ width: 'fit-content' }}>
                                                    <DisplayOutletIndicator outletQuality={currentLot.qualityOutlet} />
                                                </div>
                                            )}
                                        </div>

                                        <div className="lot-viewer-header-btns">
                                            <div className="bt">
                                                <a href={process.env.REACT_APP_API_HOST + '/helper/download-url/?url=' + encodeURIComponent(currentLot.image)}>
                                                    <i className="fal fa-download mr-1" />
                                                    <span className="lot-viewer-download"><Translation id="download" defaultMessage="Download" /></span>
                                                </a>
                                            </div>
                                            <div className="bt">
                                                <span onClick={this.copyLink}>
                                                    <i className="fal fa-link mr-1" />
                                                    <span className="lot-viewer-copy-link"><Translation id="copy_link" defaultMessage="Copy link" /></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                imageCaption={
                                    <div className="lot__viewer__contents">
                                        <div className="lot__viewer__contents--left">
                                            <div>
                                                {(isAdmin || impersonatedBy || canViewAllLots) && (
                                                    <div className="">
                                                        <b><Translation id="lot_id" defaultMessage="Lot ID" />:</b> {currentLot.lot_id}
                                                    </div>
                                                )}
                                                <div>
                                                    <b>
                                                        <Translation id="quarryBlock" defaultMessage="Quarry Block" />:
                                                    </b>{' '}
                                                    {currentLot.quarryBlock}
                                                </div>
                                                <div>
                                                    <b>
                                                        <Translation id="quality" defaultMessage="Quality" />:
                                                    </b>{' '}
                                                    {currentLot.quality}
                                                </div>

                                                {(isAdmin || impersonatedBy) && (
                                                    <div>
                                                        <b>
                                                            <Translation id="shade" defaultMessage="Shade" />:
                                                        </b>{' '}
                                                        {currentLot.shade}
                                                    </div>
                                                )}
                                            </div>

                                            <div>
                                                {(isAdmin || impersonatedBy) && (
                                                    <div>
                                                        <b>
                                                            <Translation id="location" defaultMessage="Location" />:
                                                        </b>{' '}
                                                        {currentLot.location}
                                                    </div>
                                                )}
                                                <div>
                                                    <b>{isBundle ? <Translation id="main_size" defaultMessage="Main size" /> : <Translation id="size" defaultMessage="Size" />}</b>:{' '}
                                                    {length} x {width} {this.props.unit}
                                                </div>
                                                <div>
                                                    <b>
                                                        {isBundle ? (
                                                            <Translation id="main_surface" defaultMessage="Main surface" />
                                                        ) : (
                                                            <Translation id="surface" defaultMessage="Surface" />
                                                        )}
                                                    </b>
                                                    : {surface} {this.props.unit}2
                                                </div>
                                                <div>
                                                    <b>
                                                        <Translation id="units_available" defaultMessage="Available units" />:
                                                    </b>{' '}
                                                    {currentLot.count}
                                                </div>
                                            </div>


                                            {/* Rejection code when is not Bundle, that means when is only lot */}
                                            {!isBundle && currentLot.rejectionCode && (
                                                <div className="row_lot__info__status">
                                                    <div className="row_lot__info__status--icon">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </div>
                                                    <div className="row_lot__info__status--text">
                                                        <div><span className="main-text"><Translation id="material_status_information" defaultMessage="Material status information" /></span>:&nbsp;</div>
                                                        <div><span className="categories">{rejectionCodeMap[currentLot.rejectionCode]}</span></div>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Rejection code when is Bundle, that means when is not only lot */}
                                            {isBundle && getBundleRejectionCode(currentLot, bundlesRejectionCodes) && (
                                                <div className="row_lot__info__status">
                                                    <div className="row_lot__info__status--icon">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </div>
                                                    <div className="row_lot__info__status--text">
                                                        <div><span className="main-text"><Translation id="material_status_information" defaultMessage="Material status information" /></span>:&nbsp;</div>
                                                        <div><span className="categories">{rejectionCodeMap[getBundleRejectionCode(currentLot, bundlesRejectionCodes)]}</span></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="lot__viewer__contents--right">
                                            <div className="text-end">
                                                <span>
                                                    {
                                                        !isBundle && (
                                                                <Translation id="this_lot_or_table_does_not_belong_to_any_bundle" defaultMessage="This lot or table does not belong to any bundle" />
                                                        )
                                                    }
                                                </span>
                                            </div>
                                            {this.props.lastSelectedProduct && !this.props.lastSelectedProduct.fromMall && !isAdmin && (
                                                <div className="button-quantity-block">
                                                    <IntegerInputNumber max={maxCount} min={1} valueDefault={1} onChangeValue={v => this.setQuantity(v)}/>
                                                    <div>
                                                        <button
                                                            className="button-block"                                                    
                                                            onClick={() => this.addToCart(currentLot)}
                                                        >
                                                            <i className="fal fa-shopping-cart mr-1" />
                                                            <Translation id="add" defaultMessage="Add" />
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                                toolbarButtons={[]}
                            />
                            {/*<Add2CartSuccessContainer productId={productId} />*/}
                        </div>
                    ) : (
                        <Spinner loading={true} />
                    )}
                </div>
            </section>
        )
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LotImageViewer))