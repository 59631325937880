import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
    getUseOptions,
    getHasSegmentationV3,
    getChannelOptionsV3,
    getTypologyOptionsV3,
    getNexusOptions
} from '../../store/segmentation/reducers'
import { getShippingMethod } from '../../store/centers/reducers'
import { connect } from 'react-redux'
import Translation from '../global/Translation'

const mapStateToProps = (state, props) => {
    return {
        useOptions: getUseOptions(state),
        shippingMethod: getShippingMethod(state),
        segmentationV3: getHasSegmentationV3(state),
        channelOptionsV3: getChannelOptionsV3(state),
        typologyOptionsV3: getTypologyOptionsV3(state),
        nexusOptions: props.showAllNexus ? state.segmentation.nexusOptions : getNexusOptions(state)
    }
}
class LineSegmentationV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showHelp: false }
    }

    render() {
        let { line, useOptions, segmentationV3, channelOptionsV3, typologyOptionsV3 } = this.props
        let productBS, businessSegment, channel, subchannel, salesforce, project, typology, use

        if (line.segmentation) {
            if (!segmentationV3) {
                productBS = line.businessSegment === null ? 'NOT' : line.businessSegment
                businessSegment = channelOptionsV3 ? channelOptionsV3[productBS] : undefined
                channel = businessSegment ? businessSegment.channels[line.channel] : undefined
                subchannel = channel ? channel.subchannels[line.subchannel] : undefined
                typology = subchannel ? subchannel.typologies[line.typology] : undefined
                use = useOptions ? useOptions.find(option => line.use === option.id) : undefined
            } else {

                productBS = line.businessSegment === null ? 'NOT' : line.businessSegment
                typology = typologyOptionsV3 ? typologyOptionsV3.find(option => line.typology === option.id) : undefined
                use = useOptions ? useOptions.find(option => line.use === option.id) : undefined

                if (typeof line.channel === 'string' && line.channel.startsWith('nexus') && this.props.nexusOptions[line.channel]) {
                    salesforce = this.props.nexusOptions[line.channel].salesforceRelations[line.salesforceId]
                    project = salesforce && salesforce.projectOptions[line.projectId]
                    channel = {name:'C·Nexus'}
                } else {
                    const lineChannel = (line.channel === 'A0' && line.segmentation.projectId) ? 'AP' : line.channel
                    channel = channelOptionsV3 ? channelOptionsV3[lineChannel] : undefined
                    salesforce = channel ? channel.salesforceRelations[line.segmentation.salesforceId] : undefined
                    project = salesforce && line.segmentation.projectId ? salesforce.projectOptions[line.segmentation.projectId] : undefined
                }
            }
        }
        let businessSegmentTXT = '', channelTXT = '', subchannelTXT = '', typologyTXT = '', salesforceIdTXT = '', projectIdTXT = '', useTXT = '', TXT
        if (line.segmentation.businessSegment) {
            businessSegmentTXT = `${this.props.intl.formatMessage({id: productBS})}`
        }
        if (channel) {
            channelTXT = `${channel.name[0].toUpperCase()}${channel.name.substring(1).toLowerCase()}`
        }
        TXT = `${channelTXT}/${businessSegmentTXT} `

        const hasValue = Object.values(line.segmentation).some(value => {
            if (Array.isArray(value)) return value.length > 0
            else return value !== null
        })

        if (hasValue) {
            if (this.props.showAsList) {
                const listValues = [];

                if (businessSegmentTXT) listValues.push(businessSegmentTXT)
                if (channel) listValues.push(channel.name)
                if (subchannel) listValues.push(subchannel.name)
                if (typology) listValues.push(typology.name)
                if (salesforce) listValues.push(salesforce.name)
                if (project && project.projectName) listValues.push(project.projectName)
                if (use) listValues.push(use.name)

                const list = listValues.join(' / ')

                return (
                    <React.Fragment>
                        {list}
                    </React.Fragment>
                )
            } else return (
                <React.Fragment>
                    <div>
                        <span className='segmentation-title'>
                            <span className="segmentation-title--title">
                                <Translation id="segmentation" defaultMessage="Segmentation" />
                            </span>:&nbsp;{TXT}
                        </span>
                        <span className='segmentation-link' onClick={() => this.props.editSegmentation()}><Translation id="edit" defaultMessage="Edit" /></span>
                    </div>
                    <div className="segmentation">
                        <div className={`info ${!this.state.showHelp ? 'info--hidden' : ''}`}>
                            {line.segmentation.businessSegment && (
                                <span>
                                    <strong>
                                        <FormattedMessage id="business_segment" defaultMessage="business_segment" />
                                        :&nbsp;
                                    </strong>
                                    {line.segmentation.businessSegment && <FormattedMessage id={productBS} />}
                                </span>
                            )}
                            {channel && (
                                <span>
                                    <strong>
                                        <FormattedMessage id="channel" defaultMessage="channel" />
                                        :&nbsp;
                                    </strong>
                                    {channel.name}
                                </span>
                            )}
                            {subchannel && (
                                <span>
                                    <strong>
                                        <FormattedMessage id="subchannel" defaultMessage="subchannel" />
                                        :&nbsp;
                                    </strong>
                                    {subchannel && subchannel.name}
                                </span>
                            )}

                            {typology && (
                                <span>
                                    <strong>
                                        <FormattedMessage id="typology" defaultMessage="Typology" />
                                        :&nbsp;
                                    </strong>
                                    {typology.name}
                                </span>
                            )}
                            {salesforce && (
                                <span>
                                    <strong>
                                        <FormattedMessage id="salesforceId" defaultMessage="Salesforce Id" />
                                        :&nbsp;
                                    </strong>
                                    {salesforce.name}
                                </span>
                            )}
                            {project && (
                                <span>
                                    <strong>
                                        <FormattedMessage id="projectId" defaultMessage="Project Id" />
                                        :&nbsp;
                                    </strong>
                                    {project.projectName}
                                </span>
                            )}
                            {use && (
                                <span>
                                    <strong>
                                        <FormattedMessage id="use" defaultMessage="Use" />
                                        :&nbsp;
                                    </strong>
                                    {use.name}
                                </span>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(LineSegmentationV2)
)
